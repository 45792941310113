import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AdditionalDetails: { input: any; output: any };
  CustomPaymentData: { input: any; output: any };
  CustomResponsePaymentData: { input: any; output: any };
  JSON: { input: any; output: any };
  JSONObject: { input: any; output: any };
  MessageValue: { input: any; output: any };
  Upload: { input: any; output: any };
}

export interface AiRecommendationsParams {
  excludeSameProduct?: InputMaybe<Scalars['Boolean']['input']>;
  minShown?: InputMaybe<Scalars['Int']['input']>;
  resultsLimit?: InputMaybe<Scalars['Int']['input']>;
  similarity?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uniqueProductOnly?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface AdInfo {
  __typename?: 'AdInfo';
  adId?: Maybe<Scalars['String']['output']>;
}

export interface AddedPotentialPromotionInfo {
  __typename?: 'AddedPotentialPromotionInfo';
  mainPromotionCode?: Maybe<Scalars['String']['output']>;
  mainPromotionDescription?: Maybe<Scalars['String']['output']>;
  mainPromotionName?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface AdditionalConfiguration {
  __typename?: 'AdditionalConfiguration';
  code: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface AdditionalDataInput {
  restType?: InputMaybe<Scalars['String']['input']>;
  userComment?: InputMaybe<Scalars['String']['input']>;
}

export interface Address {
  __typename?: 'Address';
  addition1?: Maybe<Scalars['String']['output']>;
  addressId?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  countryIsoCode?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  defaultAddress?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  formattedAddress?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lift?: Maybe<Scalars['Boolean']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone2?: Maybe<Scalars['String']['output']>;
  phoneNumberIsoCode?: Maybe<Scalars['String']['output']>;
  phoneNumberNational?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  pricepassNr?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Region>;
  regionIsoCode?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  salesTaxNumbers?: Maybe<Array<SalesTaxNumber>>;
  shippingAddress?: Maybe<Scalars['Boolean']['output']>;
  streetname?: Maybe<Scalars['String']['output']>;
  streetnumber?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  titleCode?: Maybe<Scalars['String']['output']>;
  town?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  visibleInAddressBook?: Maybe<Scalars['Boolean']['output']>;
}

export interface AddressData {
  __typename?: 'AddressData';
  addition1?: Maybe<Scalars['String']['output']>;
  attn?: Maybe<Scalars['String']['output']>;
  baddress?: Maybe<Scalars['Boolean']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lift?: Maybe<Scalars['Boolean']['output']>;
  maddress?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneNumberIsoCode?: Maybe<Scalars['String']['output']>;
  phoneNumberNational?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  saddress?: Maybe<Scalars['Boolean']['output']>;
  salesTaxNumbers?: Maybe<Array<Taxnumber>>;
  streetname?: Maybe<Scalars['String']['output']>;
  streetnumber?: Maybe<Scalars['String']['output']>;
  titleCode?: Maybe<Scalars['Int']['output']>;
  town?: Maybe<Scalars['String']['output']>;
}

export interface AddressDataInput {
  addition1?: InputMaybe<Scalars['String']['input']>;
  attn?: InputMaybe<Scalars['String']['input']>;
  baddress?: InputMaybe<Scalars['Boolean']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lift?: InputMaybe<Scalars['Boolean']['input']>;
  maddress?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneNumberIsoCode?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  saddress?: InputMaybe<Scalars['Boolean']['input']>;
  salesTaxNumbers?: InputMaybe<Array<TaxnumberInput>>;
  streetname?: InputMaybe<Scalars['String']['input']>;
  streetnumber?: InputMaybe<Scalars['String']['input']>;
  titleCode?: InputMaybe<Scalars['Int']['input']>;
  town?: InputMaybe<Scalars['String']['input']>;
}

export interface AddressInput {
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryInput>;
  countryIsoCode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  defaultAddress?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  formattedAddress?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lift?: InputMaybe<Scalars['Boolean']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone2?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  pricepassNr?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<RegionInput>;
  regionIsoCode?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  shippingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  streetname?: InputMaybe<Scalars['String']['input']>;
  streetnumber?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleCode?: InputMaybe<Scalars['String']['input']>;
  town?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  visibleInAddressBook?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface AddressValidation {
  __typename?: 'AddressValidation';
  postcode?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  town?: Maybe<Scalars['String']['output']>;
}

export interface AddressValidationResult {
  __typename?: 'AddressValidationResult';
  country?: Maybe<Scalars['String']['output']>;
  exactMatch?: Maybe<Scalars['Boolean']['output']>;
  selectedAddress?: Maybe<AddressValidation>;
  suggestedAddressList?: Maybe<Array<AddressValidation>>;
}

export interface AdyenPaymentMethod {
  __typename?: 'AdyenPaymentMethod';
  brand?: Maybe<Scalars['String']['output']>;
  brands?: Maybe<Array<Scalars['String']['output']>>;
  configuration?: Maybe<AdyenPaymentMethodConfiguration>;
  details?: Maybe<Array<AdyenPaymentMethodDetail>>;
  fundingSource?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export interface AdyenPaymentMethodConfiguration {
  __typename?: 'AdyenPaymentMethodConfiguration';
  entry?: Maybe<Array<Configuration>>;
}

export interface AdyenPaymentMethodDetail {
  __typename?: 'AdyenPaymentMethodDetail';
  details?: Maybe<Array<AdyenPaymentMethodDetail>>;
  items?: Maybe<Array<AdyenPaymentMethodDetailItem>>;
  key: Scalars['String']['output'];
  optional: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
}

export interface AdyenPaymentMethodDetailItem {
  __typename?: 'AdyenPaymentMethodDetailItem';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface AdyenPaymentModes {
  __typename?: 'AdyenPaymentModes';
  paymentMethods?: Maybe<Array<AdyenPaymentMethod>>;
}

export interface Affirmator {
  __typename?: 'Affirmator';
  code?: Maybe<Scalars['String']['output']>;
  iconKey?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tooltip?: Maybe<Scalars['String']['output']>;
}

export interface AnonymousNewsletterData {
  consents?: InputMaybe<Array<Consents>>;
  country?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<NewsletterEmail>;
  group?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<NewsletterData>;
  store?: InputMaybe<Scalars['String']['input']>;
}

export interface ApplicationFeature {
  __typename?: 'ApplicationFeature';
  code?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface ApplicationFeatures {
  __typename?: 'ApplicationFeatures';
  features?: Maybe<Array<ApplicationFeature>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface Appointment {
  id?: InputMaybe<Scalars['String']['input']>;
}

export interface AppointmentCancelationInput {
  appointment?: InputMaybe<AppointmentInput>;
  area?: InputMaybe<AreaInput>;
  /** @deprecated Using another cloud solution for bot management. */
  captcha?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<PointOfServiceInput>;
}

export interface AppointmentCustomer {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  newsletterAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

export interface AppointmentData {
  bonusCard?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Using another cloud solution for bot management. */
  captcha?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<AppointmentCustomer>;
  date?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  interventionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

export interface AppointmentInformation {
  __typename?: 'AppointmentInformation';
  appointmentId?: Maybe<Scalars['String']['output']>;
  areaInformation?: Maybe<Area>;
  customer?: Maybe<TimendoCustomer>;
  restType?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
  storeInformation?: Maybe<PointOfService>;
}

export interface AppointmentInput {
  groupId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  interventionId?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
}

export interface AppointmentProposal {
  __typename?: 'AppointmentProposal';
  errorCode?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  noMoreAppointments?: Maybe<Scalars['Boolean']['output']>;
  proposals?: Maybe<Array<Proposal>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface AppointmentRequestInput {
  bonusCard?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Using another cloud solution for bot management. */
  captcha?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<TimendoCustomerInput>;
  date?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  interventionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

export interface AppointmentResponse {
  __typename?: 'AppointmentResponse';
  appointmentId?: Maybe<Scalars['String']['output']>;
}

export interface Area {
  __typename?: 'Area';
  description?: Maybe<Scalars['String']['output']>;
  externId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  length?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  publicName?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Image>;
}

export interface AreaInput {
  description?: InputMaybe<Scalars['String']['input']>;
  externId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  length?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  publicName?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<ImageInput>;
}

export interface AreaList {
  __typename?: 'AreaList';
  areas?: Maybe<Array<Area>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface BaseOption {
  __typename?: 'BaseOption';
  options?: Maybe<Array<VariantOption>>;
  restType?: Maybe<Scalars['String']['output']>;
  selected?: Maybe<VariantOption>;
  variantType?: Maybe<Scalars['String']['output']>;
}

export interface BaseRestriction {
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface BaseSearchResult {
  id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface BaseStoreRelatedItem {
  __typename?: 'BaseStoreRelatedItem';
  locale?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface BaseValues {
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  valueId: Scalars['String']['output'];
}

export interface BonuscardInfo {
  __typename?: 'BonuscardInfo';
  acceptedAgb?: Maybe<Scalars['Boolean']['output']>;
  addressAddon?: Maybe<Scalars['String']['output']>;
  /** @deprecated Using another cloud solution for bot management. */
  captcha?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  elevatorAvailable?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneAlternative?: Maybe<Scalars['String']['output']>;
  postalcode?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  town?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
}

export interface BonuscardRegisterRequestInput {
  acceptedAgb?: InputMaybe<Scalars['Boolean']['input']>;
  addressAddon?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Using another cloud solution for bot management. */
  captcha?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use `childBirthday1` instead */
  child1birthday?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use `childBirthday2` instead */
  child2birthday?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use `childBirthday3` instead */
  child3birthday?: InputMaybe<Scalars['String']['input']>;
  childBirthday1?: InputMaybe<Scalars['String']['input']>;
  childBirthday2?: InputMaybe<Scalars['String']['input']>;
  childBirthday3?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  elevatorAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneAlternative?: InputMaybe<Scalars['String']['input']>;
  phoneNumberIsoCode?: InputMaybe<Scalars['String']['input']>;
  postalcode?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  secondaryTaxNumber?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  tertiaryTaxNumber?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  town?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
}

export interface Brand {
  __typename?: 'Brand';
  code?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Image>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  showBrandnameInPdpTitle?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  urlCode?: Maybe<Scalars['String']['output']>;
}

export interface Breadcrumb {
  __typename?: 'Breadcrumb';
  itemCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  seoUrl?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface Brochure {
  __typename?: 'Brochure';
  code?: Maybe<Scalars['String']['output']>;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Image>;
  orderable?: Maybe<Scalars['Boolean']['output']>;
  premediaId?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  teaser?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  webUrl?: Maybe<Scalars['String']['output']>;
}

export interface BrochureRequestInput {
  captcha?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  postalcode?: InputMaybe<Scalars['String']['input']>;
  premediaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  restType?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  town?: InputMaybe<Scalars['String']['input']>;
}

export interface BrochureType {
  __typename?: 'BrochureType';
  brochures?: Maybe<Array<Brochure>>;
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  seoCode?: Maybe<Scalars['String']['output']>;
}

export interface Brochures {
  __typename?: 'Brochures';
  brochureTypes?: Maybe<Array<BrochureType>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export interface CacheInitRequestInput {
  cacheName?: InputMaybe<Scalars['String']['input']>;
  captcha?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  uris?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface Campaign extends BaseSearchResult {
  __typename?: 'Campaign';
  firstItemOfPage?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  promotionTermsColor?: Maybe<Scalars['String']['output']>;
  promotionTermsLink?: Maybe<Scalars['String']['output']>;
  promotionTermsLinkOpen?: Maybe<Scalars['String']['output']>;
  promotionTermsText?: Maybe<Scalars['String']['output']>;
  resultGridPosition?: Maybe<Scalars['Int']['output']>;
  searchResult?: Maybe<CampaignSearchResult>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface CampaignData {
  __typename?: 'CampaignData';
  id?: Maybe<Scalars['String']['output']>;
  maxShown?: Maybe<Scalars['Int']['output']>;
  moreItemsLink?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Array<Product>>;
  provider?: Maybe<Scalars['String']['output']>;
  recommendationId?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  searchResult?: Maybe<FhSearchResult>;
  title?: Maybe<Scalars['String']['output']>;
  widgetId?: Maybe<Scalars['String']['output']>;
}

export interface CampaignDataType {
  __typename?: 'CampaignDataType';
  id?: Maybe<Scalars['String']['output']>;
  maxShown?: Maybe<Scalars['Int']['output']>;
  moreItemsLink?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Array<BaseSearchResult>>;
  provider?: Maybe<Scalars['String']['output']>;
  recommendationId?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  searchResult?: Maybe<FhSearchResult>;
  title?: Maybe<Scalars['String']['output']>;
  widgetId?: Maybe<Scalars['String']['output']>;
}

export interface CampaignFilters {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  maxShown?: InputMaybe<Scalars['Int']['input']>;
}

export interface CampaignSearchResult {
  __typename?: 'CampaignSearchResult';
  restType?: Maybe<Scalars['String']['output']>;
  searchResults?: Maybe<Array<CampaignSearchResultData>>;
}

export interface CampaignSearchResultData {
  __typename?: 'CampaignSearchResultData';
  columnSpan?: Maybe<Scalars['Int']['output']>;
  links?: Maybe<Array<Link>>;
  media?: Maybe<Image>;
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface Campaigns {
  __typename?: 'Campaigns';
  campaigns?: Maybe<Array<Campaign>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface CardType {
  __typename?: 'CardType';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface Cart {
  __typename?: 'Cart';
  additionalExpenseCosts?: Maybe<Array<EcoTaxCharge>>;
  appliedOrderPromotions?: Maybe<Array<PromotionResult>>;
  appliedProductPromotions?: Maybe<Array<PromotionResult>>;
  appliedVoucherDataList?: Maybe<Array<Voucher>>;
  appliedVouchers?: Maybe<Array<Voucher>>;
  calculated?: Maybe<Scalars['Boolean']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  deliveryAddress?: Maybe<Address>;
  deliveryAddressRequired?: Maybe<Scalars['Boolean']['output']>;
  deliveryCost?: Maybe<Price>;
  deliveryCostAssembling?: Maybe<Price>;
  deliveryCostWithoutSpecial?: Maybe<Price>;
  deliveryItemsQuantity?: Maybe<Scalars['String']['output']>;
  deliveryMode?: Maybe<DeliveryMode>;
  deliveryOrderGroups?: Maybe<Array<DeliveryOrderEntryGroup>>;
  description?: Maybe<Scalars['String']['output']>;
  entries?: Maybe<Array<OrderEntry>>;
  expirationTime?: Maybe<Scalars['String']['output']>;
  expressPaymentLogin?: Maybe<ExpressPaymentLogin>;
  guid?: Maybe<Scalars['String']['output']>;
  /** @deprecated Optile payment provider is end of life */
  isPreferredPaymentModeAvailable?: Maybe<Scalars['Boolean']['output']>;
  messages?: Maybe<Array<MessageData>>;
  name?: Maybe<Scalars['String']['output']>;
  net?: Maybe<Scalars['Boolean']['output']>;
  orderDiscounts?: Maybe<Price>;
  orderType?: Maybe<Scalars['String']['output']>;
  parentOrder?: Maybe<Order>;
  parentOrderCode?: Maybe<Scalars['String']['output']>;
  paymentAddress?: Maybe<Address>;
  paymentInfo?: Maybe<PaymentDetails>;
  paymentMode?: Maybe<PaymentMode>;
  paymentModes?: Maybe<Array<PaymentMode>>;
  /** @deprecated Optile payment provider is end of life */
  paymentSessionTokens?: Maybe<Array<XPaymentSession>>;
  paymentStart?: Maybe<PaymentStart>;
  paymentValidationSuccess?: Maybe<Scalars['Boolean']['output']>;
  payolutionSessionId?: Maybe<Scalars['String']['output']>;
  pickupItemsQuantity?: Maybe<Scalars['String']['output']>;
  pickupOrderGroups?: Maybe<Array<PickupOrderEntryGroup>>;
  pickupStationAddress?: Maybe<Address>;
  postalCode?: Maybe<Scalars['String']['output']>;
  potentialOrderPromotions?: Maybe<Array<PromotionResult>>;
  potentialProductPromotions?: Maybe<Array<PromotionResult>>;
  productDiscounts?: Maybe<Price>;
  restType?: Maybe<Scalars['String']['output']>;
  saveAddresses?: Maybe<Scalars['Boolean']['output']>;
  saveTime?: Maybe<Scalars['String']['output']>;
  savedBy?: Maybe<Principal>;
  selectedStore?: Maybe<PointOfService>;
  serviceCosts?: Maybe<Array<ServicesTotalCost>>;
  showMergedCart?: Maybe<Scalars['Boolean']['output']>;
  site?: Maybe<Scalars['String']['output']>;
  store?: Maybe<Scalars['String']['output']>;
  subOrders?: Maybe<Array<Order>>;
  subTotal?: Maybe<Price>;
  taxes?: Maybe<Array<Price>>;
  totalDiscounts?: Maybe<Price>;
  totalItems?: Maybe<Scalars['Int']['output']>;
  totalPrice?: Maybe<Price>;
  totalPriceWithTax?: Maybe<Price>;
  totalPriceWithoutCoupons?: Maybe<Price>;
  totalTax?: Maybe<Price>;
  totalUnitCount?: Maybe<Scalars['Int']['output']>;
  town?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Principal>;
  userComment?: Maybe<Scalars['String']['output']>;
  voucherSum?: Maybe<Price>;
}

export interface CartAddressesExpressPaymentInput {
  addition1?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneNumberIsoCode?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  streetname?: InputMaybe<Scalars['String']['input']>;
  streetnumber?: InputMaybe<Scalars['String']['input']>;
  town?: InputMaybe<Scalars['String']['input']>;
}

export interface CartAddressesExpressPaymentRequestInput {
  deliveryAddress?: InputMaybe<CartAddressesExpressPaymentInput>;
  /** @deprecated deliveryAddress is used instead of paymentAddress in the BE */
  paymentAddress?: InputMaybe<CartAddressesExpressPaymentInput>;
}

export interface CartAddressesRequestInput {
  addressesDifferent?: InputMaybe<Scalars['Boolean']['input']>;
  deliveryAddress?: InputMaybe<CartDeliveryAddressInput>;
  paymentAddress?: InputMaybe<CartPaymentAddressInput>;
  restType?: InputMaybe<Scalars['String']['input']>;
  saveAddresses?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface CartDeliveryAddressInput {
  addition1?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['Boolean']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lift?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone2?: InputMaybe<Scalars['String']['input']>;
  phoneNumberIsoCode?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  pricepassNr?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Array<RegionData>>;
  regionIsoCode?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  salesTaxNumbers?: InputMaybe<Array<SalesTaxNumberInput>>;
  streetname?: InputMaybe<Scalars['String']['input']>;
  streetnumber?: InputMaybe<Scalars['String']['input']>;
  titleCode?: InputMaybe<Scalars['String']['input']>;
  town?: InputMaybe<Scalars['String']['input']>;
}

export interface CartList {
  __typename?: 'CartList';
  carts?: Maybe<Array<Cart>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface CartPaymentAddressInput {
  addition1?: InputMaybe<Scalars['String']['input']>;
  addressId?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['Boolean']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryInput>;
  countryIsoCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  floor?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lift?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone2?: InputMaybe<Scalars['String']['input']>;
  phoneNumberIsoCode?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  pricepassNr?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Array<RegionData>>;
  regionIsoCode?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  salesTaxNumbers?: InputMaybe<Array<SalesTaxNumberInput>>;
  streetname?: InputMaybe<Scalars['String']['input']>;
  streetnumber?: InputMaybe<Scalars['String']['input']>;
  titleCode?: InputMaybe<Scalars['String']['input']>;
  town?: InputMaybe<Scalars['String']['input']>;
}

export interface Category {
  __typename?: 'Category';
  breadcrumbs?: Maybe<Array<Breadcrumb>>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  firstParentCategory?: Maybe<Category>;
  image?: Maybe<Image>;
  name?: Maybe<Scalars['String']['output']>;
  parentCategoryName?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  seoData?: Maybe<Seo>;
  sequence?: Maybe<Scalars['Int']['output']>;
  seriesCategory?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface Classification {
  __typename?: 'Classification';
  code?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Array<Feature>>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface CmsContentPage {
  __typename?: 'CmsContentPage';
  breadcrumbs?: Maybe<Array<Breadcrumb>>;
  cmsPageType?: Maybe<CmsPageType>;
  code?: Maybe<Scalars['String']['output']>;
  contentSlots?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  masterTemplate?: Maybe<CmsTemplate>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  seoData?: Maybe<Seo>;
  smartedit?: Maybe<CmsSmartEdit>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
}

export interface CmsContentStyle {
  __typename?: 'CmsContentStyle';
  color?: Maybe<Scalars['String']['output']>;
  fontSize?: Maybe<Scalars['String']['output']>;
  textAlign?: Maybe<Scalars['String']['output']>;
}

export interface CmsLink {
  __typename?: 'CmsLink';
  category?: Maybe<Category>;
  code?: Maybe<Scalars['String']['output']>;
  contentPage?: Maybe<CmsLinkContentPageData>;
  icon?: Maybe<Image>;
  iconName?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Image>;
  itemType?: Maybe<Scalars['String']['output']>;
  linkName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  openInNewWindow?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  seoData?: Maybe<CmsLinkContentPageSeoData>;
  svg?: Maybe<Scalars['String']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Array<Scalars['String']['output']>>;
  uid?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
}

export interface CmsLinkContentPageData {
  __typename?: 'CmsLinkContentPageData';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface CmsLinkContentPageSeoData {
  __typename?: 'CmsLinkContentPageSeoData';
  canonicalUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface CmsMedia {
  __typename?: 'CmsMedia';
  altText?: Maybe<Scalars['String']['output']>;
  cdnFilename?: Maybe<Scalars['String']['output']>;
  fileExtension?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['Int']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  hashCode?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Image>;
  mediaProvider?: Maybe<Scalars['String']['output']>;
  mime?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pictureHeight?: Maybe<Scalars['Int']['output']>;
  pictureWidth?: Maybe<Scalars['Int']['output']>;
  realFilename?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  seoText?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  useFullPageWidth?: Maybe<Scalars['Boolean']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
}

export interface CmsPageType {
  __typename?: 'CmsPageType';
  cmsPageTypeSubtype?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface CmsProductContent {
  __typename?: 'CmsProductContent';
  components?: Maybe<Scalars['JSON']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface CmsProductSpecificData {
  __typename?: 'CmsProductSpecificData';
  contentSlots?: Maybe<Scalars['JSON']['output']>;
}

export interface CmsQuestion {
  __typename?: 'CmsQuestion';
  answer?: Maybe<Scalars['String']['output']>;
  question?: Maybe<Scalars['String']['output']>;
}

export interface CmsSmartEdit {
  __typename?: 'CmsSmartEdit';
  pageContract?: Maybe<Scalars['String']['output']>;
}

export interface CmsTemplate {
  __typename?: 'CmsTemplate';
  code?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface CmsTemplateData {
  __typename?: 'CmsTemplateData';
  contentSlots?: Maybe<Scalars['JSON']['output']>;
}

export interface ColorMapping {
  __typename?: 'ColorMapping';
  code?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  targetColor?: Maybe<Scalars['String']['output']>;
  webColor?: Maybe<Scalars['String']['output']>;
  webIcon?: Maybe<Image>;
}

export interface ComparisonBrand {
  __typename?: 'ComparisonBrand';
  name?: Maybe<Scalars['String']['output']>;
}

export interface ComparisonProduct {
  __typename?: 'ComparisonProduct';
  attributes?: Maybe<Scalars['JSON']['output']>;
  formattedPrice?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface ComparisonProductService {
  __typename?: 'ComparisonProductService';
  attributes?: Maybe<Scalars['JSON']['output']>;
  brand?: Maybe<ComparisonBrand>;
  code?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Image>;
  price?: Maybe<Scalars['Float']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface ComparisonProductServiceAttributes {
  __typename?: 'ComparisonProductServiceAttributes';
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

export interface Configuration {
  __typename?: 'Configuration';
  key?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface ConfigurationInfo {
  __typename?: 'ConfigurationInfo';
  configurationLabel?: Maybe<Scalars['String']['output']>;
  configurationValue?: Maybe<Scalars['String']['output']>;
  configuratorType?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
}

export interface ConfigurationList {
  __typename?: 'ConfigurationList';
  configurations?: Maybe<Array<Configuration>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface ConfiguredProductData {
  __typename?: 'ConfiguredProductData';
  attachments?: Maybe<Array<Image>>;
  configuredId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Image>>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Price>;
  productNumber?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
}

export interface Consents {
  consent?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  referer?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface Consignment {
  __typename?: 'Consignment';
  code?: Maybe<Scalars['String']['output']>;
  deliveryPointOfService?: Maybe<PointOfService>;
  entries?: Maybe<Array<ConsignmentEntry>>;
  restType?: Maybe<Scalars['String']['output']>;
  shippingAddress?: Maybe<Address>;
  status?: Maybe<Scalars['String']['output']>;
  statusDate?: Maybe<Scalars['String']['output']>;
  trackingID?: Maybe<Scalars['String']['output']>;
}

export interface ConsignmentEntry {
  __typename?: 'ConsignmentEntry';
  orderEntry?: Maybe<OrderEntry>;
  quantity?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  shippedQuantity?: Maybe<Scalars['String']['output']>;
}

export interface ContactCenterData {
  files?: InputMaybe<Array<Scalars['Upload']['input']>>;
  formData?: InputMaybe<Scalars['String']['input']>;
}

export interface Coordinates {
  __typename?: 'Coordinates';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
}

export interface CoreFacet {
  filterId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rid: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
}

export interface Country {
  __typename?: 'Country';
  isocode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface CountryInput {
  isocode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

export interface CountryList {
  __typename?: 'CountryList';
  countries?: Maybe<Array<Country>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface CountryOverlay {
  __typename?: 'CountryOverlay';
  code?: Maybe<Scalars['String']['output']>;
  contentSlots?: Maybe<Scalars['JSON']['output']>;
}

export interface CrossCountry {
  __typename?: 'CrossCountry';
  language?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  tld?: Maybe<Scalars['String']['output']>;
}

export interface Currency {
  __typename?: 'Currency';
  active?: Maybe<Scalars['Boolean']['output']>;
  isocode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
}

export interface CustomerCardDetails {
  __typename?: 'CustomerCardDetails';
  cardNumber?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the field `cardNumber` due to Int32 limitation. */
  number?: Maybe<Scalars['Int']['output']>;
}

export interface CustomerDataInput {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  birthdays?: InputMaybe<Scalars['Int']['input']>;
  created: Scalars['String']['input'];
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  salutation: Scalars['Int']['input'];
  title: Scalars['Int']['input'];
}

export interface CustomerDetails {
  __typename?: 'CustomerDetails';
  birthdate?: Maybe<Scalars['String']['output']>;
  birthdays?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  salutation?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['Int']['output']>;
}

export interface CustomerInfo {
  __typename?: 'CustomerInfo';
  addresses?: Maybe<Array<AddressData>>;
  card?: Maybe<CustomerCardDetails>;
  country: Scalars['String']['output'];
  created: Scalars['String']['output'];
  detail?: Maybe<CustomerDetails>;
  email?: Maybe<Email>;
  group: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  store: Scalars['String']['output'];
  tac?: Maybe<TermsAndConditions>;
}

export interface DefaultCountryZipCodeCoordinates {
  __typename?: 'DefaultCountryZipCodeCoordinates';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
}

export interface DeliveryAppointmentData {
  lastname?: InputMaybe<Scalars['String']['input']>;
  orderCode?: InputMaybe<Scalars['String']['input']>;
  proposalId?: InputMaybe<Scalars['String']['input']>;
}

export interface DeliveryCost {
  __typename?: 'DeliveryCost';
  deliveryModes?: Maybe<Array<Maybe<DeliveryModeList>>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface DeliveryInformation {
  __typename?: 'DeliveryInformation';
  assembly?: Maybe<Scalars['Boolean']['output']>;
  assemblyCost?: Maybe<Scalars['Float']['output']>;
  availabilityStatus?: Maybe<Scalars['String']['output']>;
  deliveryAndAssemblyTimeText?: Maybe<Scalars['String']['output']>;
  deliveryCost?: Maybe<Scalars['Float']['output']>;
  deliveryOrPostalDativeTimeText?: Maybe<Scalars['String']['output']>;
  deliveryOrPostalTimeText?: Maybe<Scalars['String']['output']>;
  deliveryTime?: Maybe<Scalars['Int']['output']>;
  deliveryTimeAssembly?: Maybe<Scalars['Int']['output']>;
  mailDeliveryCost?: Maybe<Scalars['Float']['output']>;
  reorderTime?: Maybe<Scalars['Int']['output']>;
  reservable?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  selfServiceDataList?: Maybe<Array<StockDataContainer>>;
  shippingCost?: Maybe<Scalars['Float']['output']>;
  stockPickup?: Maybe<Scalars['Boolean']['output']>;
}

export interface DeliveryMode {
  __typename?: 'DeliveryMode';
  assemblyCost?: Maybe<Scalars['Float']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  customShippingType?: Maybe<Scalars['String']['output']>;
  deliveryCost?: Maybe<Price>;
  deliveryCostSpecial?: Maybe<Price>;
  deliveryCostSpecialType?: Maybe<Scalars['String']['output']>;
  deliveryTime?: Maybe<Scalars['String']['output']>;
  deliveryTimeText?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  isFreeDeliveryCost?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  selfServiceStores?: Maybe<Array<PointOfService>>;
  /** @deprecated Duplicated, use customShippingType instead */
  stockLevelDeliveryType?: Maybe<Scalars['String']['output']>;
}

export interface DeliveryModeInfo {
  __typename?: 'DeliveryModeInfo';
  deliveryPrice?: Maybe<Scalars['Float']['output']>;
  orderTotalThreshold?: Maybe<Scalars['Float']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface DeliveryModeList {
  __typename?: 'DeliveryModeList';
  deliveryMode?: Maybe<Scalars['String']['output']>;
  deliveryModeInfo?: Maybe<Array<Maybe<DeliveryModeInfo>>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface DeliveryOrderEntryGroup {
  __typename?: 'DeliveryOrderEntryGroup';
  deliveryAddress?: Maybe<Address>;
  entries?: Maybe<Array<OrderEntry>>;
  quantity?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  totalPriceWithTax?: Maybe<Price>;
}

export interface DeliveryTimeList {
  __typename?: 'DeliveryTimeList';
  code?: Maybe<Scalars['String']['output']>;
  deliveryTime?: Maybe<OrderDeliveryTime>;
}

export interface Dimension {
  __typename?: 'Dimension';
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface DimensionRating {
  __typename?: 'DimensionRating';
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
}

export interface DimensionRatingInput {
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Int']['input']>;
}

export interface DimensionType {
  __typename?: 'DimensionType';
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface Dimensions {
  __typename?: 'Dimensions';
  dimensions?: Maybe<Array<Dimension>>;
  restType?: Maybe<Scalars['String']['output']>;
  strRepresentation?: Maybe<Scalars['String']['output']>;
  strRepresentationKey?: Maybe<Scalars['String']['output']>;
}

export interface DisplayInformation {
  __typename?: 'DisplayInformation';
  displayed?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Location>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface DistrictsList {
  __typename?: 'DistrictsList';
  id?: Maybe<Scalars['String']['output']>;
  municipality?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  town?: Maybe<Scalars['String']['output']>;
}

export interface EcoTax {
  __typename?: 'EcoTax';
  includedInProductPrice?: Maybe<Scalars['Boolean']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EcoTaxType>;
}

export interface EcoTaxCharge {
  __typename?: 'EcoTaxCharge';
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EcoTaxType>;
  value?: Maybe<Scalars['Float']['output']>;
}

export interface EcoTaxExpense {
  __typename?: 'EcoTaxExpense';
  price?: Maybe<Scalars['Float']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<EcoTaxType>;
}

export enum EcoTaxType {
  EcoTax = 'ECO_TAX',
}

export interface Email {
  __typename?: 'Email';
  confirmed: Scalars['Boolean']['output'];
  created: Scalars['String']['output'];
  description: Scalars['String']['output'];
}

export interface EncryptedCardDataInput {
  encryptedCardNumber?: InputMaybe<Scalars['String']['input']>;
  encryptedExpiryMonth?: InputMaybe<Scalars['String']['input']>;
  encryptedExpiryYear?: InputMaybe<Scalars['String']['input']>;
  encryptedSecurityCode?: InputMaybe<Scalars['String']['input']>;
  holderName?: InputMaybe<Scalars['String']['input']>;
}

export interface EnergyEfficiencyData {
  __typename?: 'EnergyEfficiencyData';
  classColor?: Maybe<Scalars['String']['output']>;
  datasheet?: Maybe<Image>;
  eeClass?: Maybe<Scalars['String']['output']>;
  energyLabel2021Shown?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Image>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface EntryServiceData {
  __typename?: 'EntryServiceData';
  code?: Maybe<Scalars['String']['output']>;
  consumed?: Maybe<Scalars['Boolean']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface ExportStatus {
  __typename?: 'ExportStatus';
  status?: Maybe<Scalars['String']['output']>;
}

export interface ExpressAuthorize {
  __typename?: 'ExpressAuthorize';
  customResponsePaymentData: Scalars['String']['output'];
}

/** @deprecated(reason: "Optile payment provider is end of life") */
export interface ExpressPaymentLogin {
  __typename?: 'ExpressPaymentLogin';
  error?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  urlRedirect?: Maybe<Scalars['String']['output']>;
}

/** @deprecated(reason: "Optile payment provider is end of life") */
export interface ExpressPaymentSessionRequestInput {
  additionalInfoFormUrl?: InputMaybe<Scalars['String']['input']>;
  cancelUrl: Scalars['String']['input'];
  cartId: Scalars['String']['input'];
  expressPaymentRequest: Scalars['String']['input'];
  failureUrl: Scalars['String']['input'];
  restType?: InputMaybe<Scalars['String']['input']>;
  successUrl: Scalars['String']['input'];
  summaryUrl: Scalars['String']['input'];
}

export interface Eyecatcher {
  __typename?: 'Eyecatcher';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  style?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use style instead */
  type?: Maybe<Scalars['String']['output']>;
}

export interface FhCampaigns {
  __typename?: 'FHCampaigns';
  campaigns?: Maybe<Array<CampaignData>>;
  fhPreview?: Maybe<FhPreview>;
  id?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface FhSearchResult {
  __typename?: 'FHSearchResult';
  breadcrumbs?: Maybe<Breadcrumb>;
  pagination?: Maybe<Pagination>;
  restType?: Maybe<Scalars['String']['output']>;
  searchResults?: Maybe<Array<Product>>;
  title?: Maybe<Scalars['String']['output']>;
}

export interface FacetValues {
  __typename?: 'FacetValues';
  checkmarkColor?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  multiColor?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  selected?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  valueId?: Maybe<Scalars['String']['output']>;
  webColor?: Maybe<Scalars['String']['output']>;
}

export interface FacetsResult {
  __typename?: 'FacetsResult';
  filterId?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Array<FilterValues>>;
  id?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<FacetValues>>;
}

export interface FaqSuggest {
  __typename?: 'FaqSuggest';
  count?: Maybe<Scalars['Int']['output']>;
  results?: Maybe<Array<FaqSuggestItem>>;
}

export interface FaqSuggestItem {
  __typename?: 'FaqSuggestItem';
  answerContent?: Maybe<Scalars['String']['output']>;
  answerUrl?: Maybe<Scalars['String']['output']>;
  categoryIconName?: Maybe<Scalars['String']['output']>;
  categoryTitle?: Maybe<Scalars['String']['output']>;
  questionTitle?: Maybe<Scalars['String']['output']>;
}

export interface Favourite {
  __typename?: 'Favourite';
  id?: Maybe<Scalars['String']['output']>;
  item?: Maybe<FavouriteItem>;
}

export interface FavouriteItem {
  __typename?: 'FavouriteItem';
  code?: Maybe<Scalars['String']['output']>;
  entryNumber?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  quantity?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface FavouriteItemInput {
  code?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface FavouriteItemsInput {
  items?: InputMaybe<Array<FavouriteItemInput>>;
}

export interface FavouriteListInput {
  item?: InputMaybe<FavouriteItemInput>;
  name?: InputMaybe<Scalars['String']['input']>;
}

export interface Favourites {
  __typename?: 'Favourites';
  favourites?: Maybe<Array<FavouritesList>>;
}

export interface FavouritesAddNewProductInput {
  code?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface FavouritesAddNewProductsInput {
  items?: InputMaybe<Array<FavouritesAddNewProductInput>>;
}

export interface FavouritesList {
  __typename?: 'FavouritesList';
  defaultList?: Maybe<Scalars['Boolean']['output']>;
  entries?: Maybe<Array<Favourite>>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

export interface Feature {
  __typename?: 'Feature';
  code?: Maybe<Scalars['String']['output']>;
  comparable?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  featureUnit?: Maybe<FeatureUnit>;
  featureValues?: Maybe<Array<FeatureValue>>;
  name?: Maybe<Scalars['String']['output']>;
  range?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface FeatureUnit {
  __typename?: 'FeatureUnit';
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  unitType?: Maybe<Scalars['String']['output']>;
}

export interface FeatureValue {
  __typename?: 'FeatureValue';
  restType?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface FhPreview {
  __typename?: 'FhPreview';
  restType?: Maybe<Scalars['String']['output']>;
  showPreviewButton?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface FhSuggestData {
  __typename?: 'FhSuggestData';
  count?: Maybe<Scalars['Int']['output']>;
  fhId?: Maybe<Scalars['String']['output']>;
  formattedId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface FhSuggestItems {
  __typename?: 'FhSuggestItems';
  categories?: Maybe<Array<FhSuggestData>>;
  keywords?: Maybe<Array<FhSuggestData>>;
  products?: Maybe<Array<FhSuggestData>>;
}

export interface FilterValues {
  __typename?: 'FilterValues';
  filterId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxValue?: Maybe<Scalars['Float']['output']>;
  minValue?: Maybe<Scalars['Float']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
}

export interface ForgotPassword {
  __typename?: 'ForgotPassword';
  email?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface ForgotPasswordInput {
  email?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

export interface FredhopperCampaigns {
  __typename?: 'FredhopperCampaigns';
  campaigns?: Maybe<Array<CampaignDataType>>;
  fhPreview?: Maybe<FhPreview>;
  id?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface FutureStock {
  __typename?: 'FutureStock';
  date?: Maybe<Scalars['String']['output']>;
  formattedDate?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  stock?: Maybe<Stock>;
}

export interface GenericTypeString {
  __typename?: 'GenericTypeString';
  type?: Maybe<Scalars['String']['output']>;
}

export interface GeoFacet extends CoreFacet {
  __typename?: 'GeoFacet';
  distances: Array<Scalars['String']['output']>;
  filterId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rid: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
}

export interface GeoPoint {
  __typename?: 'GeoPoint';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface GeoPointInput {
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

export interface Geometry {
  __typename?: 'Geometry';
  location?: Maybe<Coordinates>;
  viewport?: Maybe<Viewport>;
}

export interface HotDealData {
  __typename?: 'HotDealData';
  assignedStore?: Maybe<PointOfService>;
  itemNumber?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface Image {
  __typename?: 'Image';
  altText?: Maybe<Scalars['String']['output']>;
  cdnFilename?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  documentType?: Maybe<Scalars['String']['output']>;
  downloadName?: Maybe<Scalars['String']['output']>;
  fileExtension?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['Int']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  formatOverride?: Maybe<Scalars['String']['output']>;
  galleryIndex?: Maybe<Scalars['Int']['output']>;
  hashCode?: Maybe<Scalars['String']['output']>;
  imageType?: Maybe<Scalars['String']['output']>;
  mediaProvider?: Maybe<Scalars['String']['output']>;
  mime?: Maybe<Scalars['String']['output']>;
  pictureHeight?: Maybe<Scalars['Int']['output']>;
  pictureWidth?: Maybe<Scalars['Int']['output']>;
  qualityOverride?: Maybe<Scalars['Int']['output']>;
  realFilename?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  seoText?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface ImageInput {
  altText?: InputMaybe<Scalars['String']['input']>;
  cdnFilename?: InputMaybe<Scalars['String']['input']>;
  fileExtension?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['Int']['input']>;
  fileType?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  formatOverride?: InputMaybe<Scalars['String']['input']>;
  galleryIndex?: InputMaybe<Scalars['Int']['input']>;
  hashCode?: InputMaybe<Scalars['String']['input']>;
  imageType?: InputMaybe<Scalars['String']['input']>;
  mediaProvider?: InputMaybe<Scalars['String']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  pictureHeight?: InputMaybe<Scalars['Int']['input']>;
  pictureWidth?: InputMaybe<Scalars['Int']['input']>;
  qualityOverride?: InputMaybe<Scalars['Int']['input']>;
  realFilename?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  seoText?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface ImageSearchData {
  files?: InputMaybe<Array<Scalars['Upload']['input']>>;
  imageSearchParams?: InputMaybe<Scalars['JSON']['input']>;
}

export interface Information {
  __typename?: 'Information';
  areaInformation?: Maybe<Area>;
  restType?: Maybe<Scalars['String']['output']>;
  storeInformation?: Maybe<PointOfService>;
}

export interface InstantDeliveryTime {
  __typename?: 'InstantDeliveryTime';
  deliveryTime?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface InternalIncidentData {
  email: Scalars['String']['input'];
  files?: InputMaybe<Array<Scalars['Upload']['input']>>;
  lastName: Scalars['String']['input'];
  message: Scalars['String']['input'];
  orderCode: Scalars['String']['input'];
}

export interface KeyValueObject {
  __typename?: 'KeyValueObject';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface KitchenShowrooms {
  __typename?: 'KitchenShowrooms';
  restType?: Maybe<Scalars['String']['output']>;
  showrooms?: Maybe<Array<Service>>;
}

export interface Language {
  __typename?: 'Language';
  active?: Maybe<Scalars['Boolean']['output']>;
  isocode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nativeName?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface LanguageList {
  __typename?: 'LanguageList';
  languages?: Maybe<Array<Maybe<Language>>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface LeafCategory {
  __typename?: 'LeafCategory';
  code?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productCount?: Maybe<Scalars['Int']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface Link {
  __typename?: 'Link';
  alternativeText?: Maybe<Scalars['String']['output']>;
  openInNewWindow?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface Location {
  __typename?: 'Location';
  department?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  formattedFloorText?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface LoggedInNewsletterData {
  consents?: InputMaybe<Array<Consents>>;
  newsletter?: InputMaybe<NewsletterData>;
}

export interface LoginResponse {
  __typename?: 'LoginResponse';
  isAnonymous?: Maybe<Scalars['Boolean']['output']>;
  orderCartMerged?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface MandantConfig {
  __typename?: 'MandantConfig';
  additionalConfigurations?: Maybe<Array<AdditionalConfiguration>>;
  adyenClientKey?: Maybe<Scalars['String']['output']>;
  affirmators?: Maybe<Array<Affirmator>>;
  /** @deprecated The live shopping is not used anymore */
  aplozeApiKey?: Maybe<Scalars['String']['output']>;
  availableServices?: Maybe<Array<Maybe<ShopService>>>;
  contactSubTopicsByTopic?: Maybe<Scalars['String']['output']>;
  contactTopicsByCategory?: Maybe<Scalars['String']['output']>;
  conversionalExternalJourneyKey?: Maybe<Scalars['String']['output']>;
  conversionalExternalKey?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  defaultZipCodeCoordinates?: Maybe<DefaultCountryZipCodeCoordinates>;
  deliveryCostData?: Maybe<DeliveryCost>;
  deliveryCountries?: Maybe<Array<Country>>;
  deliveryDateBookingTimer?: Maybe<Scalars['Float']['output']>;
  deliveryFreeShippingBasketThreshold?: Maybe<Scalars['Float']['output']>;
  detailedServiceAndDeliveryPath?: Maybe<Scalars['String']['output']>;
  experiments?: Maybe<Scalars['String']['output']>;
  fhStatsigAbTestConfiguration?: Maybe<Scalars['String']['output']>;
  googleMapsApiKey?: Maybe<Scalars['String']['output']>;
  islandDeliveryCosts?: Maybe<Scalars['Float']['output']>;
  languages?: Maybe<LanguageList>;
  legalDocumentTrippTrappUrl?: Maybe<Scalars['String']['output']>;
  miraklAdsApiKey?: Maybe<Scalars['String']['output']>;
  offerPriceViewMode?: Maybe<PriceIndicationType>;
  phoneNumberCountries?: Maybe<Array<PhoneNumberCountry>>;
  poseidonLogoIconName?: Maybe<Scalars['String']['output']>;
  postFreeShippingBasketThreshold?: Maybe<Scalars['Float']['output']>;
  postTaxCouponPattern?: Maybe<Scalars['String']['output']>;
  postagePickupFreeShippingBasketThreshold?: Maybe<Scalars['Float']['output']>;
  postalCarriers?: Maybe<Array<PostalCarrier>>;
  preTaxCouponPattern?: Maybe<Scalars['String']['output']>;
  preselectedPaymentMethod?: Maybe<Scalars['String']['output']>;
  productDatasheetDownloadNameType?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  /** @deprecated Time zone will no longer be requested from mandant but from lookupTables */
  timeZone?: Maybe<Scalars['String']['output']>;
  titles?: Maybe<Array<Title>>;
  trustedShopId?: Maybe<Scalars['String']['output']>;
  userWerkId?: Maybe<Scalars['String']['output']>;
  validationPatterns?: Maybe<ValidationPatterns>;
  zenDeskApiKey?: Maybe<Scalars['String']['output']>;
}

export interface Message {
  __typename?: 'Message';
  code?: Maybe<Scalars['String']['output']>;
  editable?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  translation?: Maybe<Scalars['String']['output']>;
}

export interface MessageData {
  __typename?: 'MessageData';
  arguments?: Maybe<Array<MessageDataArgumentsItems>>;
  message?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface MessageDataArgumentsItems {
  __typename?: 'MessageData_arguments_items';
  /** default field */
  empty?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['MessageValue']['output']>;
}

export interface MessagesResult {
  __typename?: 'MessagesResult';
  messages?: Maybe<Array<Message>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface MetaNavigation {
  __typename?: 'MetaNavigation';
  promotionNodes?: Maybe<Array<Maybe<MetaNavigationNode>>>;
  serviceNodes?: Maybe<Array<Maybe<MetaNavigationNode>>>;
  sidebarMenuNodes?: Maybe<Array<Maybe<MetaNavigationNode>>>;
}

export interface MetaNavigationLink {
  __typename?: 'MetaNavigationLink';
  id: Scalars['String']['output'];
  linkName: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
}

export interface MetaNavigationNode {
  __typename?: 'MetaNavigationNode';
  iconName?: Maybe<Scalars['String']['output']>;
  link?: Maybe<MetaNavigationLink>;
}

export interface MiraklContactCenterData {
  email: Scalars['String']['input'];
  files?: InputMaybe<Array<Scalars['Upload']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  message: Scalars['String']['input'];
  miraklTopic?: InputMaybe<Scalars['String']['input']>;
  orderCode: Scalars['String']['input'];
  sellerId?: InputMaybe<Scalars['Int']['input']>;
  sellerName?: InputMaybe<Scalars['String']['input']>;
}

export interface MiraklContactCenterResponse {
  __typename?: 'MiraklContactCenterResponse';
  messageId?: Maybe<Scalars['String']['output']>;
  threadId?: Maybe<Scalars['String']['output']>;
}

export interface MiraklIncidentData {
  email: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  orderCode: Scalars['String']['input'];
  orderLineId: Scalars['String']['input'];
  reasonCode: Scalars['String']['input'];
}

export interface MiraklIncidentReason {
  __typename?: 'MiraklIncidentReason';
  code: Scalars['String']['output'];
  label: Scalars['String']['output'];
}

export interface MiraklThreadData {
  files?: InputMaybe<Array<Scalars['Upload']['input']>>;
  message: Scalars['String']['input'];
  threadId: Scalars['String']['input'];
}

export interface MultiSelectColorFacet extends CoreFacet {
  __typename?: 'MultiSelectColorFacet';
  facetId: Scalars['String']['output'];
  filterId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rid: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  values: Array<MultiSelectColorValues>;
}

export interface MultiSelectColorValues extends BaseValues {
  __typename?: 'MultiSelectColorValues';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  valueId: Scalars['String']['output'];
  webColor: Scalars['String']['output'];
}

export interface MultiSelectFacet extends CoreFacet {
  __typename?: 'MultiSelectFacet';
  facetId: Scalars['String']['output'];
  filterId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rid: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  values: Array<MultiSelectValues>;
}

export interface MultiSelectValues extends BaseValues {
  __typename?: 'MultiSelectValues';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  valueId: Scalars['String']['output'];
}

export interface MultipartRequestConfiguration {
  __typename?: 'MultipartRequestConfiguration';
  allowedFileExtensions?: Maybe<Scalars['String']['output']>;
  allowedMimeTypes?: Maybe<Scalars['String']['output']>;
  maxExtractedFilesSizeZip?: Maybe<Scalars['Int']['output']>;
  maxFileCountZip?: Maybe<Scalars['Int']['output']>;
  maxNumberOfFiles?: Maybe<Scalars['Int']['output']>;
  maxNumberOfFilesEnabled?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  acceptTermsAndConditions?: Maybe<Scalars['Boolean']['output']>;
  addAddressData?: Maybe<Array<AddressData>>;
  addBonuscard?: Maybe<Scalars['Boolean']['output']>;
  addCartEntry?: Maybe<Cart>;
  /** @deprecated only used in v1 api */
  addFavouriteEntries?: Maybe<FavouritesList>;
  /** @deprecated only used in v1 api */
  addFavouriteEntry?: Maybe<FavouritesList>;
  addFavouriteItems?: Maybe<FavouritesList>;
  applyVoucher?: Maybe<Cart>;
  bookAppointment?: Maybe<Scalars['Boolean']['output']>;
  cancelAppointment?: Maybe<Scalars['Boolean']['output']>;
  changePassword?: Maybe<Scalars['Boolean']['output']>;
  checkAndPostMiraklContactCenter?: Maybe<MiraklContactCenterResponse>;
  clearPaymentSessionData?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Optile payment provider is end of life */
  createExpressPaymentSession?: Maybe<Cart>;
  createFavouriteList?: Maybe<Favourites>;
  createInternalIncident?: Maybe<Scalars['Boolean']['output']>;
  createMiraklIncident?: Maybe<Scalars['Boolean']['output']>;
  createProductReview?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Optile payment provider is end of life */
  createSavedInvoice?: Maybe<Scalars['Boolean']['output']>;
  deleteAddressData?: Maybe<Array<AddressData>>;
  deleteCartEntry?: Maybe<Cart>;
  /** @deprecated Optile payment provider is end of life */
  deleteSavedCreditCard?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Optile payment provider is end of life */
  deleteSavedInvoice?: Maybe<Scalars['Boolean']['output']>;
  doubleOptInBonuscard?: Maybe<BonuscardInfo>;
  expressPaymentAuthorize?: Maybe<ExpressAuthorize>;
  getAddressValidation?: Maybe<AddressValidationResult>;
  login?: Maybe<LoginResponse>;
  logout?: Maybe<LoginResponse>;
  onlineSchedulingReservationAppointment?: Maybe<Scalars['Boolean']['output']>;
  placeOrder?: Maybe<Order>;
  placeOrderConfirmationAdyen?: Maybe<PlaceOrderConfirmationAdyenResponse>;
  placeReservation?: Maybe<PlaceReservationResponse>;
  postAppointment?: Maybe<AppointmentResponse>;
  postContactCenter?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated deprecated in favor of checkAndPostMiraklContactCenter */
  postMiraklContactCenter?: Maybe<MiraklContactCenterResponse>;
  /** @deprecated type changed to ProductCodes */
  postReverseImageSearch?: Maybe<ProductList>;
  productConfiguratorInit?: Maybe<ProductConfigurationResponse>;
  productConfiguratorModify?: Maybe<ProductConfigurationResponse>;
  receiveContentSlots?: Maybe<TrackingResponse>;
  registerUser?: Maybe<RegisterUserResponse>;
  registerUserConfirmation?: Maybe<RegisterUserConfirmationResponse>;
  removeCart?: Maybe<Cart>;
  /** @deprecated only used in v1 api */
  removeFavouriteEntry?: Maybe<FavouritesList>;
  removeFavouriteItem?: Maybe<FavouritesList>;
  removeFavouriteList?: Maybe<Favourites>;
  /** @deprecated Endpoint is not used anymore. It was used for switching to new Fav implementation for anon/logged in users */
  removeFavourites?: Maybe<Scalars['Boolean']['output']>;
  removeVoucher?: Maybe<Cart>;
  renameFavouriteList?: Maybe<FavouritesList>;
  replyToMiraklThread?: Maybe<Scalars['Boolean']['output']>;
  resendBonuscardActivation?: Maybe<Scalars['Boolean']['output']>;
  resetPassword?: Maybe<Scalars['Boolean']['output']>;
  resetPasswordInquiry?: Maybe<Scalars['Boolean']['output']>;
  reverseImageSearch?: Maybe<ProductCodes>;
  /** @deprecated use savePreferences */
  saveGreeting?: Maybe<Preferences>;
  savePreferences?: Maybe<Preferences>;
  sendRecommendationsSeen?: Maybe<Scalars['Boolean']['output']>;
  setAdditionalCartData: Cart;
  subscribeAnonymousNewsletterCDM?: Maybe<Scalars['Boolean']['output']>;
  subscribeLoggedInNewsletterCDM?: Maybe<Scalars['Boolean']['output']>;
  subscribeNewsletter?: Maybe<Scalars['Boolean']['output']>;
  subscribeNewsletterCDM?: Maybe<Scalars['Boolean']['output']>;
  updateAddressData?: Maybe<Array<AddressData>>;
  updateCartAddresses?: Maybe<Cart>;
  updateCartAddressesExpressPayment?: Maybe<Cart>;
  updateCartBonusCard?: Maybe<Cart>;
  updateCartCarrier?: Maybe<Cart>;
  updateCartEntry?: Maybe<Cart>;
  updateCartMerge?: Maybe<Scalars['Boolean']['output']>;
  updateCartPaymentMode?: Maybe<Cart>;
  updateCarts?: Maybe<Cart>;
  updateCustomerCard?: Maybe<CustomerCardDetails>;
  updateCustomerData?: Maybe<CustomerDetails>;
  updatePostalCode?: Maybe<Cart>;
  /** @deprecated Optile payment provider is end of life */
  updateSavedInvoice?: Maybe<Scalars['Boolean']['output']>;
  validatePhoneNumber?: Maybe<ValidatedPhoneNumber>;
  validateToken?: Maybe<ValidateTokenResponse>;
}

export interface MutationAcceptTermsAndConditionsArgs {
  created: Scalars['String']['input'];
}

export interface MutationAddAddressDataArgs {
  addressData: AddressDataInput;
}

export interface MutationAddBonuscardArgs {
  data: BonuscardRegisterRequestInput;
}

export interface MutationAddCartEntryArgs {
  cartId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  configuredId?: InputMaybe<Scalars['String']['input']>;
  offerId?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  qty: Scalars['Int']['input'];
  serviceCodes?: InputMaybe<Scalars['String']['input']>;
  storeCode?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationAddFavouriteEntriesArgs {
  data: FavouritesAddNewProductsInput;
}

export interface MutationAddFavouriteEntryArgs {
  data: FavouritesAddNewProductInput;
}

export interface MutationAddFavouriteItemsArgs {
  favouriteData: FavouriteItemsInput;
  favouriteListId: Scalars['String']['input'];
}

export interface MutationApplyVoucherArgs {
  bonuscard?: InputMaybe<Scalars['String']['input']>;
  cartId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  pin?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationBookAppointmentArgs {
  data: DeliveryAppointmentData;
}

export interface MutationCancelAppointmentArgs {
  data?: InputMaybe<AppointmentCancelationInput>;
}

export interface MutationChangePasswordArgs {
  oldPassword?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
}

export interface MutationCheckAndPostMiraklContactCenterArgs {
  data: MiraklContactCenterData;
}

export interface MutationClearPaymentSessionDataArgs {
  cartId?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationCreateExpressPaymentSessionArgs {
  data: ExpressPaymentSessionRequestInput;
  paymentMode: Scalars['String']['input'];
}

export interface MutationCreateFavouriteListArgs {
  favouriteData: FavouriteListInput;
}

export interface MutationCreateInternalIncidentArgs {
  data: InternalIncidentData;
}

export interface MutationCreateMiraklIncidentArgs {
  data: MiraklIncidentData;
}

export interface MutationCreateProductReviewArgs {
  data: ReviewCreateInput;
  email?: InputMaybe<Scalars['String']['input']>;
  mac?: InputMaybe<Scalars['String']['input']>;
  orderCode?: InputMaybe<Scalars['String']['input']>;
  orderProductCodes?: InputMaybe<Scalars['String']['input']>;
  productCode: Scalars['String']['input'];
  token: Scalars['String']['input'];
  year?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationCreateSavedInvoiceArgs {
  savedInvoiceInput?: InputMaybe<SavedInvoiceInput>;
}

export interface MutationDeleteAddressDataArgs {
  addressId: Scalars['String']['input'];
}

export interface MutationDeleteCartEntryArgs {
  cartId?: InputMaybe<Scalars['String']['input']>;
  entryId: Scalars['Int']['input'];
}

export interface MutationDeleteSavedCreditCardArgs {
  cartId: Scalars['String']['input'];
  paymentModeId: Scalars['String']['input'];
}

export interface MutationDeleteSavedInvoiceArgs {
  paymentModeId: Scalars['String']['input'];
}

export interface MutationDoubleOptInBonuscardArgs {
  code: Scalars['String']['input'];
}

export interface MutationExpressPaymentAuthorizeArgs {
  cartId: Scalars['String']['input'];
  paymentMode: Scalars['String']['input'];
}

export interface MutationGetAddressValidationArgs {
  country: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
  street: Scalars['String']['input'];
  town: Scalars['String']['input'];
}

export interface MutationLoginArgs {
  userCredentials: UserBaseLoginInput;
}

export interface MutationOnlineSchedulingReservationAppointmentArgs {
  data: ReservationTimeslotData;
}

export interface MutationPlaceOrderArgs {
  data: PlaceOrderRequestInput;
}

export interface MutationPlaceOrderConfirmationAdyenArgs {
  data: PlaceOrderConfirmationAdyenInput;
}

export interface MutationPlaceReservationArgs {
  data: PlaceReservationData;
}

export interface MutationPostAppointmentArgs {
  data?: InputMaybe<AppointmentData>;
}

export interface MutationPostContactCenterArgs {
  data: ContactCenterData;
  isComplaint?: InputMaybe<Scalars['Boolean']['input']>;
  isMarketplaceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface MutationPostMiraklContactCenterArgs {
  data: ContactCenterData;
}

export interface MutationPostReverseImageSearchArgs {
  data: ImageSearchData;
}

export interface MutationProductConfiguratorInitArgs {
  productCode: Scalars['String']['input'];
}

export interface MutationProductConfiguratorModifyArgs {
  configuredId: Scalars['String']['input'];
  productCode: Scalars['String']['input'];
}

export interface MutationReceiveContentSlotsArgs {
  data: Scalars['String']['input'];
}

export interface MutationRegisterUserArgs {
  data: RegisterUserInput;
}

export interface MutationRegisterUserConfirmationArgs {
  dblOptInKey: Scalars['String']['input'];
}

export interface MutationRemoveCartArgs {
  cartId?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationRemoveFavouriteEntryArgs {
  entryId: Scalars['String']['input'];
  favouritesListsId: Scalars['String']['input'];
}

export interface MutationRemoveFavouriteItemArgs {
  favouriteItemId: Scalars['String']['input'];
  favouriteListId: Scalars['String']['input'];
}

export interface MutationRemoveFavouriteListArgs {
  favouriteListId: Scalars['String']['input'];
}

export interface MutationRemoveVoucherArgs {
  cartId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationRenameFavouriteListArgs {
  favouriteData: FavouriteListInput;
  favouriteListId: Scalars['String']['input'];
}

export interface MutationReplyToMiraklThreadArgs {
  data: MiraklThreadData;
}

export interface MutationResendBonuscardActivationArgs {
  captcha?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
}

export interface MutationResetPasswordArgs {
  captcha?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
  tokenId: Scalars['String']['input'];
}

export interface MutationResetPasswordInquiryArgs {
  captcha?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
}

export interface MutationReverseImageSearchArgs {
  data: ImageSearchData;
}

export interface MutationSaveGreetingArgs {
  greeting: Scalars['String']['input'];
}

export interface MutationSavePreferencesArgs {
  data: Scalars['JSON']['input'];
}

export interface MutationSendRecommendationsSeenArgs {
  seenProducts: Array<InputMaybe<Scalars['String']['input']>>;
}

export interface MutationSetAdditionalCartDataArgs {
  additionalData: AdditionalDataInput;
  cartId: Scalars['String']['input'];
}

export interface MutationSubscribeAnonymousNewsletterCdmArgs {
  data: AnonymousNewsletterData;
}

export interface MutationSubscribeLoggedInNewsletterCdmArgs {
  data: LoggedInNewsletterData;
}

export interface MutationSubscribeNewsletterArgs {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['String']['input']>;
  timendoArea?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationSubscribeNewsletterCdmArgs {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  socialAndPartnersConsent?: InputMaybe<Scalars['Boolean']['input']>;
  time: Scalars['String']['input'];
}

export interface MutationUpdateAddressDataArgs {
  addressData: AddressDataInput;
  addressId: Scalars['String']['input'];
}

export interface MutationUpdateCartAddressesArgs {
  cartId: Scalars['String']['input'];
  data: CartAddressesRequestInput;
}

export interface MutationUpdateCartAddressesExpressPaymentArgs {
  cartId: Scalars['String']['input'];
  data: CartAddressesExpressPaymentRequestInput;
}

export interface MutationUpdateCartBonusCardArgs {
  bonuscard?: InputMaybe<Scalars['String']['input']>;
  cartId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationUpdateCartCarrierArgs {
  carrier: Scalars['String']['input'];
  cartId: Scalars['String']['input'];
}

export interface MutationUpdateCartEntryArgs {
  cartId?: InputMaybe<Scalars['String']['input']>;
  configuredId?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<ServicesData>;
  deliveryMode?: InputMaybe<Scalars['String']['input']>;
  entryId: Scalars['Int']['input'];
  productCode?: InputMaybe<Scalars['String']['input']>;
  qty: Scalars['Int']['input'];
  storeCode?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationUpdateCartMergeArgs {
  cartId: Scalars['String']['input'];
}

export interface MutationUpdateCartPaymentModeArgs {
  cartId: Scalars['String']['input'];
  data: PaymentModeRequestInput;
}

export interface MutationUpdateCartsArgs {
  cartId: Scalars['String']['input'];
  cartIdAnon: Scalars['String']['input'];
  type: Scalars['String']['input'];
}

export interface MutationUpdateCustomerCardArgs {
  confirmed: Scalars['Boolean']['input'];
}

export interface MutationUpdateCustomerDataArgs {
  customerData: CustomerDataInput;
}

export interface MutationUpdatePostalCodeArgs {
  cartId: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
}

export interface MutationUpdateSavedInvoiceArgs {
  updateSavedInvoiceInput?: InputMaybe<UpdateSavedInvoiceInput>;
}

export interface MutationValidatePhoneNumberArgs {
  countryIso: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
}

export interface MutationValidateTokenArgs {
  captcha?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  tokenId: Scalars['String']['input'];
}

export interface Navigation {
  __typename?: 'Navigation';
  breadcrumbs?: Maybe<Array<Breadcrumb>>;
  cmsPageType?: Maybe<CmsPageType>;
  code?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  masterTemplate?: Maybe<CmsTemplate>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  seoData?: Maybe<Seo>;
  smartedit?: Maybe<CmsSmartEdit>;
  type?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
}

export interface NearestAvailableSubsidiaries {
  __typename?: 'NearestAvailableSubsidiaries';
  reservationSubsidiaries?: Maybe<Array<SubsidiaryInformation>>;
  restType?: Maybe<Scalars['String']['output']>;
  selfServiceSubsidiaries?: Maybe<Array<SubsidiaryInformation>>;
}

export interface NewsletterData {
  created?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
}

export interface NewsletterEmail {
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  created?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
}

export interface NewsletterInput {
  captcha?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

export interface Offer {
  __typename?: 'Offer';
  offerId?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface OnlineSchedulingConfiguration {
  __typename?: 'OnlineSchedulingConfiguration';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface OnlineSchedulingConfigurations {
  __typename?: 'OnlineSchedulingConfigurations';
  additionalInfo?: Maybe<Array<OnlineSchedulingConfiguration>>;
}

export interface OnlineSchedulingOrder {
  __typename?: 'OnlineSchedulingOrder';
  city?: Maybe<Scalars['String']['output']>;
  customerCardNumber?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  orderNumber?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
}

export interface OnlineSchedulingStore {
  __typename?: 'OnlineSchedulingStore';
  address?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  openingHours?: Maybe<Scalars['String']['output']>;
}

export interface OnlineSchedulingStores {
  __typename?: 'OnlineSchedulingStores';
  stores?: Maybe<Array<OnlineSchedulingStore>>;
}

export interface OnlineSchedulingTimeslot {
  __typename?: 'OnlineSchedulingTimeslot';
  available?: Maybe<Scalars['Boolean']['output']>;
  bookingId?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  timeslot_from?: Maybe<Scalars['String']['output']>;
  timeslot_to?: Maybe<Scalars['String']['output']>;
  weekend?: Maybe<Scalars['Boolean']['output']>;
}

export interface OnlineSchedulingTimeslots {
  __typename?: 'OnlineSchedulingTimeslots';
  timeSlots: Array<OnlineSchedulingTimeslot>;
}

export interface OpeningDay {
  __typename?: 'OpeningDay';
  closed?: Maybe<Scalars['Boolean']['output']>;
  closingTime?: Maybe<OpeningHours>;
  code?: Maybe<Scalars['String']['output']>;
  formattedDate?: Maybe<Scalars['String']['output']>;
  openingTime?: Maybe<OpeningHours>;
  weekDay?: Maybe<Scalars['String']['output']>;
}

export interface OpeningHours {
  __typename?: 'OpeningHours';
  formattedHour?: Maybe<Scalars['String']['output']>;
}

export interface OpeningSchedule {
  __typename?: 'OpeningSchedule';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  regularOpeningDays?: Maybe<Array<OpeningDay>>;
  restType?: Maybe<Scalars['String']['output']>;
  restaurantOpeningDays?: Maybe<Array<OpeningDay>>;
  specialDayOpeningList?: Maybe<Array<SpecialOpeningDay>>;
  specialOpeningDays?: Maybe<Array<OpeningDay>>;
  weekDayOpeningList?: Maybe<Array<WeekdayOpeningDay>>;
}

export interface OpeningScheduleInput {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  specialDayOpeningList?: InputMaybe<Array<SpecialOpeningDayInput>>;
  weekDayOpeningList?: InputMaybe<Array<WeekdayOpeningDayInput>>;
}

export interface Order {
  __typename?: 'Order';
  appliedOrderPromotions?: Maybe<Array<PromotionResult>>;
  appliedProductPromotions?: Maybe<Array<PromotionResult>>;
  appliedVoucherDataList?: Maybe<Array<Voucher>>;
  appliedVouchers?: Maybe<Array<Voucher>>;
  calculated?: Maybe<Scalars['Boolean']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  consignments?: Maybe<Array<Consignment>>;
  created?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  deliveryAddress?: Maybe<Address>;
  deliveryCost?: Maybe<Price>;
  deliveryCostAssembling?: Maybe<Price>;
  deliveryCostWithoutSpecial?: Maybe<Price>;
  deliveryItemsQuantity?: Maybe<Scalars['String']['output']>;
  deliveryMode?: Maybe<DeliveryMode>;
  deliveryOrderGroups?: Maybe<Array<DeliveryOrderEntryGroup>>;
  deliveryStatus?: Maybe<Scalars['String']['output']>;
  deliveryStatusDisplay?: Maybe<Scalars['String']['output']>;
  entries?: Maybe<Array<OrderEntry>>;
  guestCustomer?: Maybe<Scalars['Boolean']['output']>;
  guid?: Maybe<Scalars['String']['output']>;
  invoiceUrl?: Maybe<Scalars['String']['output']>;
  invoicesReady?: Maybe<Scalars['Boolean']['output']>;
  messages?: Maybe<Array<MessageData>>;
  mhsNumber?: Maybe<Scalars['String']['output']>;
  net?: Maybe<Scalars['Boolean']['output']>;
  orderDiscounts?: Maybe<Price>;
  orderType?: Maybe<Scalars['String']['output']>;
  /** @deprecated We are no longer using this information in poseidon */
  overallDeliveryTime?: Maybe<OverallDeliveryTime>;
  parentOrder?: Maybe<Order>;
  parentOrderCode?: Maybe<Scalars['String']['output']>;
  paymentAddress?: Maybe<Address>;
  paymentInfo?: Maybe<PaymentDetails>;
  paymentMode?: Maybe<PaymentMode>;
  paymentModes?: Maybe<Array<PaymentMode>>;
  /** @deprecated Optile payment provider is end of life */
  paymentSessionTokens?: Maybe<Array<XPaymentSession>>;
  paymentStart?: Maybe<PaymentStart>;
  pickupItemsQuantity?: Maybe<Scalars['String']['output']>;
  pickupOrderGroups?: Maybe<Array<PickupOrderEntryGroup>>;
  postalCode?: Maybe<Scalars['String']['output']>;
  potentialOrderPromotions?: Maybe<Array<PromotionResult>>;
  potentialProductPromotions?: Maybe<Array<PromotionResult>>;
  productDiscounts?: Maybe<Price>;
  providerParameters?: Maybe<Array<ProviderParameter>>;
  restType?: Maybe<Scalars['String']['output']>;
  saveAddresses?: Maybe<Scalars['Boolean']['output']>;
  selectedStore?: Maybe<PointOfService>;
  site?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusDisplay?: Maybe<Scalars['String']['output']>;
  store?: Maybe<Scalars['String']['output']>;
  subOrders?: Maybe<Array<Order>>;
  subTotal?: Maybe<Price>;
  taxes?: Maybe<Array<Price>>;
  totalDiscounts?: Maybe<Price>;
  totalItems?: Maybe<Scalars['Int']['output']>;
  totalNetPriceWithoutCoupons?: Maybe<Price>;
  totalPrice?: Maybe<Price>;
  totalPriceWithTax?: Maybe<Price>;
  totalPriceWithoutCoupons?: Maybe<Price>;
  totalTax?: Maybe<Price>;
  unconsignedEntries?: Maybe<Array<OrderEntry>>;
  user?: Maybe<Principal>;
  voucherSum?: Maybe<Price>;
}

export interface OrderDeliveryTime {
  __typename?: 'OrderDeliveryTime';
  maxTime?: Maybe<Scalars['Int']['output']>;
  minTime?: Maybe<Scalars['Int']['output']>;
  timeRangeText?: Maybe<Scalars['String']['output']>;
}

export interface OrderEntry {
  __typename?: 'OrderEntry';
  additionalExpenses?: Maybe<Array<EcoTaxExpense>>;
  appliedProductPromotionNames?: Maybe<Array<Scalars['String']['output']>>;
  basePrice?: Maybe<Price>;
  carrier?: Maybe<Scalars['String']['output']>;
  configurationInfos?: Maybe<Array<ConfigurationInfo>>;
  /** @deprecated Duplicated, use customShippingType inside deliveryModes */
  customShippingType?: Maybe<Scalars['String']['output']>;
  deliveryMode?: Maybe<DeliveryMode>;
  deliveryModes?: Maybe<Array<DeliveryMode>>;
  deliveryPointOfService?: Maybe<PointOfService>;
  deliveryTime?: Maybe<Scalars['Int']['output']>;
  deliveryTimeText?: Maybe<Scalars['String']['output']>;
  entryNumber?: Maybe<Scalars['Int']['output']>;
  freeGiftProductVoucher?: Maybe<Scalars['Boolean']['output']>;
  oldTotalPrice?: Maybe<Price>;
  orderableInSubsidiary?: Maybe<Scalars['Boolean']['output']>;
  pointOfService?: Maybe<PointOfService>;
  product?: Maybe<Product>;
  quantity?: Maybe<Scalars['Int']['output']>;
  reservableInSubsidiary?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  selectedDeliveryMode?: Maybe<Scalars['String']['output']>;
  selectedQuantity?: Maybe<Scalars['Int']['output']>;
  services?: Maybe<Array<EntryServiceData>>;
  shopInformation?: Maybe<ShopInformation>;
  totalPrice?: Maybe<Price>;
  unitBasedCalculation?: Maybe<UnitBasedCalculation>;
  updateable?: Maybe<Scalars['Boolean']['output']>;
}

export interface OrderListing {
  __typename?: 'OrderListing';
  orders?: Maybe<Array<Order>>;
  pagination?: Maybe<Pagination>;
  restType?: Maybe<Scalars['String']['output']>;
  sorts?: Maybe<Array<Sort>>;
}

export interface OrderStatus {
  __typename?: 'OrderStatus';
  customerName?: Maybe<Scalars['String']['output']>;
  orders?: Maybe<Array<OrderStatusOrder>>;
  purchaseType?: Maybe<Scalars['String']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  responsibleSubsidiary?: Maybe<PointOfService>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface OrderStatusEntry {
  __typename?: 'OrderStatusEntry';
  currentState?: Maybe<Scalars['String']['output']>;
  deliveryType?: Maybe<Scalars['String']['output']>;
  positions?: Maybe<Array<OrderStatusPosition>>;
  progressBarStatusList?: Maybe<Array<OrderStatusProgress>>;
  restType?: Maybe<Scalars['String']['output']>;
  selfServicePickupInformation?: Maybe<OrderStatusSelfServicePickupInformation>;
}

export interface OrderStatusOrder {
  __typename?: 'OrderStatusOrder';
  deliveryAppointmentAvailable?: Maybe<Scalars['Boolean']['output']>;
  linkedOrders?: Maybe<Array<Scalars['String']['output']>>;
  orderCode?: Maybe<Scalars['String']['output']>;
  orderStatusEntries?: Maybe<Array<OrderStatusEntry>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface OrderStatusPickupSubsidiary {
  __typename?: 'OrderStatusPickupSubsidiary';
  address?: Maybe<Address>;
  name?: Maybe<Scalars['String']['output']>;
  openingHoursText?: Maybe<Scalars['String']['output']>;
  paymentOptions?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface OrderStatusPosition {
  __typename?: 'OrderStatusPosition';
  articleNumber?: Maybe<Scalars['String']['output']>;
  assembly?: Maybe<Scalars['Boolean']['output']>;
  positionNumber?: Maybe<Scalars['Int']['output']>;
  productDescription?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface OrderStatusProgress {
  __typename?: 'OrderStatusProgress';
  current?: Maybe<Scalars['Boolean']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  done?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
}

export interface OrderStatusSelfServicePickupInformation {
  __typename?: 'OrderStatusSelfServicePickupInformation';
  pickupSubsidiary?: Maybe<OrderStatusPickupSubsidiary>;
  restType?: Maybe<Scalars['String']['output']>;
  transporterReservationEndDate?: Maybe<Scalars['String']['output']>;
  transporterReservationStartDate?: Maybe<Scalars['String']['output']>;
}

export interface OrdersResponse {
  __typename?: 'OrdersResponse';
  orders?: Maybe<Array<Order>>;
  restType?: Maybe<Scalars['String']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
}

export interface OverallDeliveryTime {
  __typename?: 'OverallDeliveryTime';
  deliveryTimeList?: Maybe<Array<Maybe<DeliveryTimeList>>>;
}

export interface Pagination {
  __typename?: 'Pagination';
  currentPage?: Maybe<Scalars['Int']['output']>;
  offsetAdjustment?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Scalars['String']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
  totalResults?: Maybe<Scalars['Int']['output']>;
}

export interface PaymentDetails {
  __typename?: 'PaymentDetails';
  accountHolderName?: Maybe<Scalars['String']['output']>;
  billingAddress?: Maybe<Address>;
  cardNumber?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<CardType>;
  defaultPayment?: Maybe<Scalars['Boolean']['output']>;
  expiryMonth?: Maybe<Scalars['String']['output']>;
  expiryYear?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  issueNumber?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  saved?: Maybe<Scalars['Boolean']['output']>;
  startMonth?: Maybe<Scalars['String']['output']>;
  startYear?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
}

export interface PaymentMode {
  __typename?: 'PaymentMode';
  active?: Maybe<Scalars['Boolean']['output']>;
  additionalDetails?: Maybe<Scalars['AdditionalDetails']['output']>;
  /** @deprecated Optile payment provider is end of life */
  birthdate?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Optile payment provider is end of life */
  expiryMonth?: Maybe<Scalars['String']['output']>;
  /** @deprecated Optile payment provider is end of life */
  expiryYear?: Maybe<Scalars['String']['output']>;
  /** @deprecated Optile payment provider is end of life */
  holderName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Optile payment provider is end of life */
  iframe?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Image>;
  /** @deprecated Optile payment provider is end of life */
  maskedAccount?: Maybe<Scalars['String']['output']>;
  maxOrderValue?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Optile payment provider is end of life */
  method?: Maybe<Scalars['String']['output']>;
  minOrderValue?: Maybe<Scalars['Int']['output']>;
  mobileDescription?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** @deprecated Optile payment provider is end of life */
  networkCode?: Maybe<Scalars['String']['output']>;
  /** @deprecated Optile payment provider is end of life */
  number?: Maybe<Scalars['String']['output']>;
  /** @deprecated Optile payment provider is end of life */
  optileNetworkCode?: Maybe<Scalars['String']['output']>;
  paymentCost?: Maybe<Price>;
  /** @deprecated Optile payment provider is end of life */
  paymentModeId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Optile payment provider is end of life */
  paymentModeUrl?: Maybe<Scalars['String']['output']>;
  pgProvider?: Maybe<Scalars['String']['output']>;
  /** @deprecated Optile payment provider is end of life */
  provisional?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use pspGroupedCode instead. */
  pspCode?: Maybe<Scalars['String']['output']>;
  pspGroupedCode?: Maybe<Scalars['String']['output']>;
  redirectHint?: Maybe<Scalars['String']['output']>;
  redirectMessage?: Maybe<Scalars['String']['output']>;
  redirectPageHeader?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  /** @deprecated Optile payment provider is end of life */
  state?: Maybe<Scalars['String']['output']>;
  /** @deprecated Optile payment provider is end of life */
  type?: Maybe<Scalars['String']['output']>;
}

export interface PaymentModeRequestInput {
  acceptedAgb?: InputMaybe<Scalars['Boolean']['input']>;
  cartId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  temporary?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface PaymentModes {
  __typename?: 'PaymentModes';
  paymentModes?: Maybe<Array<PaymentMode>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface PaymentStart {
  __typename?: 'PaymentStart';
  customResponsePaymentData?: Maybe<Scalars['CustomResponsePaymentData']['output']>;
  error?: Maybe<Scalars['Boolean']['output']>;
  htmlFragment?: Maybe<Scalars['String']['output']>;
  interactionCode?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  returnCode?: Maybe<Scalars['String']['output']>;
  urlRedirect?: Maybe<Scalars['String']['output']>;
}

export interface PhoneNumberCountry {
  __typename?: 'PhoneNumberCountry';
  iso: Scalars['String']['output'];
  name: Scalars['String']['output'];
  prefix: Scalars['String']['output'];
  rank?: Maybe<Scalars['Int']['output']>;
}

export interface PickupOrderEntryGroup {
  __typename?: 'PickupOrderEntryGroup';
  deliveryPointOfService?: Maybe<PointOfService>;
  distance?: Maybe<Scalars['Float']['output']>;
  entries?: Maybe<Array<OrderEntry>>;
  quantity?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  totalPriceWithTax?: Maybe<Price>;
}

export interface PlaceOrderConfirmationAdyenInput {
  cartId: Scalars['String']['input'];
  facilitatorAccessToken: Scalars['String']['input'];
  isExpress?: InputMaybe<Scalars['Boolean']['input']>;
  orderId: Scalars['String']['input'];
  payerId: Scalars['String']['input'];
  paymentData: Scalars['String']['input'];
  paymentSource: Scalars['String']['input'];
}

export interface PlaceOrderConfirmationAdyenResponse {
  __typename?: 'PlaceOrderConfirmationAdyenResponse';
  additionalData: PlaceOrderConfirmationAdyenResponseAdditionalData;
  merchantReference: Scalars['String']['output'];
  paymentMethod: GenericTypeString;
  pspReference: Scalars['String']['output'];
  resultCode: Scalars['String']['output'];
}

export interface PlaceOrderConfirmationAdyenResponseAdditionalData {
  __typename?: 'PlaceOrderConfirmationAdyenResponseAdditionalData';
  entry?: Maybe<Array<KeyValueObject>>;
  paypalEmail: Scalars['String']['output'];
}

export interface PlaceOrderRequestInput {
  cancelUrl?: InputMaybe<Scalars['String']['input']>;
  cartId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Made obsolete by the paymentData field below. */
  encryptedCardData?: InputMaybe<EncryptedCardDataInput>;
  failureUrl?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Was used for multiorders feature, which is removed. */
  isForced?: InputMaybe<Scalars['Boolean']['input']>;
  paymentData?: InputMaybe<Scalars['CustomPaymentData']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  successUrl?: InputMaybe<Scalars['String']['input']>;
}

export interface PlaceReservationData {
  acceptedGdpr?: InputMaybe<Scalars['Boolean']['input']>;
  cartId?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['Boolean']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  ngo?: InputMaybe<Scalars['Boolean']['input']>;
  phone1?: InputMaybe<Scalars['String']['input']>;
  phoneNumberIsoCode?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  streetname?: InputMaybe<Scalars['String']['input']>;
  streetnumber?: InputMaybe<Scalars['String']['input']>;
  town?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
}

export interface PlaceReservationResponse {
  __typename?: 'PlaceReservationResponse';
  appliedOrderPromotions?: Maybe<Array<PromotionResult>>;
  appliedVoucherDataList?: Maybe<Array<Voucher>>;
  calculated?: Maybe<Scalars['Boolean']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  deliveryAddress?: Maybe<Address>;
  deliveryCost?: Maybe<Price>;
  deliveryCostAssembling?: Maybe<Price>;
  entries?: Maybe<Array<OrderEntry>>;
  guestCustomer?: Maybe<Scalars['Boolean']['output']>;
  net?: Maybe<Scalars['Boolean']['output']>;
  orderType?: Maybe<Scalars['String']['output']>;
  paymentAddress?: Maybe<Address>;
  status?: Maybe<Scalars['String']['output']>;
  store?: Maybe<Scalars['String']['output']>;
  subOrders?: Maybe<Array<Order>>;
  subTotal?: Maybe<Price>;
  totalItems?: Maybe<Scalars['Int']['output']>;
  totalPrice?: Maybe<Price>;
  totalPriceWithTax?: Maybe<Price>;
  totalTax?: Maybe<Price>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Principal>;
  voucherSum?: Maybe<Price>;
}

export interface PocoContentTokenResponse {
  __typename?: 'PocoContentTokenResponse';
  token?: Maybe<Scalars['String']['output']>;
}

export interface PointOfService {
  __typename?: 'PointOfService';
  address?: Maybe<Address>;
  attachedPointOfServices?: Maybe<Array<PointOfService>>;
  bigPicture?: Maybe<Image>;
  code?: Maybe<Scalars['String']['output']>;
  dailyMenu?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  distanceFromPostalCode?: Maybe<Scalars['Float']['output']>;
  distanceKm?: Maybe<Scalars['Float']['output']>;
  features?: Maybe<PointOfServiceFeatures>;
  formattedDistance?: Maybe<Scalars['String']['output']>;
  geoPoint?: Maybe<GeoPoint>;
  locationType?: Maybe<Scalars['String']['output']>;
  longDescription?: Maybe<Scalars['String']['output']>;
  mapIcon?: Maybe<Image>;
  name?: Maybe<Scalars['String']['output']>;
  newFlag?: Maybe<Scalars['Boolean']['output']>;
  openingHours?: Maybe<OpeningSchedule>;
  openingHoursText?: Maybe<Scalars['String']['output']>;
  regularOpeningHours?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  restaurantDescription?: Maybe<Scalars['String']['output']>;
  restaurantInfo?: Maybe<Scalars['String']['output']>;
  restaurantOpeningHours?: Maybe<Scalars['String']['output']>;
  seoData?: Maybe<PointOfServiceSeoData>;
  services?: Maybe<Array<PosService>>;
  smallPicture?: Maybe<Image>;
  specialOpeningHours?: Maybe<Scalars['String']['output']>;
  storeContent?: Maybe<Scalars['String']['output']>;
  storeImages?: Maybe<Array<Image>>;
  timendoGroupId?: Maybe<Scalars['String']['output']>;
  timendoInterventionId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface PointOfServiceInput {
  address?: InputMaybe<AddressInput>;
  attachedPointOfServices?: InputMaybe<Array<PointOfServiceInput>>;
  bigPicture?: InputMaybe<ImageInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  distanceKm?: InputMaybe<Scalars['Float']['input']>;
  features?: InputMaybe<PointOfServiceFeaturesInput>;
  formattedDistance?: InputMaybe<Scalars['String']['input']>;
  geoPoint?: InputMaybe<GeoPointInput>;
  longDescription?: InputMaybe<Scalars['String']['input']>;
  mapIcon?: InputMaybe<ImageInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  openingHours?: InputMaybe<OpeningScheduleInput>;
  openingHoursText?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  services?: InputMaybe<Array<PosServiceInput>>;
  smallPicture?: InputMaybe<ImageInput>;
  storeContent?: InputMaybe<Scalars['String']['input']>;
  storeImages?: InputMaybe<Array<ImageInput>>;
  timendoGroupId?: InputMaybe<Scalars['String']['input']>;
  timendoInterventionId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface PointOfServiceFeatures {
  __typename?: 'PointOfService_features';
  empty?: Maybe<Scalars['String']['output']>;
}

export interface PointOfServiceFeaturesInput {
  empty?: InputMaybe<Scalars['String']['input']>;
}

export interface PointOfServiceSeoData {
  __typename?: 'PointOfService_seoData';
  canonicalUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  noFollow?: Maybe<Scalars['Boolean']['output']>;
  noIndex?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface PointOfServices {
  __typename?: 'PointOfServices';
  defaultPostalCode?: Maybe<PostalCode>;
  pointOfServices?: Maybe<Array<PointOfService>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface PosService {
  __typename?: 'PosService';
  code?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Image>;
  iconName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface PosServiceInput {
  code?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<ImageInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

export interface PostalCarrier {
  __typename?: 'PostalCarrier';
  code: Scalars['String']['output'];
}

export interface PostalCode {
  __typename?: 'PostalCode';
  id?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  town?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface PostalCodes {
  __typename?: 'PostalCodes';
  postalcodes?: Maybe<Array<PostalCode>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface Preferences {
  __typename?: 'Preferences';
  data?: Maybe<Scalars['JSONObject']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  valid?: Maybe<Scalars['Boolean']['output']>;
}

export interface Price {
  __typename?: 'Price';
  currencyIso?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  formattedValue?: Maybe<Scalars['String']['output']>;
  maxQuantity?: Maybe<Scalars['String']['output']>;
  minQuantity?: Maybe<Scalars['String']['output']>;
  priceType?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  specialOfferTypeData?: Maybe<SpecialOfferType>;
  startTime?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

export enum PriceIndicationType {
  Regular = 'REGULAR',
  Trimmed = 'TRIMMED',
  Uvp = 'UVP',
  UvpLight = 'UVP_LIGHT',
}

export interface PriceRange {
  __typename?: 'PriceRange';
  maxPrice?: Maybe<Price>;
  minPrice?: Maybe<Price>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface Principal {
  __typename?: 'Principal';
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
}

export interface Product extends BaseSearchResult {
  __typename?: 'Product';
  adInfo?: Maybe<AdInfo>;
  additionalDescription?: Maybe<Array<ProductAdditionalDescription>>;
  additionalProductExpenses?: Maybe<Array<EcoTax>>;
  affirmators?: Maybe<Array<Affirmator>>;
  attributes?: Maybe<ProductAttributes>;
  augmentedReality?: Maybe<ProductAugmentedReality>;
  availabilityStatus?: Maybe<Scalars['String']['output']>;
  availableForPickup?: Maybe<Scalars['Boolean']['output']>;
  averageRating?: Maybe<Scalars['Float']['output']>;
  baseOptions?: Maybe<Array<BaseOption>>;
  baseProduct?: Maybe<Scalars['String']['output']>;
  brands?: Maybe<Array<Brand>>;
  breadcrumbs?: Maybe<Array<Breadcrumb>>;
  campaignContentShown?: Maybe<Scalars['Boolean']['output']>;
  categories?: Maybe<Array<Category>>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  characteristicData?: Maybe<ProductCharacteristicData>;
  classifications?: Maybe<Array<Classification>>;
  code?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  configurable?: Maybe<Scalars['Boolean']['output']>;
  configuratorData?: Maybe<ProductConfigurator>;
  configuratorType?: Maybe<Scalars['String']['output']>;
  configuratorUrl?: Maybe<Scalars['String']['output']>;
  configuredProduct?: Maybe<Scalars['Boolean']['output']>;
  configuredProductData?: Maybe<ConfiguredProductData>;
  consultationShown?: Maybe<Scalars['Boolean']['output']>;
  dataSheets?: Maybe<Array<Image>>;
  deliveryData?: Maybe<ProductDeliveryData>;
  /** @deprecated Use the field `freeDelivery` */
  deliveryForFree?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  eligibleForFreeDelivery?: Maybe<Scalars['Boolean']['output']>;
  energyEfficiencyData?: Maybe<EnergyEfficiencyData>;
  eyecatcherABIsShown?: Maybe<Scalars['Boolean']['output']>;
  eyecatchers?: Maybe<Array<Eyecatcher>>;
  firstItemOfPage?: Maybe<Scalars['Int']['output']>;
  freeDelivery?: Maybe<Scalars['Boolean']['output']>;
  futureStocks?: Maybe<Array<FutureStock>>;
  hasRatings?: Maybe<Scalars['Boolean']['output']>;
  hotdealData?: Maybe<HotDealData>;
  id?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<Image>>;
  legalInformationShown?: Maybe<Scalars['Boolean']['output']>;
  liveshopping?: Maybe<Scalars['Boolean']['output']>;
  manufactureCountry?: Maybe<ProductCountryOfOrigin>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  mediaData?: Maybe<ProductMediaData>;
  multidimensional?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfReviews?: Maybe<Scalars['Int']['output']>;
  onlineOnly?: Maybe<Scalars['Boolean']['output']>;
  orderData?: Maybe<ProductOrderData>;
  originCountry?: Maybe<ProductCountryOfOrigin>;
  originalresponse?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Image>;
  potentialPromotions?: Maybe<Array<Promotion>>;
  price?: Maybe<Price>;
  priceData?: Maybe<ProductPriceData>;
  priceRange?: Maybe<PriceRange>;
  produceCountry?: Maybe<ProductCountryOfOrigin>;
  productReferences?: Maybe<Array<ProductReference>>;
  productServices?: Maybe<Array<ProductService>>;
  productUrl?: Maybe<Scalars['String']['output']>;
  purchasable?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use the field `hasRatings` */
  ratingData?: Maybe<RatingData>;
  relatedProductAvailability?: Maybe<RelatedProductAvailability>;
  reservationData?: Maybe<ProductReservationData>;
  restType?: Maybe<Scalars['String']['output']>;
  reviews?: Maybe<Array<Review>>;
  searchResult?: Maybe<CampaignSearchResult>;
  selfServiceData?: Maybe<SelfService>;
  seoData?: Maybe<Seo>;
  seriesName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the field `productServices`. */
  services?: Maybe<Array<ProductServiceData>>;
  shopInformation?: Maybe<ShopInformation>;
  source?: Maybe<Scalars['String']['output']>;
  statisticKPI?: Maybe<Scalars['String']['output']>;
  stock?: Maybe<Stock>;
  style?: Maybe<ProductStyleData>;
  summary?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  target?: Maybe<Scalars['String']['output']>;
  teaserText?: Maybe<Scalars['String']['output']>;
  timendoAreaCmsPage?: Maybe<Scalars['String']['output']>;
  timendoMasterId?: Maybe<Scalars['String']['output']>;
  timendoSettings?: Maybe<Array<TimendoData>>;
  title?: Maybe<Scalars['String']['output']>;
  transitionalDescription?: Maybe<Scalars['Boolean']['output']>;
  tv5special?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unlockCode?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  urlFromSeoData?: Maybe<Scalars['String']['output']>;
  variantContainerData?: Maybe<VariantContainer>;
  variantMatrix?: Maybe<Array<VariantMatrixElement>>;
  variantOptions?: Maybe<Array<VariantOption>>;
  variantType?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
  volumePrices?: Maybe<Array<Price>>;
  volumePricesFlag?: Maybe<Scalars['Boolean']['output']>;
}

export interface ProductAdditionalDescription {
  __typename?: 'ProductAdditionalDescription';
  attributes?: Maybe<Array<ProductAttributeData>>;
  name?: Maybe<Scalars['String']['output']>;
}

export interface ProductAttribute {
  __typename?: 'ProductAttribute';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
  restType: Scalars['String']['output'];
  value: Scalars['String']['output'];
  valueId: Scalars['String']['output'];
}

export interface ProductAttributeData {
  __typename?: 'ProductAttributeData';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface ProductAttributeGroupData {
  __typename?: 'ProductAttributeGroupData';
  attributes?: Maybe<Array<ProductAttributeData>>;
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface ProductAttributes {
  __typename?: 'ProductAttributes';
  colorMapping?: Maybe<ColorMapping>;
  dimensions?: Maybe<Dimensions>;
  material?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface ProductAugmentedReality {
  __typename?: 'ProductAugmentedReality';
  androidUrl?: Maybe<Scalars['String']['output']>;
  iosUrl?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface ProductAvailabilityInformation {
  __typename?: 'ProductAvailabilityInformation';
  assembly?: Maybe<Scalars['Boolean']['output']>;
  nearestAvailableSubsidiaries?: Maybe<NearestAvailableSubsidiaries>;
  restType?: Maybe<Scalars['String']['output']>;
  shippingInformation?: Maybe<Array<ShippingInformation>>;
  status?: Maybe<Scalars['String']['output']>;
  subsidiaries?: Maybe<Array<SubsidiaryInformation>>;
}

export interface ProductBrandRestriction extends BaseRestriction {
  __typename?: 'ProductBrandRestriction';
  brands?: Maybe<Array<Brand>>;
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface ProductCategoryRestriction extends BaseRestriction {
  __typename?: 'ProductCategoryRestriction';
  categories?: Maybe<Array<Category>>;
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface ProductCharacteristicData {
  __typename?: 'ProductCharacteristicData';
  colorMapping?: Maybe<ColorMapping>;
  detailAttributes?: Maybe<Array<ProductAttributeGroupData>>;
  dimensions?: Maybe<Dimensions>;
  material?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface ProductCodes {
  __typename?: 'ProductCodes';
  codes?: Maybe<Scalars['JSON']['output']>;
  errorCode?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

/** MBX comparsion only */
export interface ProductComparison {
  __typename?: 'ProductComparison';
  attributes?: Maybe<Array<ProductComparisonAttributes>>;
  products?: Maybe<Array<ComparisonProduct>>;
  title?: Maybe<Scalars['String']['output']>;
}

export interface ProductComparisonAttributes {
  __typename?: 'ProductComparisonAttributes';
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
}

/** New comparison service */
export interface ProductComparisonService {
  __typename?: 'ProductComparisonService';
  attributes?: Maybe<Array<ProductComparisonAttributes>>;
  products?: Maybe<Array<ComparisonProductService>>;
}

export interface ProductConfigurationInitializationRequestInput {
  captcha?: InputMaybe<Scalars['String']['input']>;
  deviceType?: InputMaybe<Scalars['String']['input']>;
  productCode?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductConfigurationInput {
  captcha?: InputMaybe<Scalars['String']['input']>;
  configuredId?: InputMaybe<Scalars['String']['input']>;
  productCode?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductConfigurationModificationRequestInput {
  captcha?: InputMaybe<Scalars['String']['input']>;
  configuredId?: InputMaybe<Scalars['String']['input']>;
  deviceType?: InputMaybe<Scalars['String']['input']>;
  productCode?: InputMaybe<Scalars['String']['input']>;
  redirectTarget?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

export interface ProductConfigurationResponse {
  __typename?: 'ProductConfigurationResponse';
  openType?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface ProductConfigurator {
  __typename?: 'ProductConfigurator';
  configurationId?: Maybe<Scalars['String']['output']>;
  configurationSystemId?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface ProductConfiguredRestriction extends BaseRestriction {
  __typename?: 'ProductConfiguredRestriction';
  configuredOnly?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface ProductCountryOfOrigin {
  __typename?: 'ProductCountryOfOrigin';
  isocode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface ProductDeliveryData {
  __typename?: 'ProductDeliveryData';
  assembly?: Maybe<Scalars['Boolean']['output']>;
  bulkyProduct?: Maybe<Scalars['Boolean']['output']>;
  customDeliveryType?: Maybe<Scalars['String']['output']>;
  defaultDeliveryTimeRetrievalEnabled?: Maybe<Scalars['Boolean']['output']>;
  delivery?: Maybe<Scalars['Boolean']['output']>;
  deliveryCostsCharged?: Maybe<Scalars['Boolean']['output']>;
  deliveryIndicator?: Maybe<Scalars['String']['output']>;
  deliveryInformationForCustomers?: Maybe<Array<ProductAttribute>>;
  fastDeliveryShown?: Maybe<Scalars['Boolean']['output']>;
  mailable?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  selfServiceOnly?: Maybe<Scalars['Boolean']['output']>;
}

export interface ProductDeliveryMethods {
  __typename?: 'ProductDeliveryMethods';
  code?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Price>;
}

export interface ProductEyecatcherRestriction extends BaseRestriction {
  __typename?: 'ProductEyecatcherRestriction';
  eyecatchers?: Maybe<Array<Eyecatcher>>;
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface ProductGroupRestriction extends BaseRestriction {
  __typename?: 'ProductGroupRestriction';
  categoryMappingCodesList?: Maybe<Array<Scalars['String']['output']>>;
  included?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface ProductList {
  __typename?: 'ProductList';
  products?: Maybe<Array<Product>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface ProductMediaData {
  __typename?: 'ProductMediaData';
  banner?: Maybe<Image>;
  datasheets?: Maybe<Array<Image>>;
  presentation?: Maybe<Array<Image>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface ProductOrderData {
  __typename?: 'ProductOrderData';
  buyable?: Maybe<Scalars['Boolean']['output']>;
  maxOrderableAmount?: Maybe<Scalars['Int']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  sellable?: Maybe<Scalars['Boolean']['output']>;
}

export interface ProductPriceData {
  __typename?: 'ProductPriceData';
  assemblyCost?: Maybe<Price>;
  currentNetPrice?: Maybe<Price>;
  currentPrice?: Maybe<Price>;
  deliveryCost?: Maybe<Price>;
  deliveryMethods?: Maybe<Array<Maybe<ProductDeliveryMethods>>>;
  hideUvp?: Maybe<Scalars['Boolean']['output']>;
  last30DaysBestPrice?: Maybe<Price>;
  offerAttributes?: Maybe<Offer>;
  oldNetPrice?: Maybe<Price>;
  oldPrice?: Maybe<Price>;
  postCost?: Maybe<Price>;
  pricePerUnit?: Maybe<Price>;
  priceShown?: Maybe<Scalars['Boolean']['output']>;
  productUnitDiffersToPriceUnit?: Maybe<Scalars['Boolean']['output']>;
  promotionalMessageShown?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  savedPercent?: Maybe<Scalars['Int']['output']>;
  savedValueTextShown?: Maybe<Scalars['Boolean']['output']>;
  sellingAmount?: Maybe<Scalars['Float']['output']>;
  sellingUnit?: Maybe<Scalars['String']['output']>;
  shippingCost?: Maybe<Price>;
}

export interface ProductQuantityRestriction extends BaseRestriction {
  __typename?: 'ProductQuantityRestriction';
  quantity?: Maybe<Scalars['Int']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
}

export interface ProductReference {
  __typename?: 'ProductReference';
  description?: Maybe<Scalars['String']['output']>;
  preselected?: Maybe<Scalars['Boolean']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  referenceType?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  target?: Maybe<Product>;
}

export interface ProductReservationData {
  __typename?: 'ProductReservationData';
  maxReservableAmount?: Maybe<Scalars['Int']['output']>;
  reservable?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface ProductRestriction extends BaseRestriction {
  __typename?: 'ProductRestriction';
  products?: Maybe<Array<Product>>;
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface ProductService {
  __typename?: 'ProductService';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Image>;
  price?: Maybe<Scalars['Float']['output']>;
  productSummary?: Maybe<Scalars['String']['output']>;
  teaserText?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface ProductServiceData {
  __typename?: 'ProductServiceData';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Image>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface ProductStyleData {
  __typename?: 'ProductStyleData';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface Promotion {
  __typename?: 'Promotion';
  code?: Maybe<Scalars['String']['output']>;
  couldFireMessages?: Maybe<Array<Scalars['String']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  firedMessages?: Maybe<Array<Scalars['String']['output']>>;
  priority?: Maybe<Scalars['Int']['output']>;
  productBanner?: Maybe<Image>;
  promotionGroup?: Maybe<Scalars['String']['output']>;
  promotionType?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  restrictions?: Maybe<Array<PromotionRestriction>>;
  startDate?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
}

export interface PromotionOrderEntryConsumed {
  __typename?: 'PromotionOrderEntryConsumed';
  adjustedUnitPrice?: Maybe<Scalars['Float']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  orderEntryNumber?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['String']['output']>;
  reductionValue?: Maybe<Scalars['Float']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface PromotionRestriction {
  __typename?: 'PromotionRestriction';
  description?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  restrictionType?: Maybe<Scalars['String']['output']>;
}

export interface PromotionResult {
  __typename?: 'PromotionResult';
  addedPotentialPromotionInfo?: Maybe<AddedPotentialPromotionInfo>;
  consumedEntries?: Maybe<Array<PromotionOrderEntryConsumed>>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productPriceReduction?: Maybe<Scalars['Boolean']['output']>;
  promotion?: Maybe<Promotion>;
  promotionDiscount?: Maybe<Price>;
  removable?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  usedCouponCodes?: Maybe<Array<Scalars['String']['output']>>;
  valueDisplayed?: Maybe<Scalars['Boolean']['output']>;
}

export interface Proposal {
  __typename?: 'Proposal';
  endDate?: Maybe<Scalars['String']['output']>;
  proposalId?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
}

export interface ProviderParameter {
  __typename?: 'ProviderParameter';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface Query {
  __typename?: 'Query';
  checkCart: Scalars['Boolean']['output'];
  countries?: Maybe<Array<Country>>;
  districts?: Maybe<Array<DistrictsList>>;
  getAIRecommendations?: Maybe<ProductList>;
  getActivePaymentModes?: Maybe<PaymentModes>;
  getAddresses?: Maybe<Array<AddressData>>;
  getAdyenPaymentModes?: Maybe<AdyenPaymentModes>;
  getAppointmentAreas?: Maybe<AreaList>;
  getAppointmentInformation?: Maybe<AppointmentInformation>;
  getAppointmentProposals?: Maybe<AppointmentProposal>;
  getAppointmentStores?: Maybe<PointOfServices>;
  getAppointmentTimeslots?: Maybe<Timeslots>;
  getAvailability?: Maybe<DeliveryInformation>;
  getBrochures?: Maybe<Brochures>;
  getCampaigns?: Maybe<FredhopperCampaigns>;
  getCarts?: Maybe<CartList>;
  getCategory?: Maybe<Category>;
  getConfig?: Maybe<ConfigurationList>;
  getContentPage?: Maybe<CmsContentPage>;
  getCountryOverlay?: Maybe<CountryOverlay>;
  getCurrentUser?: Maybe<User>;
  getCustomer?: Maybe<CustomerInfo>;
  getCustomerCard?: Maybe<CustomerCardDetails>;
  getCustomerData?: Maybe<CustomerDetails>;
  /** @deprecated Optile payment provider is end of life */
  getExpressPaymentModes?: Maybe<PaymentModes>;
  getFHCampaigns?: Maybe<FredhopperCampaigns>;
  getFaqSuggest?: Maybe<FaqSuggest>;
  getFavouriteList?: Maybe<FavouritesList>;
  getFavouriteLists?: Maybe<Favourites>;
  /** @deprecated only used in v1 api */
  getFavourites?: Maybe<Favourites>;
  getFeatures?: Maybe<ApplicationFeatures>;
  getFredhopperSuggest?: Maybe<FhSuggestItems>;
  getKitchenShowrooms?: Maybe<KitchenShowrooms>;
  getLeafCategory?: Maybe<LeafCategory>;
  getMandantConfig?: Maybe<MandantConfig>;
  getMessages?: Maybe<Scalars['JSONObject']['output']>;
  getMessagesWithCodes?: Maybe<Array<Message>>;
  getMetaNavigation?: Maybe<MetaNavigation>;
  getMiraklIncidentReasons?: Maybe<Array<MiraklIncidentReason>>;
  /** @deprecated Use `navigation` instead. */
  getNavigation?: Maybe<Navigation>;
  /** @deprecated Use `navigationLevels` instead */
  getNavigationListOptions?: Maybe<Navigation>;
  getNavigationNodes?: Maybe<Navigation>;
  getOrder?: Maybe<Order>;
  getOrderCheckSplit: Scalars['Boolean']['output'];
  getOrderPDF?: Maybe<Scalars['JSON']['output']>;
  /** @deprecated Part of old order status */
  getOrderStatus?: Maybe<OrderStatus>;
  getPaymentModes?: Maybe<Cart>;
  getPeeriusRecommendations?: Maybe<TrackingResponse>;
  getPocoContentToken?: Maybe<PocoContentTokenResponse>;
  getPointOfServices?: Maybe<PointOfServices>;
  getPreferences?: Maybe<Preferences>;
  getProduct?: Maybe<Product>;
  getProductAvailability?: Maybe<ProductAvailabilityInformation>;
  getProductComparison?: Maybe<ProductComparison>;
  getProductComparisonService?: Maybe<ProductComparisonService>;
  getProductComparisonServiceValidation?: Maybe<ProductComparisonService>;
  getProductConfiguratorPdf?: Maybe<Scalars['JSON']['output']>;
  getProductContent?: Maybe<CmsProductContent>;
  getProductReviewOptions?: Maybe<ReviewOptions>;
  getProductReviews?: Maybe<ReviewList>;
  getProductSpecificContent?: Maybe<CmsProductSpecificData>;
  getProducts?: Maybe<ProductList>;
  getProductsToReview?: Maybe<ProductList>;
  getRatingProductDimensionTypes?: Maybe<Array<DimensionType>>;
  getRestaurants?: Maybe<Restaurants>;
  getRetailMedia?: Maybe<ProductList>;
  getSearchBasics?: Maybe<SearchBasics>;
  getSearchOnlyResults?: Maybe<SearchOnlyResults>;
  /** @deprecated use getSearchBasics and getSearchOnlyResults */
  getSearchResults?: Maybe<SearchResult>;
  /** @deprecated use getSearchResults */
  getSearchResultsData?: Maybe<SearchResultData>;
  getSeller?: Maybe<Seller>;
  getSuggestSearch?: Maybe<SuggestSearchItems>;
  getTemplatePage?: Maybe<CmsTemplateData>;
  getUnitBasedCalculation?: Maybe<UnitBasedCalculation>;
  getVisualRecommendations?: Maybe<ProductList>;
  getZipCode?: Maybe<ZipCodeResponse>;
  navigation?: Maybe<Navigation>;
  onlineSchedulingGetConfiguration?: Maybe<OnlineSchedulingConfigurations>;
  onlineSchedulingGetOrder?: Maybe<OnlineSchedulingOrder>;
  onlineSchedulingGetStores?: Maybe<OnlineSchedulingStores>;
  onlineSchedulingGetTimeslots?: Maybe<OnlineSchedulingTimeslots>;
  regions?: Maybe<Array<RegionsList>>;
  registerUserConfirmationLink?: Maybe<RegisterUserConfirmationLink>;
  reservationExportStatus?: Maybe<ExportStatus>;
  searchBar?: Maybe<SearchBar>;
  searchPickupStations?: Maybe<PointOfServices>;
  suggestZipCode?: Maybe<PostalCodes>;
}

export interface QueryCheckCartArgs {
  cartId: Scalars['String']['input'];
  validationType?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryDistrictsArgs {
  regionIsoCode: Scalars['String']['input'];
}

export interface QueryGetAiRecommendationsArgs {
  productCode: Scalars['String']['input'];
  recommendationsParams?: InputMaybe<AiRecommendationsParams>;
}

export interface QueryGetAddressesArgs {
  bubbleErrors?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface QueryGetAppointmentInformationArgs {
  appointmentId?: InputMaybe<Scalars['String']['input']>;
  areaId?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetAppointmentProposalsArgs {
  lastname: Scalars['String']['input'];
  orderCode: Scalars['String']['input'];
}

export interface QueryGetAppointmentStoresArgs {
  areaId: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetAppointmentTimeslotsArgs {
  areaId: Scalars['String']['input'];
  end: Scalars['String']['input'];
  start: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
}

export interface QueryGetAvailabilityArgs {
  productCode: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
}

export interface QueryGetCampaignsArgs {
  filters?: InputMaybe<CampaignFilters>;
  productCode: Scalars['String']['input'];
}

export interface QueryGetCartsArgs {
  type: Scalars['String']['input'];
}

export interface QueryGetCategoryArgs {
  code: Scalars['String']['input'];
}

export interface QueryGetConfigArgs {
  context?: InputMaybe<Scalars['String']['input']>;
  keys?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface QueryGetContentPageArgs {
  cmsTicketId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  filters?: InputMaybe<Scalars['String']['input']>;
  keepCache?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetCountryOverlayArgs {
  ip?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetCustomerCardArgs {
  bubbleErrors?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface QueryGetFhCampaignsArgs {
  cmsPageCode: Scalars['String']['input'];
  productCode?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetFaqSuggestArgs {
  maxWordsAnswer?: InputMaybe<Scalars['Int']['input']>;
  maxWordsQuestion?: InputMaybe<Scalars['Int']['input']>;
  term: Scalars['String']['input'];
}

export interface QueryGetFavouriteListArgs {
  favouriteListId: Scalars['String']['input'];
}

export interface QueryGetFeaturesArgs {
  context?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetFredhopperSuggestArgs {
  term: Scalars['String']['input'];
}

export interface QueryGetKitchenShowroomsArgs {
  postalCode?: InputMaybe<Scalars['String']['input']>;
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
  subsidiaryId?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetMessagesArgs {
  isInSmartEdit?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface QueryGetMessagesWithCodesArgs {
  codes: Array<Scalars['String']['input']>;
  isInSmartEdit?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface QueryGetNavigationArgs {
  cmsTicketId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
}

export interface QueryGetNavigationListOptionsArgs {
  deep?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  level?: InputMaybe<Scalars['Int']['input']>;
  parseOnlyFirstChildren?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface QueryGetNavigationNodesArgs {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  level?: InputMaybe<Scalars['Int']['input']>;
  parseOnlyFirstChildren?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface QueryGetOrderArgs {
  code: Scalars['String']['input'];
}

export interface QueryGetOrderCheckSplitArgs {
  orderCode: Scalars['String']['input'];
}

export interface QueryGetOrderPdfArgs {
  code: Scalars['String']['input'];
}

export interface QueryGetOrderStatusArgs {
  lastname: Scalars['String']['input'];
  orderCode: Scalars['String']['input'];
}

export interface QueryGetPaymentModesArgs {
  cartId: Scalars['String']['input'];
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  isExpress?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface QueryGetPeeriusRecommendationsArgs {
  data: Scalars['String']['input'];
}

export interface QueryGetPointOfServicesArgs {
  postalCode?: InputMaybe<Scalars['String']['input']>;
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
  subsidiaryId?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetProductArgs {
  productCode: Scalars['String']['input'];
}

export interface QueryGetProductAvailabilityArgs {
  maxSubsidiariesShown?: InputMaybe<Scalars['String']['input']>;
  productCode: Scalars['String']['input'];
  qty?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetProductComparisonArgs {
  productCode: Scalars['String']['input'];
}

export interface QueryGetProductComparisonServiceArgs {
  productCode: Scalars['String']['input'];
}

export interface QueryGetProductComparisonServiceValidationArgs {
  categoryId: Scalars['Int']['input'];
  productCode: Scalars['String']['input'];
}

export interface QueryGetProductConfiguratorPdfArgs {
  attachment: Scalars['String']['input'];
  code: Scalars['String']['input'];
}

export interface QueryGetProductContentArgs {
  cmsTicketId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
}

export interface QueryGetProductReviewOptionsArgs {
  productCode: Scalars['String']['input'];
}

export interface QueryGetProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  filters?: InputMaybe<ReviewsFilters>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  productCode: Scalars['String']['input'];
  ratingValue?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetProductSpecificContentArgs {
  cmsTicketId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  pageCode: Scalars['String']['input'];
}

export interface QueryGetProductsArgs {
  codes: Scalars['String']['input'];
}

export interface QueryGetProductsToReviewArgs {
  codes: Scalars['String']['input'];
  email: Scalars['String']['input'];
  mac: Scalars['String']['input'];
  orderCode: Scalars['String']['input'];
  year: Scalars['String']['input'];
}

export interface QueryGetRestaurantsArgs {
  postalCode?: InputMaybe<Scalars['String']['input']>;
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
  subsidiaryId?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetRetailMediaArgs {
  amount?: InputMaybe<Scalars['Int']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  isMock?: InputMaybe<Scalars['Boolean']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  pageId?: InputMaybe<Scalars['String']['input']>;
  pageNr?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetSearchBasicsArgs {
  cid?: InputMaybe<Scalars['String']['input']>;
  encodedFhLink?: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['String']['input'];
  pagination: Scalars['String']['input'];
  productCodes?: InputMaybe<Scalars['String']['input']>;
  rankingValue?: InputMaybe<Scalars['String']['input']>;
  redirectRequest?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sortBy: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetSearchOnlyResultsArgs {
  cid?: InputMaybe<Scalars['String']['input']>;
  encodedFhLink?: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['String']['input'];
  pagination: Scalars['String']['input'];
  productCodes?: InputMaybe<Scalars['String']['input']>;
  rankingValue?: InputMaybe<Scalars['String']['input']>;
  redirectRequest?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sortBy: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetSearchResultsArgs {
  cid?: InputMaybe<Scalars['String']['input']>;
  encodedFhLink?: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['String']['input'];
  pagination: Scalars['String']['input'];
  productCodes?: InputMaybe<Scalars['String']['input']>;
  rankingValue?: InputMaybe<Scalars['String']['input']>;
  redirectRequest?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sortBy: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetSearchResultsDataArgs {
  cid?: InputMaybe<Scalars['String']['input']>;
  encodedFhLink?: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['String']['input'];
  pagination: Scalars['String']['input'];
  redirectRequest?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sortBy: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetSellerArgs {
  sellerId?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryGetSuggestSearchArgs {
  term: Scalars['String']['input'];
}

export interface QueryGetTemplatePageArgs {
  cmsTicketId?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['String']['input'];
  templateId: Scalars['String']['input'];
}

export interface QueryGetUnitBasedCalculationArgs {
  amount: Scalars['Float']['input'];
  code: Scalars['String']['input'];
}

export interface QueryGetVisualRecommendationsArgs {
  productCode: Scalars['String']['input'];
  visualRecosParams?: InputMaybe<VisualRecosParams>;
}

export interface QueryGetZipCodeArgs {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryNavigationArgs {
  codes?: InputMaybe<Array<Scalars['String']['input']>>;
  levelAmount?: InputMaybe<Scalars['Int']['input']>;
  levelOffset?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryOnlineSchedulingGetOrderArgs {
  lastName: Scalars['String']['input'];
  orderNumber: Scalars['String']['input'];
}

export interface QueryOnlineSchedulingGetStoresArgs {
  lastName: Scalars['String']['input'];
  orderNumber: Scalars['String']['input'];
  servicetype: Scalars['Int']['input'];
}

export interface QueryOnlineSchedulingGetTimeslotsArgs {
  client: Scalars['String']['input'];
  from: Scalars['String']['input'];
  month: Scalars['Int']['input'];
  servicetypeId: Scalars['Int']['input'];
  storeId: Scalars['String']['input'];
  to: Scalars['String']['input'];
}

export interface QueryRegisterUserConfirmationLinkArgs {
  email: Scalars['String']['input'];
}

export interface QueryReservationExportStatusArgs {
  code: Scalars['String']['input'];
}

export interface QuerySearchPickupStationsArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  postalCode: Scalars['String']['input'];
}

export interface QuerySuggestZipCodeArgs {
  search: Scalars['String']['input'];
}

export interface RangeFacet extends CoreFacet {
  __typename?: 'RangeFacet';
  facetId: Scalars['String']['output'];
  filterId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  maxValue: Scalars['Int']['output'];
  minValue: Scalars['Int']['output'];
  rid: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
}

/** deprecated, please dont use this type anymore */
export interface RatingData {
  __typename?: 'RatingData';
  ratingEnabled?: Maybe<Scalars['Boolean']['output']>;
  ratingStatistic?: Maybe<RatingStatistic>;
}

export interface RatingStatistic {
  __typename?: 'RatingStatistic';
  ratingCount?: Maybe<Scalars['Int']['output']>;
  ratingStatisticPerRatingValue?: Maybe<Array<RatingStatisticPerRating>>;
  ratingValue?: Maybe<Scalars['Float']['output']>;
  ratingValueType?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface RatingStatisticPerRating {
  __typename?: 'RatingStatisticPerRating';
  ratingValue?: Maybe<Scalars['Int']['output']>;
  ratingValueCount?: Maybe<Scalars['Int']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface Redirect {
  __typename?: 'Redirect';
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  productRedirection?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface Region {
  __typename?: 'Region';
  countryIso?: Maybe<Scalars['String']['output']>;
  isocode?: Maybe<Scalars['String']['output']>;
  isocodeShort?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface RegionData {
  isocode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

export interface RegionInput {
  countryIso?: InputMaybe<Scalars['String']['input']>;
  isocode?: InputMaybe<Scalars['String']['input']>;
  isocodeShort?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

export interface RegionsList {
  __typename?: 'RegionsList';
  isocode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface RegisterUserConfirmationLink {
  __typename?: 'RegisterUserConfirmationLink';
  confirmationLink?: Maybe<Scalars['String']['output']>;
}

export interface RegisterUserConfirmationResponse {
  __typename?: 'RegisterUserConfirmationResponse';
  email?: Maybe<Scalars['String']['output']>;
}

export interface RegisterUserInput {
  /** @deprecated Using another cloud solution for bot management. */
  captcha?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Use the field `userid`. */
  email?: InputMaybe<Scalars['String']['input']>;
  orderCode?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  userid: Scalars['String']['input'];
}

export interface RegisterUserResponse {
  __typename?: 'RegisterUserResponse';
  email?: Maybe<Scalars['String']['output']>;
}

export interface RelatedProductAvailability {
  __typename?: 'RelatedProductAvailability';
  hasAccessories?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface Reply {
  __typename?: 'Reply';
  alias?: Maybe<Scalars['String']['output']>;
  createdTS?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface ReservationTimeslotData {
  bookingId?: InputMaybe<Scalars['Int']['input']>;
  ccard?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  isUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  reasonId?: InputMaybe<Scalars['Int']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface ResetPasswordInput {
  password?: InputMaybe<Scalars['String']['input']>;
  repeatPassword?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  tokenId?: InputMaybe<Scalars['String']['input']>;
}

export interface ResetPasswordTokenInput {
  restType?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  tokenId?: InputMaybe<Scalars['String']['input']>;
}

export interface ResponseEntity {
  __typename?: 'ResponseEntity';
  body?: Maybe<ResponseEntityBody>;
  statusCode?: Maybe<Scalars['String']['output']>;
  statusCodeValue?: Maybe<Scalars['Int']['output']>;
}

export interface ResponseEntityBody {
  __typename?: 'ResponseEntity_body';
  empty?: Maybe<Scalars['String']['output']>;
}

export interface Restaurant {
  __typename?: 'Restaurant';
  address?: Maybe<Address>;
  code?: Maybe<Scalars['String']['output']>;
  distanceFromPostalCode?: Maybe<Scalars['Float']['output']>;
  geoPoint?: Maybe<GeoPoint>;
  name?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use field `RestaurantSchedule` instead */
  openingHours?: Maybe<Scalars['String']['output']>;
  openingHoursSchedule?: Maybe<RestaurantSchedule>;
  restType?: Maybe<Scalars['String']['output']>;
  seoData?: Maybe<RestaurantSeoData>;
  type?: Maybe<Scalars['String']['output']>;
  urlCode?: Maybe<Scalars['String']['output']>;
}

export interface RestaurantSchedule {
  __typename?: 'RestaurantSchedule';
  regularOpeningDays?: Maybe<Array<OpeningDay>>;
}

export interface RestaurantSeoData {
  __typename?: 'RestaurantSeoData';
  url?: Maybe<Scalars['String']['output']>;
}

export interface Restaurants {
  __typename?: 'Restaurants';
  restType?: Maybe<Scalars['String']['output']>;
  restaurants?: Maybe<Array<Restaurant>>;
}

export interface Restriction extends BaseRestriction {
  __typename?: 'Restriction';
  restType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface Review {
  __typename?: 'Review';
  authorAlias?: Maybe<Scalars['String']['output']>;
  authorType?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  countryCrossedData?: Maybe<CrossCountry>;
  created?: Maybe<Scalars['String']['output']>;
  dimensionalRatingValues?: Maybe<Array<DimensionRating>>;
  id?: Maybe<Scalars['String']['output']>;
  ratingValue?: Maybe<Scalars['Int']['output']>;
  replies?: Maybe<Array<Reply>>;
  restType?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  variantData?: Maybe<Array<VariantData>>;
  verifiedAuthor?: Maybe<Scalars['Boolean']['output']>;
}

export enum ReviewApprovalMode {
  AutomaticApproval = 'AUTOMATIC_APPROVAL',
  ManualApproval = 'MANUAL_APPROVAL',
}

export interface ReviewCreateInput {
  authorAlias?: InputMaybe<Scalars['String']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  captcha?: InputMaybe<Scalars['String']['input']>;
  dimensionalRatingValues?: InputMaybe<Array<DimensionRatingInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  publishConsent?: InputMaybe<Scalars['Boolean']['input']>;
  ratingValue?: InputMaybe<Scalars['Int']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  verifiedAuthor?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ReviewList {
  __typename?: 'ReviewList';
  approvalMode: ReviewApprovalMode;
  dimensionTypes?: Maybe<Array<DimensionType>>;
  dimensionalRatingStatisticData?: Maybe<Array<RatingStatistic>>;
  pagination?: Maybe<ReviewPagination>;
  ratingStatistic?: Maybe<RatingStatistic>;
  restType?: Maybe<Scalars['String']['output']>;
  reviews?: Maybe<Array<Review>>;
}

export interface ReviewOptions {
  __typename?: 'ReviewOptions';
  canCreateReview?: Maybe<Scalars['Boolean']['output']>;
  canGetReviews?: Maybe<Scalars['Boolean']['output']>;
}

export interface ReviewPagination {
  __typename?: 'ReviewPagination';
  currentPage?: Maybe<Scalars['Int']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
  totalResults?: Maybe<Scalars['Int']['output']>;
}

export interface ReviewsFilters {
  ratingValue?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface SalesTaxNumber {
  __typename?: 'SalesTaxNumber';
  number?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface SalesTaxNumberInput {
  number?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

/** @deprecated(reason: "Optile payment provider is end of life") */
export interface SavedInvoiceInput {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  paymentMode?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
}

export interface SearchBar {
  __typename?: 'SearchBar';
  suggest?: Maybe<Scalars['Boolean']['output']>;
  term?: Maybe<Scalars['String']['output']>;
}

export interface SearchBasics {
  __typename?: 'SearchBasics';
  breadcrumbs?: Maybe<Array<Breadcrumb>>;
  fhPreview?: Maybe<FhPreview>;
  filters?: Maybe<Array<CoreFacet>>;
  pagination?: Maybe<Pagination>;
  redirect?: Maybe<Redirect>;
  rid: Scalars['String']['output'];
  searchPass?: Maybe<Scalars['String']['output']>;
  searchResults?: Maybe<Array<BaseSearchResult>>;
  searchTermAlternatives?: Maybe<Array<SearchTermAlternative>>;
  seoData?: Maybe<Seo>;
}

export interface SearchOnlyResults {
  __typename?: 'SearchOnlyResults';
  searchResults?: Maybe<Array<BaseSearchResult>>;
}

export interface SearchResult {
  __typename?: 'SearchResult';
  breadcrumbs?: Maybe<Array<Breadcrumb>>;
  fhPreview?: Maybe<FhPreview>;
  filters?: Maybe<Array<CoreFacet>>;
  pagination?: Maybe<Pagination>;
  redirect?: Maybe<Redirect>;
  rid: Scalars['String']['output'];
  searchPass?: Maybe<Scalars['String']['output']>;
  searchResults?: Maybe<Array<BaseSearchResult>>;
  searchTermAlternatives?: Maybe<Array<SearchTermAlternative>>;
  seoData?: Maybe<Seo>;
}

export interface SearchResultData {
  __typename?: 'SearchResultData';
  breadcrumbs?: Maybe<Array<Breadcrumb>>;
  encodedFhLink?: Maybe<Scalars['String']['output']>;
  fhPreview?: Maybe<FhPreview>;
  filters?: Maybe<Array<FacetsResult>>;
  pagination?: Maybe<Pagination>;
  redirect?: Maybe<Redirect>;
  restType?: Maybe<Scalars['String']['output']>;
  searchPass?: Maybe<Scalars['String']['output']>;
  searchResults?: Maybe<Array<BaseSearchResult>>;
  searchTerm?: Maybe<Scalars['String']['output']>;
  searchTermAlternatives?: Maybe<Array<SearchTermAlternative>>;
  seoData?: Maybe<Seo>;
  sorts?: Maybe<Array<Sort>>;
}

export interface SearchTermAlternative {
  __typename?: 'SearchTermAlternative';
  alternative?: Maybe<Scalars['String']['output']>;
  executed?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface SelfService {
  __typename?: 'SelfService';
  restType?: Maybe<Scalars['String']['output']>;
  selfService?: Maybe<Scalars['Boolean']['output']>;
}

export interface Seller {
  __typename?: 'Seller';
  conditions?: Maybe<Scalars['String']['output']>;
  contactInformation: Address;
  description?: Maybe<Scalars['String']['output']>;
  gdpr?: Maybe<Scalars['String']['output']>;
  imprint?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  returnPolicy?: Maybe<Scalars['String']['output']>;
  seoData?: Maybe<SellerSeoData>;
}

export interface SellerSeoData {
  __typename?: 'SellerSeoData';
  url: Scalars['String']['output'];
}

export interface Seo {
  __typename?: 'Seo';
  baseStoreRelatedItems?: Maybe<Array<BaseStoreRelatedItem>>;
  canonicalUrl?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use the field `url`. */
  mobileUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  noFollow?: Maybe<Scalars['Boolean']['output']>;
  noIndex?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  seoPath?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface Service {
  __typename?: 'Service';
  address?: Maybe<Address>;
  code?: Maybe<Scalars['String']['output']>;
  distanceFromPostalCode?: Maybe<Scalars['Float']['output']>;
  geoPoint?: Maybe<GeoPoint>;
  name?: Maybe<Scalars['String']['output']>;
  openingHoursSchedule?: Maybe<ServiceSchedule>;
  restType?: Maybe<Scalars['String']['output']>;
  seoData?: Maybe<ServiceSeoData>;
  type?: Maybe<Scalars['String']['output']>;
  urlCode?: Maybe<Scalars['String']['output']>;
}

export interface ServiceSchedule {
  __typename?: 'ServiceSchedule';
  regularOpeningDays?: Maybe<Array<OpeningDay>>;
}

export interface ServiceSeoData {
  __typename?: 'ServiceSeoData';
  url?: Maybe<Scalars['String']['output']>;
}

export interface Services {
  code?: InputMaybe<Scalars['String']['input']>;
  consumed?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ServicesData {
  services?: InputMaybe<Array<Services>>;
}

export interface ShippingInformation {
  __typename?: 'ShippingInformation';
  deliveryTime?: Maybe<Scalars['Int']['output']>;
  deliveryTimeText?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  shippingType?: Maybe<Scalars['String']['output']>;
}

export interface ShopInformation {
  __typename?: 'ShopInformation';
  isExternal?: Maybe<Scalars['Boolean']['output']>;
  shopId?: Maybe<Scalars['String']['output']>;
  shopName?: Maybe<Scalars['String']['output']>;
}

export interface ShopService {
  __typename?: 'ShopService';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface SingleSelectFacet extends CoreFacet {
  __typename?: 'SingleSelectFacet';
  facetId: Scalars['String']['output'];
  filterId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rid: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  values: Array<SingleSelectValues>;
}

export interface SingleSelectValues extends BaseValues {
  __typename?: 'SingleSelectValues';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  valueId: Scalars['String']['output'];
}

export interface Sort {
  __typename?: 'Sort';
  code?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  selected?: Maybe<Scalars['Boolean']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface SortFacet extends CoreFacet {
  __typename?: 'SortFacet';
  codes: Array<Scalars['String']['output']>;
  filterId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rid: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
}

export interface SpecialOfferType {
  __typename?: 'SpecialOfferType';
  numberOfAsterisks?: Maybe<Scalars['Int']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface SpecialOpeningDay {
  __typename?: 'SpecialOpeningDay';
  closed?: Maybe<Scalars['Boolean']['output']>;
  closingTime?: Maybe<Time>;
  comment?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  formattedDate?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  openingTime?: Maybe<Time>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface SpecialOpeningDayInput {
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  closingTime?: InputMaybe<TimeInput>;
  comment?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  formattedDate?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  openingTime?: InputMaybe<TimeInput>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

export interface StatisticIdentifierRestriction extends BaseRestriction {
  __typename?: 'StatisticIdentifierRestriction';
  restType?: Maybe<Scalars['String']['output']>;
  statisticIdentifier?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface Stock {
  __typename?: 'Stock';
  restType?: Maybe<Scalars['String']['output']>;
  stockLevel?: Maybe<Scalars['String']['output']>;
  stockLevelStatus?: Maybe<Scalars['String']['output']>;
}

export interface StockDataContainer {
  __typename?: 'StockDataContainer';
  availableInWarehouse?: Maybe<Scalars['Boolean']['output']>;
  availableReservation?: Maybe<Scalars['Int']['output']>;
  deliveryTime?: Maybe<Scalars['Int']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  formattedFloor?: Maybe<Scalars['String']['output']>;
  formattedSubsidiaryStockStatus?: Maybe<Scalars['String']['output']>;
  formattedSubsidiaryStockStatusIndex?: Maybe<Scalars['Float']['output']>;
  inSubsidiaryReorderTime?: Maybe<Scalars['String']['output']>;
  inSubsidiaryReorderTimeIndex?: Maybe<Scalars['Float']['output']>;
  inSubsidiaryStock?: Maybe<Scalars['Boolean']['output']>;
  inSubsidiaryStockStatus?: Maybe<Scalars['Int']['output']>;
  orderableInSubsidiary?: Maybe<Scalars['Boolean']['output']>;
  pointOfService?: Maybe<PointOfService>;
  reorderTimeShown?: Maybe<Scalars['Boolean']['output']>;
  reserveableInSubsidiary?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  selfServiceDeliveryTime?: Maybe<Scalars['String']['output']>;
  showInSubsidiary?: Maybe<Scalars['Boolean']['output']>;
}

export interface SubsidiaryInformation {
  __typename?: 'SubsidiaryInformation';
  displayInformation?: Maybe<DisplayInformation>;
  distance?: Maybe<Scalars['Float']['output']>;
  pointOfService?: Maybe<PointOfService>;
  reservable?: Maybe<Scalars['Boolean']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  selfService?: Maybe<Scalars['Boolean']['output']>;
  shippingInformation?: Maybe<Array<ShippingInformation>>;
}

export interface SuggestSearchData {
  __typename?: 'SuggestSearchData';
  count?: Maybe<Scalars['Int']['output']>;
  fhId?: Maybe<Scalars['String']['output']>;
  formattedId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface SuggestSearchItems {
  __typename?: 'SuggestSearchItems';
  categories?: Maybe<Array<SuggestSearchData>>;
  keywords?: Maybe<Array<SuggestSearchData>>;
  products?: Maybe<Array<SuggestSearchData>>;
}

export interface Task {
  __typename?: 'Task';
  restType?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
}

export interface Taxnumber {
  __typename?: 'Taxnumber';
  created?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
}

export interface TaxnumberInput {
  created?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
}

export interface TermsAndConditions {
  __typename?: 'TermsAndConditions';
  created: Scalars['String']['output'];
}

export interface Time {
  __typename?: 'Time';
  formattedHour?: Maybe<Scalars['String']['output']>;
  hour?: Maybe<Scalars['String']['output']>;
  minute?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface TimeInput {
  formattedHour?: InputMaybe<Scalars['String']['input']>;
  hour?: InputMaybe<Scalars['String']['input']>;
  minute?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

export interface TimendoCustomer {
  __typename?: 'TimendoCustomer';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface TimendoCustomerInput {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
}

export interface TimendoData {
  __typename?: 'TimendoData';
  categoryCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  store?: Maybe<PointOfService>;
}

export interface Timeslot {
  __typename?: 'Timeslot';
  calendarIds?: Maybe<Array<Scalars['Int']['output']>>;
  capacity?: Maybe<Scalars['Int']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
}

export interface Timeslots {
  __typename?: 'Timeslots';
  availableTimeslots?: Maybe<Array<Timeslot>>;
  calendarId?: Maybe<Scalars['Int']['output']>;
  calendarIds?: Maybe<Array<Scalars['Int']['output']>>;
  firstSlot?: Maybe<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['Int']['output']>;
  interventionIds?: Maybe<Array<Scalars['Int']['output']>>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface Title {
  __typename?: 'Title';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface TrackingResponse {
  __typename?: 'TrackingResponse';
  contentslots?: Maybe<Scalars['JSON']['output']>;
  debug?: Maybe<Scalars['JSON']['output']>;
}

export interface UnitBasedCalculation {
  __typename?: 'UnitBasedCalculation';
  amount?: Maybe<Scalars['Int']['output']>;
  deliveryAmount?: Maybe<Scalars['Float']['output']>;
  oldPriceTotal?: Maybe<Price>;
  packageAmount?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Price>;
  productUnitBasedAmount?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  selectedDeliveryAmount?: Maybe<Scalars['Float']['output']>;
}

/** @deprecated(reason: "Optile payment provider is end of life") */
export interface UpdateSavedInvoiceInput {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  paymentMode?: InputMaybe<Scalars['String']['input']>;
  paymentModeId?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
}

export interface User {
  __typename?: 'User';
  anonymous?: Maybe<Scalars['Boolean']['output']>;
  currency?: Maybe<Currency>;
  customerId?: Maybe<Scalars['String']['output']>;
  deactivationDate?: Maybe<Scalars['String']['output']>;
  defaultAddress?: Maybe<Address>;
  defaultBillingAddress?: Maybe<Address>;
  defaultShippingAddress?: Maybe<Address>;
  /** @deprecated Use the field `email`. */
  displayUid?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Language>;
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  titleCode?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
}

export interface UserBaseLoginInput {
  /** @deprecated Using another cloud solution for bot management. */
  captcha?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  restType?: InputMaybe<Scalars['String']['input']>;
  userid?: InputMaybe<Scalars['String']['input']>;
}

export interface ValidateTokenResponse {
  __typename?: 'ValidateTokenResponse';
  email?: Maybe<Scalars['String']['output']>;
}

export interface ValidatedPhoneNumber {
  __typename?: 'ValidatedPhoneNumber';
  internationallyFormattedNumber?: Maybe<Scalars['String']['output']>;
  isPossible: Scalars['Boolean']['output'];
  isValid: Scalars['Boolean']['output'];
  locallyFormattedNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberCountry?: Maybe<Scalars['String']['output']>;
}

export interface ValidationPatterns {
  __typename?: 'ValidationPatterns';
  bonuscardCoupon?: Maybe<Scalars['String']['output']>;
  coupon?: Maybe<Scalars['String']['output']>;
  dateFormat?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  imageUploadValidation?: Maybe<MultipartRequestConfiguration>;
  multipartFileUploadValidation?: Maybe<MultipartRequestConfiguration>;
  phone?: Maybe<Scalars['String']['output']>;
  postalcode?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  salesTaxNumberPrimary?: Maybe<Scalars['String']['output']>;
  salesTaxNumberSecondary?: Maybe<Scalars['String']['output']>;
}

export interface VariantCategory {
  __typename?: 'VariantCategory';
  hasImage?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
}

export interface VariantContainer {
  __typename?: 'VariantContainer';
  restType?: Maybe<Scalars['String']['output']>;
  useProductPictures?: Maybe<Scalars['Boolean']['output']>;
  variantGroups?: Maybe<Array<VariantGroup>>;
  variantHighlightType?: Maybe<Scalars['String']['output']>;
  variantPrimaryEnabled?: Maybe<Scalars['Boolean']['output']>;
  variants?: Maybe<Array<Product>>;
}

export interface VariantData {
  __typename?: 'VariantData';
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface VariantGroup {
  __typename?: 'VariantGroup';
  count?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  variantValues?: Maybe<Array<VariantValue>>;
  variantValuesEnabled?: Maybe<Scalars['Boolean']['output']>;
}

export interface VariantMatrixElement {
  __typename?: 'VariantMatrixElement';
  elements?: Maybe<Array<VariantMatrixElement>>;
  isLeaf?: Maybe<Scalars['Boolean']['output']>;
  parentVariantCategory?: Maybe<VariantCategory>;
  restType?: Maybe<Scalars['String']['output']>;
  variantOption?: Maybe<VariantOption>;
  variantValueCategory?: Maybe<VariantValueCategory>;
}

export interface VariantOption {
  __typename?: 'VariantOption';
  code?: Maybe<Scalars['String']['output']>;
  priceData?: Maybe<Price>;
  restType?: Maybe<Scalars['String']['output']>;
  stock?: Maybe<Stock>;
  url?: Maybe<Scalars['String']['output']>;
  variantOptionQualifiers?: Maybe<Array<VariantOptionQualifier>>;
}

export interface VariantOptionQualifier {
  __typename?: 'VariantOptionQualifier';
  image?: Maybe<Image>;
  name?: Maybe<Scalars['String']['output']>;
  qualifier?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface VariantValue {
  __typename?: 'VariantValue';
  colorMapping?: Maybe<Scalars['String']['output']>;
  colorTitle?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  priceDifference?: Maybe<Scalars['Float']['output']>;
  product?: Maybe<Product>;
  productPicture?: Maybe<Image>;
  restType?: Maybe<Scalars['String']['output']>;
  selected?: Maybe<Scalars['Boolean']['output']>;
  siblingProductCodes?: Maybe<Scalars['String']['output']>;
  targetColor?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
}

export interface VariantValueCategory {
  __typename?: 'VariantValueCategory';
  name?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['Int']['output']>;
  superCategories?: Maybe<Array<VariantCategory>>;
}

export interface Viewport {
  __typename?: 'Viewport';
  northeast?: Maybe<Coordinates>;
  southwest?: Maybe<Coordinates>;
}

export interface VisualRecosParams {
  excludeSameProduct?: InputMaybe<Scalars['Boolean']['input']>;
  minShown?: InputMaybe<Scalars['Int']['input']>;
  resultsLimit?: InputMaybe<Scalars['Int']['input']>;
  similarity?: InputMaybe<Scalars['Float']['input']>;
  uniqueProductOnly?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface Voucher {
  __typename?: 'Voucher';
  absolute?: Maybe<Scalars['Boolean']['output']>;
  applied?: Maybe<Scalars['Boolean']['output']>;
  appliedValue?: Maybe<Price>;
  code?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']['output']>;
  freeShipping?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  overflowAmount?: Maybe<Scalars['Float']['output']>;
  promotionCode?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  restrictions?: Maybe<Array<BaseRestriction>>;
  value?: Maybe<Scalars['Float']['output']>;
  valueFormatted?: Maybe<Scalars['String']['output']>;
  valueString?: Maybe<Scalars['String']['output']>;
  violationMessage?: Maybe<Scalars['String']['output']>;
  voucherCode?: Maybe<Scalars['String']['output']>;
  voucherType?: Maybe<Scalars['String']['output']>;
}

export interface WeekdayOpeningDay {
  __typename?: 'WeekdayOpeningDay';
  closed?: Maybe<Scalars['Boolean']['output']>;
  closingTime?: Maybe<Time>;
  openingTime?: Maybe<Time>;
  restType?: Maybe<Scalars['String']['output']>;
  weekDay?: Maybe<Scalars['String']['output']>;
}

export interface WeekdayOpeningDayInput {
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  closingTime?: InputMaybe<TimeInput>;
  openingTime?: InputMaybe<TimeInput>;
  restType?: InputMaybe<Scalars['String']['input']>;
  weekDay?: InputMaybe<Scalars['String']['input']>;
}

/** @deprecated(reason: "Optile payment provider is end of life") */
export interface XPaymentSession {
  __typename?: 'XPaymentSession';
  javaScriptUrl?: Maybe<Scalars['String']['output']>;
  optileUrl?: Maybe<Scalars['String']['output']>;
  pgProvider?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  restType?: Maybe<Scalars['String']['output']>;
  sessionID?: Maybe<Scalars['String']['output']>;
}

export interface ZipCodeResponse {
  __typename?: 'ZipCodeResponse';
  geometry?: Maybe<Geometry>;
  zipCode?: Maybe<PostalCode>;
}

export interface AssignToCustomerUsingPost {
  __typename?: 'assignToCustomerUsingPOST';
  empty?: Maybe<Scalars['String']['output']>;
}

export interface CancelAppointmentUsingPost {
  __typename?: 'cancelAppointmentUsingPOST';
  empty?: Maybe<Scalars['String']['output']>;
}

export interface CheckBonuscardUsingPost {
  __typename?: 'checkBonuscardUsingPOST';
  empty?: Maybe<Scalars['String']['output']>;
}

export interface ContactEmailUsingPost {
  __typename?: 'contactEmailUsingPOST';
  empty?: Maybe<Scalars['String']['output']>;
}

export interface CreateProductRatingUsingPost {
  __typename?: 'createProductRatingUsingPOST';
  empty?: Maybe<Scalars['String']['output']>;
}

export interface DeleteProductRatingUsingDelete {
  __typename?: 'deleteProductRatingUsingDELETE';
  empty?: Maybe<Scalars['String']['output']>;
}

export interface GetRatingOptionsUsingOptions {
  __typename?: 'getRatingOptionsUsingOPTIONS';
  empty?: Maybe<Scalars['String']['output']>;
}

export interface GetTokenUsingGet {
  __typename?: 'getTokenUsingGET';
  empty?: Maybe<Scalars['String']['output']>;
}

export interface MarkingBonuscardAsLostUsingPost {
  __typename?: 'markingBonuscardAsLostUsingPOST';
  empty?: Maybe<Scalars['String']['output']>;
}

export interface OrderBrochuresUsingPost {
  __typename?: 'orderBrochuresUsingPOST';
  empty?: Maybe<Scalars['String']['output']>;
}

export interface RegisterUsingPost {
  __typename?: 'registerUsingPOST';
  empty?: Maybe<Scalars['String']['output']>;
}

export interface RequestResetPasswordEmailUsingPost {
  __typename?: 'requestResetPasswordEmailUsingPOST';
  empty?: Maybe<Scalars['String']['output']>;
}

export interface ResetPasswordUsingPost {
  __typename?: 'resetPasswordUsingPOST';
  empty?: Maybe<Scalars['String']['output']>;
}

export interface ServicesTotalCost {
  __typename?: 'servicesTotalCost';
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
}

export interface SubscribeNewsletterUsingPost {
  __typename?: 'subscribeNewsletterUsingPOST';
  empty?: Maybe<Scalars['String']['output']>;
}

export type CampaignsQueryVariables = Exact<{
  cmsPageCode: Scalars['String']['input'];
  productCode?: InputMaybe<Scalars['String']['input']>;
  include: Scalars['Boolean']['input'];
}>;

export type CampaignsQuery = {
  __typename?: 'Query';
  getFHCampaigns?: {
    __typename?: 'FredhopperCampaigns';
    restType: string | null;
    type: string | null;
    id: string | null;
    fhPreview: {
      __typename?: 'FhPreview';
      showPreviewButton: boolean | null;
      url: string | null;
    } | null;
    campaigns: Array<{
      __typename?: 'CampaignDataType';
      restType: string | null;
      id: string | null;
      title: string | null;
      moreItemsLink: string | null;
      provider: string | null;
      widgetId: string | null;
      recommendationId: string | null;
      searchResult: {
        __typename?: 'FHSearchResult';
        pagination: { __typename?: 'Pagination'; totalResults: number | null } | null;
      } | null;
      products: Array<
        | {
            __typename?: 'Campaign';
            title: string | null;
            id: string | null;
            type: string | null;
            searchResult: {
              __typename?: 'CampaignSearchResult';
              searchResults: Array<{
                __typename?: 'CampaignSearchResultData';
                columnSpan: number | null;
                type: string | null;
                links: Array<{
                  __typename?: 'Link';
                  url: string | null;
                  alternativeText: string | null;
                  openInNewWindow: boolean | null;
                }> | null;
                media: {
                  __typename?: 'Image';
                  altText: string | null;
                  fileType: string | null;
                  seoText: string | null;
                  url: string | null;
                  cdnFilename: string | null;
                  realFilename: string | null;
                } | null;
              }> | null;
            } | null;
          }
        | {
            __typename?: 'Product';
            baseProduct: string | null;
            id: string | null;
            type: string | null;
            availabilityStatus: string | null;
            averageRating: number | null;
            name: string | null;
            numberOfReviews: number | null;
            url: string | null;
            code: string | null;
            firstItemOfPage: number | null;
            hasRatings: boolean | null;
            legalInformationShown: boolean | null;
            color: string | null;
            eyecatcherABIsShown: boolean | null;
            freeDelivery: boolean | null;
            configurable: boolean | null;
            statisticKPI: string | null;
            attributes: {
              __typename?: 'ProductAttributes';
              material: string | null;
              colorMapping: {
                __typename?: 'ColorMapping';
                code: string | null;
                targetColor: string | null;
                webColor: string | null;
              } | null;
              dimensions: {
                __typename?: 'Dimensions';
                strRepresentation: string | null;
                strRepresentationKey: string | null;
                dimensions: Array<{
                  __typename?: 'Dimension';
                  type: string | null;
                  value: string | null;
                }> | null;
              } | null;
            } | null;
            priceData: {
              __typename?: 'ProductPriceData';
              hideUvp: boolean | null;
              sellingUnit: string | null;
              sellingAmount: number | null;
              productUnitDiffersToPriceUnit: boolean | null;
              savedPercent: number | null;
              currentPrice: {
                __typename?: 'Price';
                currencyIso: string | null;
                value: number | null;
                specialOfferTypeData: {
                  __typename?: 'SpecialOfferType';
                  numberOfAsterisks: number | null;
                } | null;
              } | null;
              deliveryCost: { __typename?: 'Price'; value: number | null } | null;
              offerAttributes: { __typename?: 'Offer'; offerId: string | null } | null;
              postCost: { __typename?: 'Price'; value: number | null } | null;
              pricePerUnit: { __typename?: 'Price'; value: number | null } | null;
              shippingCost: { __typename?: 'Price'; value: number | null } | null;
              oldPrice: { __typename?: 'Price'; value: number | null } | null;
              currentNetPrice: { __typename?: 'Price'; value: number | null } | null;
              oldNetPrice: { __typename?: 'Price'; value: number | null } | null;
              last30DaysBestPrice: { __typename?: 'Price'; value: number | null } | null;
            } | null;
            energyEfficiencyData: {
              __typename?: 'EnergyEfficiencyData';
              classColor: string | null;
              eeClass: string | null;
              energyLabel2021Shown: boolean | null;
              datasheet: {
                __typename?: 'Image';
                url: string | null;
                realFilename: string | null;
              } | null;
              label: {
                __typename?: 'Image';
                altText: string | null;
                cdnFilename: string | null;
                filename: string | null;
              } | null;
            } | null;
            mediaData: {
              __typename?: 'ProductMediaData';
              presentation: Array<{
                __typename?: 'Image';
                altText: string | null;
                cdnFilename: string | null;
                realFilename: string | null;
                seoText: string | null;
                fileType: string | null;
              }> | null;
              datasheets: Array<{
                __typename?: 'Image';
                url: string | null;
                realFilename: string | null;
                documentType: string | null;
              }> | null;
            } | null;
            brands: Array<{ __typename?: 'Brand'; name: string | null }> | null;
            eyecatchers: Array<{
              __typename?: 'Eyecatcher';
              code: string | null;
              style: string | null;
            }> | null;
            orderData: { __typename?: 'ProductOrderData'; buyable: boolean | null } | null;
            style: {
              __typename?: 'ProductStyleData';
              code: string | null;
              name: string | null;
            } | null;
            variantContainerData: {
              __typename?: 'VariantContainer';
              variantHighlightType: string | null;
              variantGroups: Array<{
                __typename?: 'VariantGroup';
                name: string | null;
                variantValues: Array<{
                  __typename?: 'VariantValue';
                  enabled: boolean | null;
                  restType: string | null;
                  selected: boolean | null;
                  value: string | null;
                  product: {
                    __typename?: 'Product';
                    availabilityStatus: string | null;
                    code: string | null;
                    color: string | null;
                    name: string | null;
                    url: string | null;
                    priceData: {
                      __typename?: 'ProductPriceData';
                      hideUvp: boolean | null;
                      sellingUnit: string | null;
                      sellingAmount: number | null;
                      productUnitDiffersToPriceUnit: boolean | null;
                      savedPercent: number | null;
                      currentPrice: {
                        __typename?: 'Price';
                        currencyIso: string | null;
                        value: number | null;
                        specialOfferTypeData: {
                          __typename?: 'SpecialOfferType';
                          numberOfAsterisks: number | null;
                        } | null;
                      } | null;
                      deliveryCost: { __typename?: 'Price'; value: number | null } | null;
                      offerAttributes: { __typename?: 'Offer'; offerId: string | null } | null;
                      postCost: { __typename?: 'Price'; value: number | null } | null;
                      pricePerUnit: { __typename?: 'Price'; value: number | null } | null;
                      shippingCost: { __typename?: 'Price'; value: number | null } | null;
                      oldPrice: { __typename?: 'Price'; value: number | null } | null;
                      currentNetPrice: { __typename?: 'Price'; value: number | null } | null;
                      oldNetPrice: { __typename?: 'Price'; value: number | null } | null;
                      last30DaysBestPrice: { __typename?: 'Price'; value: number | null } | null;
                    } | null;
                  } | null;
                  productPicture: {
                    __typename?: 'Image';
                    altText: string | null;
                    cdnFilename: string | null;
                    hashCode: string | null;
                    fileType: string | null;
                    filename: string | null;
                    fileExtension: string | null;
                  } | null;
                }> | null;
              }> | null;
            } | null;
            hotdealData: {
              __typename?: 'HotDealData';
              assignedStore: { __typename?: 'PointOfService'; name: string | null } | null;
            } | null;
          }
      > | null;
    }> | null;
  } | null;
};

export type GetAiRecommendationsQueryVariables = Exact<{
  productCode: Scalars['String']['input'];
  recommendationsParams?: InputMaybe<AiRecommendationsParams>;
}>;

export type GetAiRecommendationsQuery = {
  __typename?: 'Query';
  getAIRecommendations: {
    __typename?: 'ProductList';
    restType: string | null;
    products: Array<{
      __typename?: 'Product';
      baseProduct: string | null;
      id: string | null;
      type: string | null;
      source: string | null;
      originalresponse: string | null;
      availabilityStatus: string | null;
      averageRating: number | null;
      name: string | null;
      numberOfReviews: number | null;
      url: string | null;
      code: string | null;
      firstItemOfPage: number | null;
      hasRatings: boolean | null;
      legalInformationShown: boolean | null;
      color: string | null;
      eyecatcherABIsShown: boolean | null;
      freeDelivery: boolean | null;
      configurable: boolean | null;
      statisticKPI: string | null;
      orderData: { __typename?: 'ProductOrderData'; buyable: boolean | null } | null;
      attributes: {
        __typename?: 'ProductAttributes';
        material: string | null;
        colorMapping: {
          __typename?: 'ColorMapping';
          code: string | null;
          targetColor: string | null;
          webColor: string | null;
        } | null;
        dimensions: {
          __typename?: 'Dimensions';
          strRepresentation: string | null;
          strRepresentationKey: string | null;
          dimensions: Array<{
            __typename?: 'Dimension';
            type: string | null;
            value: string | null;
          }> | null;
        } | null;
      } | null;
      priceData: {
        __typename?: 'ProductPriceData';
        hideUvp: boolean | null;
        sellingUnit: string | null;
        sellingAmount: number | null;
        productUnitDiffersToPriceUnit: boolean | null;
        savedPercent: number | null;
        currentPrice: {
          __typename?: 'Price';
          currencyIso: string | null;
          value: number | null;
          specialOfferTypeData: {
            __typename?: 'SpecialOfferType';
            numberOfAsterisks: number | null;
          } | null;
        } | null;
        deliveryCost: { __typename?: 'Price'; value: number | null } | null;
        offerAttributes: { __typename?: 'Offer'; offerId: string | null } | null;
        postCost: { __typename?: 'Price'; value: number | null } | null;
        pricePerUnit: { __typename?: 'Price'; value: number | null } | null;
        shippingCost: { __typename?: 'Price'; value: number | null } | null;
        oldPrice: { __typename?: 'Price'; value: number | null } | null;
        currentNetPrice: { __typename?: 'Price'; value: number | null } | null;
        oldNetPrice: { __typename?: 'Price'; value: number | null } | null;
        last30DaysBestPrice: { __typename?: 'Price'; value: number | null } | null;
      } | null;
      energyEfficiencyData: {
        __typename?: 'EnergyEfficiencyData';
        classColor: string | null;
        eeClass: string | null;
        energyLabel2021Shown: boolean | null;
        datasheet: { __typename?: 'Image'; url: string | null; realFilename: string | null } | null;
        label: {
          __typename?: 'Image';
          altText: string | null;
          cdnFilename: string | null;
          filename: string | null;
        } | null;
      } | null;
      mediaData: {
        __typename?: 'ProductMediaData';
        presentation: Array<{
          __typename?: 'Image';
          altText: string | null;
          cdnFilename: string | null;
          realFilename: string | null;
          seoText: string | null;
          fileType: string | null;
        }> | null;
        datasheets: Array<{
          __typename?: 'Image';
          url: string | null;
          realFilename: string | null;
          documentType: string | null;
        }> | null;
      } | null;
      brands: Array<{ __typename?: 'Brand'; name: string | null }> | null;
      eyecatchers: Array<{
        __typename?: 'Eyecatcher';
        code: string | null;
        style: string | null;
      }> | null;
      style: { __typename?: 'ProductStyleData'; code: string | null; name: string | null } | null;
      variantContainerData: {
        __typename?: 'VariantContainer';
        variantHighlightType: string | null;
        variantGroups: Array<{
          __typename?: 'VariantGroup';
          name: string | null;
          variantValues: Array<{
            __typename?: 'VariantValue';
            enabled: boolean | null;
            restType: string | null;
            selected: boolean | null;
            value: string | null;
            product: {
              __typename?: 'Product';
              availabilityStatus: string | null;
              code: string | null;
              color: string | null;
              name: string | null;
              url: string | null;
              priceData: {
                __typename?: 'ProductPriceData';
                hideUvp: boolean | null;
                sellingUnit: string | null;
                sellingAmount: number | null;
                productUnitDiffersToPriceUnit: boolean | null;
                savedPercent: number | null;
                currentPrice: {
                  __typename?: 'Price';
                  currencyIso: string | null;
                  value: number | null;
                  specialOfferTypeData: {
                    __typename?: 'SpecialOfferType';
                    numberOfAsterisks: number | null;
                  } | null;
                } | null;
                deliveryCost: { __typename?: 'Price'; value: number | null } | null;
                offerAttributes: { __typename?: 'Offer'; offerId: string | null } | null;
                postCost: { __typename?: 'Price'; value: number | null } | null;
                pricePerUnit: { __typename?: 'Price'; value: number | null } | null;
                shippingCost: { __typename?: 'Price'; value: number | null } | null;
                oldPrice: { __typename?: 'Price'; value: number | null } | null;
                currentNetPrice: { __typename?: 'Price'; value: number | null } | null;
                oldNetPrice: { __typename?: 'Price'; value: number | null } | null;
                last30DaysBestPrice: { __typename?: 'Price'; value: number | null } | null;
              } | null;
            } | null;
            productPicture: {
              __typename?: 'Image';
              altText: string | null;
              cdnFilename: string | null;
              hashCode: string | null;
              fileType: string | null;
              filename: string | null;
              fileExtension: string | null;
            } | null;
          }> | null;
        }> | null;
      } | null;
      hotdealData: {
        __typename?: 'HotDealData';
        assignedStore: { __typename?: 'PointOfService'; name: string | null } | null;
      } | null;
    }> | null;
  } | null;
};

export type ContentPagesQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type ContentPagesQuery = {
  __typename?: 'Query';
  getContentPage: {
    __typename?: 'CmsContentPage';
    uid: string | null;
    code: string | null;
    name: string | null;
    restType: string | null;
    title: string | null;
    seoData: {
      __typename?: 'Seo';
      canonicalUrl: string | null;
      code: string | null;
      description: string | null;
      keywords: string | null;
      name: string | null;
      noFollow: boolean | null;
      noIndex: boolean | null;
      seoPath: string | null;
      title: string | null;
      url: string | null;
    } | null;
  } | null;
};

export type GetProductConfiguratorPdfQueryVariables = Exact<{
  code: Scalars['String']['input'];
  attachment: Scalars['String']['input'];
  skip?: Scalars['Boolean']['input'];
}>;

export type GetProductConfiguratorPdfQuery = {
  __typename?: 'Query';
  getProductConfiguratorPdf?: any | null;
};

export type CountryOverlayQueryVariables = Exact<{
  ip?: InputMaybe<Scalars['String']['input']>;
}>;

export type CountryOverlayQuery = {
  __typename?: 'Query';
  getCountryOverlay: {
    __typename?: 'CountryOverlay';
    code: string | null;
    contentSlots: any | null;
  } | null;
};

export type GetCountryRegionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountryRegionsQuery = {
  __typename?: 'Query';
  countries: Array<{
    __typename?: 'Country';
    isocode: string | null;
    name: string | null;
    restType: string | null;
  }> | null;
};

export type DistrictsQueryVariables = Exact<{
  regionIsoCode: Scalars['String']['input'];
}>;

export type DistrictsQuery = {
  __typename?: 'Query';
  districts: Array<{
    __typename?: 'DistrictsList';
    id: string | null;
    municipality: string | null;
    restType: string | null;
    town: string | null;
  }> | null;
};

export type GetRegionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRegionsQuery = {
  __typename?: 'Query';
  regions: Array<{
    __typename?: 'RegionsList';
    isocode: string | null;
    name: string | null;
    restType: string | null;
  }> | null;
};

export type NavigationQueryVariables = Exact<{
  codes?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  levelAmount?: InputMaybe<Scalars['Int']['input']>;
  levelOffset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type NavigationQuery = {
  __typename?: 'Query';
  navigation: { __typename?: 'Navigation'; data: any | null } | null;
};

export type SearchtermQueryVariables = Exact<{ [key: string]: never }>;

export type SearchtermQuery = {
  __typename?: 'Query';
  searchBar: { __typename?: 'SearchBar'; term: string | null; suggest: boolean | null } | null;
};

export type GetMetaNavigationQueryVariables = Exact<{ [key: string]: never }>;

export type GetMetaNavigationQuery = {
  __typename?: 'Query';
  getMetaNavigation: {
    __typename?: 'MetaNavigation';
    promotionNodes: Array<{
      __typename?: 'MetaNavigationNode';
      iconName: string | null;
      link: {
        __typename?: 'MetaNavigationLink';
        id: string;
        linkName: string;
        name: string | null;
        target: string | null;
        url: string;
      } | null;
    } | null> | null;
    serviceNodes: Array<{
      __typename?: 'MetaNavigationNode';
      iconName: string | null;
      link: {
        __typename?: 'MetaNavigationLink';
        id: string;
        linkName: string;
        name: string | null;
        target: string | null;
        url: string;
      } | null;
    } | null> | null;
    sidebarMenuNodes: Array<{
      __typename?: 'MetaNavigationNode';
      iconName: string | null;
      link: {
        __typename?: 'MetaNavigationLink';
        id: string;
        linkName: string;
        name: string | null;
        target: string | null;
        url: string;
      } | null;
    } | null> | null;
  } | null;
};

export type SubscribeNewsletterMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['String']['input']>;
  timendoArea?: InputMaybe<Scalars['String']['input']>;
}>;

export type SubscribeNewsletterMutation = {
  __typename?: 'Mutation';
  subscribeNewsletter: boolean | null;
};

export type SubscribeNewsletterCdmMutationVariables = Exact<{
  email: Scalars['String']['input'];
  time: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  socialAndPartnersConsent?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SubscribeNewsletterCdmMutation = {
  __typename?: 'Mutation';
  subscribeNewsletterCDM: boolean | null;
};

export type SubscribeAnonymousNewsletterCdmMutationVariables = Exact<{
  data: AnonymousNewsletterData;
}>;

export type SubscribeAnonymousNewsletterCdmMutation = {
  __typename?: 'Mutation';
  subscribeAnonymousNewsletterCDM: boolean | null;
};

export type SubscribeLoggedInNewsletterCdmMutationVariables = Exact<{
  data: LoggedInNewsletterData;
}>;

export type SubscribeLoggedInNewsletterCdmMutation = {
  __typename?: 'Mutation';
  subscribeLoggedInNewsletterCDM: boolean | null;
};

export type SendRecommendationsSeenMutationVariables = Exact<{
  seenProducts:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type SendRecommendationsSeenMutation = {
  __typename?: 'Mutation';
  sendRecommendationsSeen: boolean | null;
};

export type SearchPickupStationsQueryVariables = Exact<{
  postalCode: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SearchPickupStationsQuery = {
  __typename?: 'Query';
  searchPickupStations: {
    __typename?: 'PointOfServices';
    restType: string | null;
    defaultPostalCode: {
      __typename?: 'PostalCode';
      id: string | null;
      label: string | null;
      latitude: number | null;
      longitude: number | null;
      restType: string | null;
      town: string | null;
      value: string | null;
    } | null;
    pointOfServices: Array<{
      __typename?: 'PointOfService';
      code: string | null;
      distanceKm: number | null;
      formattedDistance: string | null;
      openingHoursText: string | null;
      name: string | null;
      restType: string | null;
      locationType: string | null;
      address: {
        __typename?: 'Address';
        formattedAddress: string | null;
        line1: string | null;
        line2: string | null;
        phone: string | null;
        postalCode: string | null;
        streetname: string | null;
        streetnumber: string | null;
        town: string | null;
        restType: string | null;
        region: {
          __typename?: 'Region';
          countryIso: string | null;
          isocode: string | null;
          name: string | null;
          restType: string | null;
        } | null;
      } | null;
      geoPoint: {
        __typename?: 'GeoPoint';
        latitude: number | null;
        longitude: number | null;
        restType: string | null;
      } | null;
      services: Array<{
        __typename?: 'PosService';
        code: string | null;
        name: string | null;
      }> | null;
      seoData: {
        __typename?: 'PointOfService_seoData';
        title: string | null;
        description: string | null;
        noIndex: boolean | null;
        noFollow: boolean | null;
      } | null;
    }> | null;
  } | null;
};

export type UnitBasedCalculationQueryVariables = Exact<{
  amount: Scalars['Float']['input'];
  code: Scalars['String']['input'];
}>;

export type UnitBasedCalculationQuery = {
  __typename?: 'Query';
  getUnitBasedCalculation: {
    __typename?: 'UnitBasedCalculation';
    amount: number | null;
    deliveryAmount: number | null;
    packageAmount: number | null;
    selectedDeliveryAmount: number | null;
    productUnitBasedAmount: string | null;
    restType: string | null;
    price: {
      __typename?: 'Price';
      currencyIso: string | null;
      formattedValue: string | null;
      priceType: string | null;
      restType: string | null;
      value: number | null;
    } | null;
  } | null;
};

export type DimensionsFragment = {
  __typename?: 'Dimensions';
  dimensions: Array<{ __typename?: 'Dimension'; type: string | null; value: string | null }> | null;
};

export type PriceFragment = {
  __typename?: 'Price';
  currencyIso: string | null;
  value: number | null;
};

export type AddressFragment = {
  __typename?: 'Address';
  addition1: string | null;
  companyName: string | null;
  dateOfBirth: string | null;
  defaultAddress: boolean | null;
  email: string | null;
  fax: string | null;
  firstName: string | null;
  floor: string | null;
  formattedAddress: string | null;
  gender: string | null;
  id: string | null;
  lastName: string | null;
  lift: boolean | null;
  line1: string | null;
  line2: string | null;
  phone: string | null;
  phone2: string | null;
  postalCode: string | null;
  pricepassNr: string | null;
  restType: string | null;
  shippingAddress: boolean | null;
  streetname: string | null;
  streetnumber: string | null;
  title: string | null;
  titleCode: string | null;
  town: string | null;
  uid: string | null;
  visibleInAddressBook: boolean | null;
  country: { __typename?: 'Country'; isocode: string | null; name: string | null } | null;
  region: {
    __typename?: 'Region';
    countryIso: string | null;
    isocode: string | null;
    isocodeShort: string | null;
    name: string | null;
  } | null;
  salesTaxNumbers: Array<{
    __typename?: 'SalesTaxNumber';
    number: string | null;
    rank: string | null;
  }> | null;
};

export type BreadcrumbsFragment = {
  __typename?: 'Breadcrumb';
  itemCode: string | null;
  seoUrl: string | null;
  name: string | null;
  type: string | null;
};

type CoreFacetGeoFacetFragment = {
  __typename: 'GeoFacet';
  filterId: string;
  id: string;
  title: string | null;
};

type CoreFacetMultiSelectColorFacetFragment = {
  __typename: 'MultiSelectColorFacet';
  filterId: string;
  id: string;
  title: string | null;
};

type CoreFacetMultiSelectFacetFragment = {
  __typename: 'MultiSelectFacet';
  filterId: string;
  id: string;
  title: string | null;
};

type CoreFacetRangeFacetFragment = {
  __typename: 'RangeFacet';
  filterId: string;
  id: string;
  title: string | null;
};

type CoreFacetSingleSelectFacetFragment = {
  __typename: 'SingleSelectFacet';
  filterId: string;
  id: string;
  title: string | null;
};

type CoreFacetSortFacetFragment = {
  __typename: 'SortFacet';
  filterId: string;
  id: string;
  title: string | null;
};

export type CoreFacetFragment =
  | CoreFacetGeoFacetFragment
  | CoreFacetMultiSelectColorFacetFragment
  | CoreFacetMultiSelectFacetFragment
  | CoreFacetRangeFacetFragment
  | CoreFacetSingleSelectFacetFragment
  | CoreFacetSortFacetFragment;

export type MultiSelectColorValuesFragment = {
  __typename: 'MultiSelectColorValues';
  count: number;
  id: string;
  title: string;
  valueId: string;
};

export type MultiSelectValuesFragment = {
  __typename: 'MultiSelectValues';
  count: number;
  id: string;
  title: string;
  valueId: string;
};

export type PromotionResultFragment = {
  __typename?: 'PromotionResult';
  description: string | null;
  id: string | null;
  name: string | null;
  productPriceReduction: boolean | null;
  valueDisplayed: boolean | null;
  consumedEntries: Array<{
    __typename?: 'PromotionOrderEntryConsumed';
    adjustedUnitPrice: number | null;
    orderEntryNumber: number | null;
    reductionValue: number | null;
    quantity: string | null;
  }> | null;
  promotion: { __typename?: 'Promotion'; code: string | null } | null;
  promotionDiscount: {
    __typename?: 'Price';
    currencyIso: string | null;
    value: number | null;
  } | null;
};

export type ReducedPriceDataFragment = {
  __typename?: 'ProductPriceData';
  savedPercent: number | null;
  productUnitDiffersToPriceUnit: boolean | null;
  currentPrice: { __typename?: 'Price'; currencyIso: string | null; value: number | null } | null;
  oldPrice: { __typename?: 'Price'; value: number | null } | null;
  currentNetPrice: { __typename?: 'Price'; value: number | null } | null;
  oldNetPrice: { __typename?: 'Price'; value: number | null } | null;
  last30DaysBestPrice: { __typename?: 'Price'; value: number | null } | null;
  shippingCost: { __typename?: 'Price'; value: number | null } | null;
  postCost: { __typename?: 'Price'; value: number | null } | null;
};

export type ServicesFragment = {
  __typename?: 'OrderEntry';
  services: Array<{
    __typename?: 'EntryServiceData';
    code: string | null;
    consumed: boolean | null;
    price: number | null;
    restType: string | null;
    totalPrice: number | null;
    type: string | null;
  }> | null;
};

export type CommonPriceDataFragment = {
  __typename?: 'ProductPriceData';
  hideUvp: boolean | null;
  sellingUnit: string | null;
  sellingAmount: number | null;
  productUnitDiffersToPriceUnit: boolean | null;
  savedPercent: number | null;
  deliveryCost: { __typename?: 'Price'; value: number | null } | null;
  offerAttributes: { __typename?: 'Offer'; offerId: string | null } | null;
  postCost: { __typename?: 'Price'; value: number | null } | null;
  pricePerUnit: { __typename?: 'Price'; value: number | null } | null;
  shippingCost: { __typename?: 'Price'; value: number | null } | null;
  currentPrice: { __typename?: 'Price'; currencyIso: string | null; value: number | null } | null;
  oldPrice: { __typename?: 'Price'; value: number | null } | null;
  currentNetPrice: { __typename?: 'Price'; value: number | null } | null;
  oldNetPrice: { __typename?: 'Price'; value: number | null } | null;
  last30DaysBestPrice: { __typename?: 'Price'; value: number | null } | null;
};

export type MediaDataFragment = {
  __typename?: 'ProductMediaData';
  presentation: Array<{
    __typename?: 'Image';
    cdnFilename: string | null;
    altText: string | null;
    realFilename: string | null;
    fileType: string | null;
  }> | null;
  datasheets: Array<{
    __typename?: 'Image';
    url: string | null;
    cdnFilename: string | null;
    downloadName: string | null;
    documentType: string | null;
  }> | null;
};

export type EnergyEfficiencyDataFragment = {
  __typename?: 'EnergyEfficiencyData';
  classColor: string | null;
  eeClass: string | null;
  energyLabel2021Shown: boolean | null;
  datasheet: { __typename?: 'Image'; url: string | null; realFilename: string | null } | null;
  label: {
    __typename?: 'Image';
    altText: string | null;
    cdnFilename: string | null;
    filename: string | null;
  } | null;
};

export type BasicProductFragment = {
  __typename?: 'Product';
  baseProduct: string | null;
  code: string | null;
  name: string | null;
  color: string | null;
  url: string | null;
  deliveryData: {
    __typename?: 'ProductDeliveryData';
    deliveryCostsCharged: boolean | null;
    assembly: boolean | null;
    bulkyProduct: boolean | null;
    customDeliveryType: string | null;
    defaultDeliveryTimeRetrievalEnabled: boolean | null;
    delivery: boolean | null;
    deliveryIndicator: string | null;
    fastDeliveryShown: boolean | null;
  } | null;
  characteristicData: {
    __typename?: 'ProductCharacteristicData';
    colorMapping: {
      __typename?: 'ColorMapping';
      targetColor: string | null;
      webColor: string | null;
    } | null;
    detailAttributes: Array<{
      __typename?: 'ProductAttributeGroupData';
      attributes: Array<{
        __typename?: 'ProductAttributeData';
        code: string | null;
        name: string | null;
        value: string | null;
      }> | null;
    }> | null;
  } | null;
  breadcrumbs: Array<{
    __typename?: 'Breadcrumb';
    itemCode: string | null;
    seoUrl: string | null;
    name: string | null;
    type: string | null;
  }> | null;
  mediaData: {
    __typename?: 'ProductMediaData';
    presentation: Array<{
      __typename?: 'Image';
      cdnFilename: string | null;
      altText: string | null;
      realFilename: string | null;
      fileType: string | null;
    }> | null;
    datasheets: Array<{
      __typename?: 'Image';
      url: string | null;
      cdnFilename: string | null;
      downloadName: string | null;
      documentType: string | null;
    }> | null;
  } | null;
  energyEfficiencyData: {
    __typename?: 'EnergyEfficiencyData';
    classColor: string | null;
    eeClass: string | null;
    energyLabel2021Shown: boolean | null;
    datasheet: { __typename?: 'Image'; url: string | null; realFilename: string | null } | null;
    label: {
      __typename?: 'Image';
      altText: string | null;
      cdnFilename: string | null;
      filename: string | null;
    } | null;
  } | null;
  eyecatchers: Array<{
    __typename?: 'Eyecatcher';
    code: string | null;
    name: string | null;
  }> | null;
  style: { __typename?: 'ProductStyleData'; code: string | null; name: string | null } | null;
  brands: Array<{
    __typename?: 'Brand';
    code: string | null;
    name: string | null;
    url: string | null;
    urlCode: string | null;
    image: {
      __typename?: 'Image';
      altText: string | null;
      filename: string | null;
      pictureHeight: number | null;
      pictureWidth: number | null;
    } | null;
  }> | null;
  attributes: {
    __typename?: 'ProductAttributes';
    material: string | null;
    colorMapping: {
      __typename?: 'ColorMapping';
      code: string | null;
      targetColor: string | null;
      webColor: string | null;
    } | null;
    dimensions: {
      __typename?: 'Dimensions';
      strRepresentation: string | null;
      strRepresentationKey: string | null;
      dimensions: Array<{
        __typename?: 'Dimension';
        type: string | null;
        value: string | null;
      }> | null;
    } | null;
  } | null;
  picture: { __typename?: 'Image'; altText: string | null; cdnFilename: string | null } | null;
  price: {
    __typename?: 'Price';
    value: number | null;
    specialOfferTypeData: {
      __typename?: 'SpecialOfferType';
      numberOfAsterisks: number | null;
    } | null;
  } | null;
  priceData: {
    __typename?: 'ProductPriceData';
    hideUvp: boolean | null;
    sellingUnit: string | null;
    sellingAmount: number | null;
    productUnitDiffersToPriceUnit: boolean | null;
    savedPercent: number | null;
    currentPrice: {
      __typename?: 'Price';
      endTime: string | null;
      currencyIso: string | null;
      value: number | null;
      specialOfferTypeData: {
        __typename?: 'SpecialOfferType';
        numberOfAsterisks: number | null;
      } | null;
    } | null;
    deliveryCost: { __typename?: 'Price'; value: number | null } | null;
    offerAttributes: { __typename?: 'Offer'; offerId: string | null } | null;
    postCost: { __typename?: 'Price'; value: number | null } | null;
    pricePerUnit: { __typename?: 'Price'; value: number | null } | null;
    shippingCost: { __typename?: 'Price'; value: number | null } | null;
    oldPrice: { __typename?: 'Price'; value: number | null } | null;
    currentNetPrice: { __typename?: 'Price'; value: number | null } | null;
    oldNetPrice: { __typename?: 'Price'; value: number | null } | null;
    last30DaysBestPrice: { __typename?: 'Price'; value: number | null } | null;
  } | null;
  reservationData: {
    __typename?: 'ProductReservationData';
    maxReservableAmount: number | null;
    reservable: boolean | null;
  } | null;
};

export type SubsidiaryFragment = {
  __typename?: 'SubsidiaryInformation';
  distance: number | null;
  reservable: boolean | null;
  selfService: boolean | null;
  displayInformation: {
    __typename?: 'DisplayInformation';
    displayed: boolean | null;
    location: {
      __typename?: 'Location';
      department: string | null;
      floor: string | null;
      formattedFloorText: string | null;
    } | null;
  } | null;
  pointOfService: {
    __typename?: 'PointOfService';
    code: string | null;
    name: string | null;
    address: {
      __typename?: 'Address';
      postalCode: string | null;
      streetname: string | null;
      streetnumber: string | null;
      town: string | null;
      formattedAddress: string | null;
    } | null;
    geoPoint: { __typename?: 'GeoPoint'; latitude: number | null; longitude: number | null } | null;
    seoData: { __typename?: 'PointOfService_seoData'; url: string | null } | null;
  } | null;
  shippingInformation: Array<{
    __typename?: 'ShippingInformation';
    deliveryTime: number | null;
    deliveryTimeText: string | null;
    shippingType: string | null;
  }> | null;
};

export type ConfiguredProductFragment = {
  __typename?: 'Product';
  configuredProduct: boolean | null;
  configuredProductData: {
    __typename?: 'ConfiguredProductData';
    configuredId: string | null;
    productNumber: string | null;
    description: string | null;
    name: string | null;
    restType: string | null;
    summary: string | null;
    attachments: Array<{ __typename?: 'Image'; altText: string | null; url: string | null }> | null;
    images: Array<{ __typename?: 'Image'; altText: string | null; url: string | null }> | null;
  } | null;
};

export type PaymentAddressFragment = {
  __typename?: 'Address';
  addressId: string | null;
  companyName: string | null;
  countryIsoCode: string | null;
  email: string | null;
  firstName: string | null;
  floor: string | null;
  gender: string | null;
  lastName: string | null;
  lift: boolean | null;
  line1: string | null;
  line2: string | null;
  phone: string | null;
  postalCode: string | null;
  pricepassNr: string | null;
  regionIsoCode: string | null;
  streetname: string | null;
  streetnumber: string | null;
  titleCode: string | null;
  town: string | null;
  country: { __typename?: 'Country'; isocode: string | null; name: string | null } | null;
  region: { __typename?: 'Region'; isocode: string | null; name: string | null } | null;
  salesTaxNumbers: Array<{ __typename?: 'SalesTaxNumber'; number: string | null }> | null;
};

export type DeliveryModesFragment = {
  __typename?: 'OrderEntry';
  deliveryModes: Array<{
    __typename?: 'DeliveryMode';
    assemblyCost: number | null;
    code: string | null;
    customShippingType: string | null;
    deliveryCostSpecialType: string | null;
    deliveryTime: string | null;
    description: string | null;
    deliveryTimeText: string | null;
    isFreeDeliveryCost: boolean | null;
    name: string | null;
    deliveryCost: { __typename?: 'Price'; value: number | null } | null;
    deliveryCostSpecial: { __typename?: 'Price'; value: number | null } | null;
    selfServiceStores: Array<{
      __typename?: 'PointOfService';
      code: string | null;
      openingHoursText: string | null;
      name: string | null;
      address: {
        __typename?: 'Address';
        formattedAddress: string | null;
        line1: string | null;
        line2: string | null;
        phone: string | null;
        postalCode: string | null;
        streetname: string | null;
        streetnumber: string | null;
        town: string | null;
        region: {
          __typename?: 'Region';
          countryIso: string | null;
          isocode: string | null;
          name: string | null;
        } | null;
      } | null;
      geoPoint: {
        __typename?: 'GeoPoint';
        latitude: number | null;
        longitude: number | null;
      } | null;
      seoData: {
        __typename?: 'PointOfService_seoData';
        canonicalUrl: string | null;
        title: string | null;
        description: string | null;
        noIndex: boolean | null;
        noFollow: boolean | null;
        url: string | null;
      } | null;
    }> | null;
  }> | null;
  deliveryMode: {
    __typename?: 'DeliveryMode';
    code: string | null;
    description: string | null;
    name: string | null;
  } | null;
};

export type MessagesFragment = {
  __typename?: 'MessageData';
  message: string | null;
  arguments: Array<{
    __typename?: 'MessageData_arguments_items';
    type: string | null;
    value: any | null;
  }> | null;
};

export type PaymentModeFragment = {
  __typename?: 'PaymentMode';
  active: boolean | null;
  code: string | null;
  paymentModeId: string | null;
  description: string | null;
  mobileDescription: string | null;
  name: string | null;
  pgProvider: string | null;
  pspGroupedCode: string | null;
  redirectHint: string | null;
  redirectMessage: string | null;
  redirectPageHeader: string | null;
  logo: {
    __typename?: 'Image';
    altText: string | null;
    cdnFilename: string | null;
    fileExtension: string | null;
    format: string | null;
    mediaProvider: string | null;
    mime: string | null;
    pictureHeight: number | null;
    pictureWidth: number | null;
    realFilename: string | null;
    seoText: string | null;
    url: string | null;
  } | null;
  paymentCost: { __typename?: 'Price'; currencyIso: string | null; value: number | null } | null;
};

export type DeliveryAddressFragment = {
  __typename?: 'Address';
  addition1: string | null;
  addressId: string | null;
  email: string | null;
  firstName: string | null;
  floor: string | null;
  gender: string | null;
  lastName: string | null;
  lift: boolean | null;
  line1: string | null;
  line2: string | null;
  phone: string | null;
  postalCode: string | null;
  pricepassNr: string | null;
  regionIsoCode: string | null;
  streetname: string | null;
  streetnumber: string | null;
  titleCode: string | null;
  town: string | null;
  region: { __typename?: 'Region'; isocode: string | null; name: string | null } | null;
};

export type PointOfServiceFragment = {
  __typename?: 'PointOfService';
  name: string | null;
  code: string | null;
  description: string | null;
  address: { __typename?: 'Address'; formattedAddress: string | null } | null;
};

export type FavouriteProductFragment = {
  __typename?: 'Product';
  type: string | null;
  availabilityStatus: string | null;
  baseProduct: string | null;
  code: string | null;
  color: string | null;
  name: string | null;
  statisticKPI: string | null;
  url: string | null;
  additionalProductExpenses: Array<{
    __typename?: 'EcoTax';
    price: number | null;
    type: EcoTaxType | null;
    includedInProductPrice: boolean | null;
  }> | null;
  attributes: {
    __typename?: 'ProductAttributes';
    material: string | null;
    dimensions: {
      __typename?: 'Dimensions';
      strRepresentation: string | null;
      strRepresentationKey: string | null;
      dimensions: Array<{
        __typename?: 'Dimension';
        type: string | null;
        value: string | null;
      }> | null;
    } | null;
  } | null;
  mediaData: {
    __typename?: 'ProductMediaData';
    presentation: Array<{
      __typename?: 'Image';
      cdnFilename: string | null;
      altText: string | null;
      realFilename: string | null;
      fileType: string | null;
    }> | null;
    datasheets: Array<{
      __typename?: 'Image';
      url: string | null;
      cdnFilename: string | null;
      downloadName: string | null;
      documentType: string | null;
    }> | null;
  } | null;
  energyEfficiencyData: {
    __typename?: 'EnergyEfficiencyData';
    classColor: string | null;
    eeClass: string | null;
    energyLabel2021Shown: boolean | null;
    datasheet: { __typename?: 'Image'; url: string | null; realFilename: string | null } | null;
    label: {
      __typename?: 'Image';
      altText: string | null;
      cdnFilename: string | null;
      filename: string | null;
    } | null;
  } | null;
  picture: { __typename?: 'Image'; altText: string | null; cdnFilename: string | null } | null;
  orderData: { __typename?: 'ProductOrderData'; buyable: boolean | null } | null;
  price: {
    __typename?: 'Price';
    value: number | null;
    specialOfferTypeData: {
      __typename?: 'SpecialOfferType';
      numberOfAsterisks: number | null;
    } | null;
  } | null;
  priceData: {
    __typename?: 'ProductPriceData';
    hideUvp: boolean | null;
    sellingUnit: string | null;
    sellingAmount: number | null;
    productUnitDiffersToPriceUnit: boolean | null;
    savedPercent: number | null;
    currentPrice: {
      __typename?: 'Price';
      currencyIso: string | null;
      value: number | null;
      specialOfferTypeData: {
        __typename?: 'SpecialOfferType';
        numberOfAsterisks: number | null;
      } | null;
    } | null;
    deliveryCost: { __typename?: 'Price'; value: number | null } | null;
    offerAttributes: { __typename?: 'Offer'; offerId: string | null } | null;
    postCost: { __typename?: 'Price'; value: number | null } | null;
    pricePerUnit: { __typename?: 'Price'; value: number | null } | null;
    shippingCost: { __typename?: 'Price'; value: number | null } | null;
    oldPrice: { __typename?: 'Price'; value: number | null } | null;
    currentNetPrice: { __typename?: 'Price'; value: number | null } | null;
    oldNetPrice: { __typename?: 'Price'; value: number | null } | null;
    last30DaysBestPrice: { __typename?: 'Price'; value: number | null } | null;
  } | null;
};

export type FavouriteFragment = {
  __typename?: 'FavouritesList';
  id: string | null;
  name: string | null;
  entries: Array<{
    __typename?: 'Favourite';
    id: string | null;
    item: {
      __typename?: 'FavouriteItem';
      code: string | null;
      entryNumber: string | null;
      quantity: number | null;
      type: string | null;
      product: {
        __typename?: 'Product';
        type: string | null;
        availabilityStatus: string | null;
        baseProduct: string | null;
        code: string | null;
        color: string | null;
        name: string | null;
        statisticKPI: string | null;
        url: string | null;
        additionalProductExpenses: Array<{
          __typename?: 'EcoTax';
          price: number | null;
          type: EcoTaxType | null;
          includedInProductPrice: boolean | null;
        }> | null;
        attributes: {
          __typename?: 'ProductAttributes';
          material: string | null;
          dimensions: {
            __typename?: 'Dimensions';
            strRepresentation: string | null;
            strRepresentationKey: string | null;
            dimensions: Array<{
              __typename?: 'Dimension';
              type: string | null;
              value: string | null;
            }> | null;
          } | null;
        } | null;
        mediaData: {
          __typename?: 'ProductMediaData';
          presentation: Array<{
            __typename?: 'Image';
            cdnFilename: string | null;
            altText: string | null;
            realFilename: string | null;
            fileType: string | null;
          }> | null;
          datasheets: Array<{
            __typename?: 'Image';
            url: string | null;
            cdnFilename: string | null;
            downloadName: string | null;
            documentType: string | null;
          }> | null;
        } | null;
        energyEfficiencyData: {
          __typename?: 'EnergyEfficiencyData';
          classColor: string | null;
          eeClass: string | null;
          energyLabel2021Shown: boolean | null;
          datasheet: {
            __typename?: 'Image';
            url: string | null;
            realFilename: string | null;
          } | null;
          label: {
            __typename?: 'Image';
            altText: string | null;
            cdnFilename: string | null;
            filename: string | null;
          } | null;
        } | null;
        picture: {
          __typename?: 'Image';
          altText: string | null;
          cdnFilename: string | null;
        } | null;
        orderData: { __typename?: 'ProductOrderData'; buyable: boolean | null } | null;
        price: {
          __typename?: 'Price';
          value: number | null;
          specialOfferTypeData: {
            __typename?: 'SpecialOfferType';
            numberOfAsterisks: number | null;
          } | null;
        } | null;
        priceData: {
          __typename?: 'ProductPriceData';
          hideUvp: boolean | null;
          sellingUnit: string | null;
          sellingAmount: number | null;
          productUnitDiffersToPriceUnit: boolean | null;
          savedPercent: number | null;
          currentPrice: {
            __typename?: 'Price';
            currencyIso: string | null;
            value: number | null;
            specialOfferTypeData: {
              __typename?: 'SpecialOfferType';
              numberOfAsterisks: number | null;
            } | null;
          } | null;
          deliveryCost: { __typename?: 'Price'; value: number | null } | null;
          offerAttributes: { __typename?: 'Offer'; offerId: string | null } | null;
          postCost: { __typename?: 'Price'; value: number | null } | null;
          pricePerUnit: { __typename?: 'Price'; value: number | null } | null;
          shippingCost: { __typename?: 'Price'; value: number | null } | null;
          oldPrice: { __typename?: 'Price'; value: number | null } | null;
          currentNetPrice: { __typename?: 'Price'; value: number | null } | null;
          oldNetPrice: { __typename?: 'Price'; value: number | null } | null;
          last30DaysBestPrice: { __typename?: 'Price'; value: number | null } | null;
        } | null;
      } | null;
    } | null;
  }> | null;
};

export type FavouriteWithDefaultFragment = {
  __typename?: 'FavouritesList';
  id: string | null;
  name: string | null;
  defaultList: boolean | null;
  entries: Array<{
    __typename?: 'Favourite';
    id: string | null;
    item: {
      __typename?: 'FavouriteItem';
      code: string | null;
      entryNumber: string | null;
      quantity: number | null;
      type: string | null;
      product: {
        __typename?: 'Product';
        type: string | null;
        availabilityStatus: string | null;
        baseProduct: string | null;
        code: string | null;
        color: string | null;
        name: string | null;
        statisticKPI: string | null;
        url: string | null;
        additionalProductExpenses: Array<{
          __typename?: 'EcoTax';
          price: number | null;
          type: EcoTaxType | null;
          includedInProductPrice: boolean | null;
        }> | null;
        attributes: {
          __typename?: 'ProductAttributes';
          material: string | null;
          dimensions: {
            __typename?: 'Dimensions';
            strRepresentation: string | null;
            strRepresentationKey: string | null;
            dimensions: Array<{
              __typename?: 'Dimension';
              type: string | null;
              value: string | null;
            }> | null;
          } | null;
        } | null;
        mediaData: {
          __typename?: 'ProductMediaData';
          presentation: Array<{
            __typename?: 'Image';
            cdnFilename: string | null;
            altText: string | null;
            realFilename: string | null;
            fileType: string | null;
          }> | null;
          datasheets: Array<{
            __typename?: 'Image';
            url: string | null;
            cdnFilename: string | null;
            downloadName: string | null;
            documentType: string | null;
          }> | null;
        } | null;
        energyEfficiencyData: {
          __typename?: 'EnergyEfficiencyData';
          classColor: string | null;
          eeClass: string | null;
          energyLabel2021Shown: boolean | null;
          datasheet: {
            __typename?: 'Image';
            url: string | null;
            realFilename: string | null;
          } | null;
          label: {
            __typename?: 'Image';
            altText: string | null;
            cdnFilename: string | null;
            filename: string | null;
          } | null;
        } | null;
        picture: {
          __typename?: 'Image';
          altText: string | null;
          cdnFilename: string | null;
        } | null;
        orderData: { __typename?: 'ProductOrderData'; buyable: boolean | null } | null;
        price: {
          __typename?: 'Price';
          value: number | null;
          specialOfferTypeData: {
            __typename?: 'SpecialOfferType';
            numberOfAsterisks: number | null;
          } | null;
        } | null;
        priceData: {
          __typename?: 'ProductPriceData';
          hideUvp: boolean | null;
          sellingUnit: string | null;
          sellingAmount: number | null;
          productUnitDiffersToPriceUnit: boolean | null;
          savedPercent: number | null;
          currentPrice: {
            __typename?: 'Price';
            currencyIso: string | null;
            value: number | null;
            specialOfferTypeData: {
              __typename?: 'SpecialOfferType';
              numberOfAsterisks: number | null;
            } | null;
          } | null;
          deliveryCost: { __typename?: 'Price'; value: number | null } | null;
          offerAttributes: { __typename?: 'Offer'; offerId: string | null } | null;
          postCost: { __typename?: 'Price'; value: number | null } | null;
          pricePerUnit: { __typename?: 'Price'; value: number | null } | null;
          shippingCost: { __typename?: 'Price'; value: number | null } | null;
          oldPrice: { __typename?: 'Price'; value: number | null } | null;
          currentNetPrice: { __typename?: 'Price'; value: number | null } | null;
          oldNetPrice: { __typename?: 'Price'; value: number | null } | null;
          last30DaysBestPrice: { __typename?: 'Price'; value: number | null } | null;
        } | null;
      } | null;
    } | null;
  }> | null;
};

export type OrderFragment = {
  __typename?: 'Order';
  code: string | null;
  guid: string | null;
  orderType: string | null;
  saveAddresses: boolean | null;
  totalItems: number | null;
  appliedOrderPromotions: Array<{
    __typename?: 'PromotionResult';
    removable: boolean | null;
    usedCouponCodes: Array<string> | null;
    description: string | null;
    id: string | null;
    name: string | null;
    productPriceReduction: boolean | null;
    valueDisplayed: boolean | null;
    consumedEntries: Array<{
      __typename?: 'PromotionOrderEntryConsumed';
      adjustedUnitPrice: number | null;
      orderEntryNumber: number | null;
      reductionValue: number | null;
      quantity: string | null;
    }> | null;
    promotion: { __typename?: 'Promotion'; code: string | null } | null;
    promotionDiscount: {
      __typename?: 'Price';
      currencyIso: string | null;
      value: number | null;
    } | null;
  }> | null;
  appliedProductPromotions: Array<{
    __typename?: 'PromotionResult';
    removable: boolean | null;
    usedCouponCodes: Array<string> | null;
    description: string | null;
    id: string | null;
    name: string | null;
    productPriceReduction: boolean | null;
    valueDisplayed: boolean | null;
    consumedEntries: Array<{
      __typename?: 'PromotionOrderEntryConsumed';
      adjustedUnitPrice: number | null;
      orderEntryNumber: number | null;
      reductionValue: number | null;
      quantity: string | null;
    }> | null;
    promotion: { __typename?: 'Promotion'; code: string | null } | null;
    promotionDiscount: {
      __typename?: 'Price';
      currencyIso: string | null;
      value: number | null;
    } | null;
  }> | null;
  appliedVoucherDataList: Array<{
    __typename?: 'Voucher';
    applied: boolean | null;
    code: string | null;
    description: string | null;
    freeShipping: boolean | null;
    overflowAmount: number | null;
    promotionCode: string | null;
    name: string | null;
    value: number | null;
    violationMessage: string | null;
    voucherType: string | null;
    appliedValue: { __typename?: 'Price'; currencyIso: string | null; value: number | null } | null;
    currency: { __typename?: 'Currency'; name: string | null } | null;
    restrictions: Array<
      | {
          __typename?: 'ProductBrandRestriction';
          type: string | null;
          brands: Array<{ __typename?: 'Brand'; code: string | null }> | null;
        }
      | {
          __typename?: 'ProductCategoryRestriction';
          type: string | null;
          categories: Array<{ __typename?: 'Category'; code: string | null }> | null;
        }
      | {
          __typename?: 'ProductConfiguredRestriction';
          configuredOnly: boolean | null;
          type: string | null;
        }
      | {
          __typename?: 'ProductEyecatcherRestriction';
          type: string | null;
          eyecatchers: Array<{ __typename?: 'Eyecatcher'; code: string | null }> | null;
        }
      | {
          __typename?: 'ProductGroupRestriction';
          categoryMappingCodesList: Array<string> | null;
          included: boolean | null;
          type: string | null;
        }
      | {
          __typename?: 'ProductQuantityRestriction';
          unit: string | null;
          quantity: number | null;
          type: string | null;
        }
      | {
          __typename?: 'ProductRestriction';
          type: string | null;
          products: Array<{ __typename?: 'Product'; code: string | null }> | null;
        }
      | { __typename?: 'Restriction'; type: string | null }
      | {
          __typename?: 'StatisticIdentifierRestriction';
          statisticIdentifier: string | null;
          type: string | null;
        }
    > | null;
  }> | null;
  currency: { __typename?: 'Currency'; isocode: string | null } | null;
  deliveryAddress: {
    __typename?: 'Address';
    addition1: string | null;
    addressId: string | null;
    email: string | null;
    firstName: string | null;
    floor: string | null;
    gender: string | null;
    lastName: string | null;
    lift: boolean | null;
    line1: string | null;
    line2: string | null;
    phone: string | null;
    postalCode: string | null;
    pricepassNr: string | null;
    regionIsoCode: string | null;
    streetname: string | null;
    streetnumber: string | null;
    titleCode: string | null;
    town: string | null;
    region: { __typename?: 'Region'; isocode: string | null; name: string | null } | null;
  } | null;
  paymentMode: {
    __typename?: 'PaymentMode';
    active: boolean | null;
    code: string | null;
    paymentModeId: string | null;
    description: string | null;
    mobileDescription: string | null;
    name: string | null;
    pgProvider: string | null;
    pspGroupedCode: string | null;
    redirectHint: string | null;
    redirectMessage: string | null;
    redirectPageHeader: string | null;
    logo: {
      __typename?: 'Image';
      altText: string | null;
      cdnFilename: string | null;
      fileExtension: string | null;
      format: string | null;
      mediaProvider: string | null;
      mime: string | null;
      pictureHeight: number | null;
      pictureWidth: number | null;
      realFilename: string | null;
      seoText: string | null;
      url: string | null;
    } | null;
    paymentCost: { __typename?: 'Price'; currencyIso: string | null; value: number | null } | null;
  } | null;
  paymentModes: Array<{
    __typename?: 'PaymentMode';
    active: boolean | null;
    code: string | null;
    description: string | null;
    logo: {
      __typename?: 'Image';
      altText: string | null;
      cdnFilename: string | null;
      fileExtension: string | null;
      format: string | null;
      mediaProvider: string | null;
      mime: string | null;
      pictureHeight: number | null;
      pictureWidth: number | null;
      realFilename: string | null;
      seoText: string | null;
      url: string | null;
    } | null;
  }> | null;
  entries: Array<{
    __typename?: 'OrderEntry';
    deliveryTime: number | null;
    entryNumber: number | null;
    quantity: number | null;
    selectedDeliveryMode: string | null;
    selectedQuantity: number | null;
    updateable: boolean | null;
    pointOfService: {
      __typename?: 'PointOfService';
      name: string | null;
      code: string | null;
      description: string | null;
      address: { __typename?: 'Address'; formattedAddress: string | null } | null;
    } | null;
    product: {
      __typename?: 'Product';
      baseProduct: string | null;
      code: string | null;
      name: string | null;
      color: string | null;
      url: string | null;
      deliveryData: {
        __typename?: 'ProductDeliveryData';
        deliveryCostsCharged: boolean | null;
        assembly: boolean | null;
        bulkyProduct: boolean | null;
        customDeliveryType: string | null;
        defaultDeliveryTimeRetrievalEnabled: boolean | null;
        delivery: boolean | null;
        deliveryIndicator: string | null;
        fastDeliveryShown: boolean | null;
      } | null;
      characteristicData: {
        __typename?: 'ProductCharacteristicData';
        colorMapping: {
          __typename?: 'ColorMapping';
          targetColor: string | null;
          webColor: string | null;
        } | null;
        detailAttributes: Array<{
          __typename?: 'ProductAttributeGroupData';
          attributes: Array<{
            __typename?: 'ProductAttributeData';
            code: string | null;
            name: string | null;
            value: string | null;
          }> | null;
        }> | null;
      } | null;
      breadcrumbs: Array<{
        __typename?: 'Breadcrumb';
        itemCode: string | null;
        seoUrl: string | null;
        name: string | null;
        type: string | null;
      }> | null;
      mediaData: {
        __typename?: 'ProductMediaData';
        presentation: Array<{
          __typename?: 'Image';
          cdnFilename: string | null;
          altText: string | null;
          realFilename: string | null;
          fileType: string | null;
        }> | null;
        datasheets: Array<{
          __typename?: 'Image';
          url: string | null;
          cdnFilename: string | null;
          downloadName: string | null;
          documentType: string | null;
        }> | null;
      } | null;
      energyEfficiencyData: {
        __typename?: 'EnergyEfficiencyData';
        classColor: string | null;
        eeClass: string | null;
        energyLabel2021Shown: boolean | null;
        datasheet: { __typename?: 'Image'; url: string | null; realFilename: string | null } | null;
        label: {
          __typename?: 'Image';
          altText: string | null;
          cdnFilename: string | null;
          filename: string | null;
        } | null;
      } | null;
      eyecatchers: Array<{
        __typename?: 'Eyecatcher';
        code: string | null;
        name: string | null;
      }> | null;
      style: { __typename?: 'ProductStyleData'; code: string | null; name: string | null } | null;
      brands: Array<{
        __typename?: 'Brand';
        code: string | null;
        name: string | null;
        url: string | null;
        urlCode: string | null;
        image: {
          __typename?: 'Image';
          altText: string | null;
          filename: string | null;
          pictureHeight: number | null;
          pictureWidth: number | null;
        } | null;
      }> | null;
      attributes: {
        __typename?: 'ProductAttributes';
        material: string | null;
        colorMapping: {
          __typename?: 'ColorMapping';
          code: string | null;
          targetColor: string | null;
          webColor: string | null;
        } | null;
        dimensions: {
          __typename?: 'Dimensions';
          strRepresentation: string | null;
          strRepresentationKey: string | null;
          dimensions: Array<{
            __typename?: 'Dimension';
            type: string | null;
            value: string | null;
          }> | null;
        } | null;
      } | null;
      picture: { __typename?: 'Image'; altText: string | null; cdnFilename: string | null } | null;
      price: {
        __typename?: 'Price';
        value: number | null;
        specialOfferTypeData: {
          __typename?: 'SpecialOfferType';
          numberOfAsterisks: number | null;
        } | null;
      } | null;
      priceData: {
        __typename?: 'ProductPriceData';
        hideUvp: boolean | null;
        sellingUnit: string | null;
        sellingAmount: number | null;
        productUnitDiffersToPriceUnit: boolean | null;
        savedPercent: number | null;
        currentPrice: {
          __typename?: 'Price';
          endTime: string | null;
          currencyIso: string | null;
          value: number | null;
          specialOfferTypeData: {
            __typename?: 'SpecialOfferType';
            numberOfAsterisks: number | null;
          } | null;
        } | null;
        deliveryCost: { __typename?: 'Price'; value: number | null } | null;
        offerAttributes: { __typename?: 'Offer'; offerId: string | null } | null;
        postCost: { __typename?: 'Price'; value: number | null } | null;
        pricePerUnit: { __typename?: 'Price'; value: number | null } | null;
        shippingCost: { __typename?: 'Price'; value: number | null } | null;
        oldPrice: { __typename?: 'Price'; value: number | null } | null;
        currentNetPrice: { __typename?: 'Price'; value: number | null } | null;
        oldNetPrice: { __typename?: 'Price'; value: number | null } | null;
        last30DaysBestPrice: { __typename?: 'Price'; value: number | null } | null;
      } | null;
      reservationData: {
        __typename?: 'ProductReservationData';
        maxReservableAmount: number | null;
        reservable: boolean | null;
      } | null;
    } | null;
    totalPrice: { __typename?: 'Price'; currencyIso: string | null; value: number | null } | null;
    unitBasedCalculation: {
      __typename?: 'UnitBasedCalculation';
      deliveryAmount: number | null;
      packageAmount: number | null;
      selectedDeliveryAmount: number | null;
      amount: number | null;
      oldPriceTotal: {
        __typename?: 'Price';
        currencyIso: string | null;
        formattedValue: string | null;
        priceType: string | null;
        value: number | null;
      } | null;
    } | null;
    deliveryModes: Array<{
      __typename?: 'DeliveryMode';
      assemblyCost: number | null;
      code: string | null;
      customShippingType: string | null;
      deliveryCostSpecialType: string | null;
      deliveryTime: string | null;
      description: string | null;
      deliveryTimeText: string | null;
      isFreeDeliveryCost: boolean | null;
      name: string | null;
      deliveryCost: { __typename?: 'Price'; value: number | null } | null;
      deliveryCostSpecial: { __typename?: 'Price'; value: number | null } | null;
      selfServiceStores: Array<{
        __typename?: 'PointOfService';
        code: string | null;
        openingHoursText: string | null;
        name: string | null;
        address: {
          __typename?: 'Address';
          formattedAddress: string | null;
          line1: string | null;
          line2: string | null;
          phone: string | null;
          postalCode: string | null;
          streetname: string | null;
          streetnumber: string | null;
          town: string | null;
          region: {
            __typename?: 'Region';
            countryIso: string | null;
            isocode: string | null;
            name: string | null;
          } | null;
        } | null;
        geoPoint: {
          __typename?: 'GeoPoint';
          latitude: number | null;
          longitude: number | null;
        } | null;
        seoData: {
          __typename?: 'PointOfService_seoData';
          canonicalUrl: string | null;
          title: string | null;
          description: string | null;
          noIndex: boolean | null;
          noFollow: boolean | null;
          url: string | null;
        } | null;
      }> | null;
    }> | null;
    deliveryMode: {
      __typename?: 'DeliveryMode';
      code: string | null;
      description: string | null;
      name: string | null;
    } | null;
    services: Array<{
      __typename?: 'EntryServiceData';
      code: string | null;
      consumed: boolean | null;
      price: number | null;
      restType: string | null;
      totalPrice: number | null;
      type: string | null;
    }> | null;
  }> | null;
  messages: Array<{
    __typename?: 'MessageData';
    message: string | null;
    arguments: Array<{
      __typename?: 'MessageData_arguments_items';
      type: string | null;
      value: any | null;
    }> | null;
  }> | null;
  paymentAddress: {
    __typename?: 'Address';
    addressId: string | null;
    companyName: string | null;
    countryIsoCode: string | null;
    email: string | null;
    firstName: string | null;
    floor: string | null;
    gender: string | null;
    lastName: string | null;
    lift: boolean | null;
    line1: string | null;
    line2: string | null;
    phone: string | null;
    postalCode: string | null;
    pricepassNr: string | null;
    regionIsoCode: string | null;
    streetname: string | null;
    streetnumber: string | null;
    titleCode: string | null;
    town: string | null;
    country: { __typename?: 'Country'; isocode: string | null; name: string | null } | null;
    region: { __typename?: 'Region'; isocode: string | null; name: string | null } | null;
    salesTaxNumbers: Array<{ __typename?: 'SalesTaxNumber'; number: string | null }> | null;
  } | null;
  deliveryCost: { __typename?: 'Price'; value: number | null } | null;
  deliveryCostAssembling: { __typename?: 'Price'; value: number | null } | null;
  deliveryCostWithoutSpecial: { __typename?: 'Price'; value: number | null } | null;
  subOrders: Array<{
    __typename?: 'Order';
    code: string | null;
    selectedStore: {
      __typename?: 'PointOfService';
      code: string | null;
      name: string | null;
      seoData: { __typename?: 'PointOfService_seoData'; url: string | null } | null;
    } | null;
    totalPriceWithTax: {
      __typename?: 'Price';
      currencyIso: string | null;
      value: number | null;
    } | null;
  }> | null;
  subTotal: { __typename?: 'Price'; currencyIso: string | null; value: number | null } | null;
  totalPrice: { __typename?: 'Price'; currencyIso: string | null; value: number | null } | null;
  totalPriceWithTax: {
    __typename?: 'Price';
    currencyIso: string | null;
    value: number | null;
  } | null;
  totalTax: { __typename?: 'Price'; currencyIso: string | null; value: number | null } | null;
  totalPriceWithoutCoupons: {
    __typename?: 'Price';
    currencyIso: string | null;
    value: number | null;
  } | null;
  totalNetPriceWithoutCoupons: {
    __typename?: 'Price';
    currencyIso: string | null;
    value: number | null;
  } | null;
  voucherSum: { __typename?: 'Price'; currencyIso: string | null; value: number | null } | null;
};

export type ProductConfiguratorResponseFragment = {
  __typename?: 'ProductConfigurationResponse';
  openType: string | null;
  restType: string | null;
  url: string | null;
};

export type CustomerDetailsFragment = {
  __typename?: 'CustomerDetails';
  salutation: number | null;
  title: number | null;
  firstname: string | null;
  lastname: string | null;
  birthdate: string | null;
  phone: string | null;
  created: string | null;
};

export type AddressDataFragment = {
  __typename?: 'AddressData';
  id: string | null;
  maddress: boolean | null;
  saddress: boolean | null;
  baddress: boolean | null;
  gender: number | null;
  titleCode: number | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  companyName: string | null;
  attn: string | null;
  streetname: string | null;
  streetnumber: string | null;
  floor: string | null;
  lift: boolean | null;
  postalCode: string | null;
  town: string | null;
  country: string | null;
  addition1: string | null;
  created: string | null;
  salesTaxNumbers: Array<{
    __typename?: 'Taxnumber';
    id: string | null;
    number: string | null;
    rank: number | null;
    created: string | null;
  }> | null;
};

export type ProductCampaignAndListingDataFragment = {
  __typename?: 'Product';
  availabilityStatus: string | null;
  averageRating: number | null;
  name: string | null;
  numberOfReviews: number | null;
  url: string | null;
  code: string | null;
  firstItemOfPage: number | null;
  hasRatings: boolean | null;
  legalInformationShown: boolean | null;
  color: string | null;
  eyecatcherABIsShown: boolean | null;
  freeDelivery: boolean | null;
  configurable: boolean | null;
  statisticKPI: string | null;
  attributes: {
    __typename?: 'ProductAttributes';
    material: string | null;
    colorMapping: {
      __typename?: 'ColorMapping';
      code: string | null;
      targetColor: string | null;
      webColor: string | null;
    } | null;
    dimensions: {
      __typename?: 'Dimensions';
      strRepresentation: string | null;
      strRepresentationKey: string | null;
      dimensions: Array<{
        __typename?: 'Dimension';
        type: string | null;
        value: string | null;
      }> | null;
    } | null;
  } | null;
  priceData: {
    __typename?: 'ProductPriceData';
    hideUvp: boolean | null;
    sellingUnit: string | null;
    sellingAmount: number | null;
    productUnitDiffersToPriceUnit: boolean | null;
    savedPercent: number | null;
    currentPrice: {
      __typename?: 'Price';
      currencyIso: string | null;
      value: number | null;
      specialOfferTypeData: {
        __typename?: 'SpecialOfferType';
        numberOfAsterisks: number | null;
      } | null;
    } | null;
    deliveryCost: { __typename?: 'Price'; value: number | null } | null;
    offerAttributes: { __typename?: 'Offer'; offerId: string | null } | null;
    postCost: { __typename?: 'Price'; value: number | null } | null;
    pricePerUnit: { __typename?: 'Price'; value: number | null } | null;
    shippingCost: { __typename?: 'Price'; value: number | null } | null;
    oldPrice: { __typename?: 'Price'; value: number | null } | null;
    currentNetPrice: { __typename?: 'Price'; value: number | null } | null;
    oldNetPrice: { __typename?: 'Price'; value: number | null } | null;
    last30DaysBestPrice: { __typename?: 'Price'; value: number | null } | null;
  } | null;
  energyEfficiencyData: {
    __typename?: 'EnergyEfficiencyData';
    classColor: string | null;
    eeClass: string | null;
    energyLabel2021Shown: boolean | null;
    datasheet: { __typename?: 'Image'; url: string | null; realFilename: string | null } | null;
    label: {
      __typename?: 'Image';
      altText: string | null;
      cdnFilename: string | null;
      filename: string | null;
    } | null;
  } | null;
  mediaData: {
    __typename?: 'ProductMediaData';
    presentation: Array<{
      __typename?: 'Image';
      altText: string | null;
      cdnFilename: string | null;
      realFilename: string | null;
      seoText: string | null;
      fileType: string | null;
    }> | null;
    datasheets: Array<{
      __typename?: 'Image';
      url: string | null;
      realFilename: string | null;
      documentType: string | null;
    }> | null;
  } | null;
  brands: Array<{ __typename?: 'Brand'; name: string | null }> | null;
  eyecatchers: Array<{
    __typename?: 'Eyecatcher';
    code: string | null;
    style: string | null;
  }> | null;
  orderData: { __typename?: 'ProductOrderData'; buyable: boolean | null } | null;
  style: { __typename?: 'ProductStyleData'; code: string | null; name: string | null } | null;
  variantContainerData: {
    __typename?: 'VariantContainer';
    variantHighlightType: string | null;
    variantGroups: Array<{
      __typename?: 'VariantGroup';
      name: string | null;
      variantValues: Array<{
        __typename?: 'VariantValue';
        enabled: boolean | null;
        restType: string | null;
        selected: boolean | null;
        value: string | null;
        product: {
          __typename?: 'Product';
          availabilityStatus: string | null;
          code: string | null;
          color: string | null;
          name: string | null;
          url: string | null;
          priceData: {
            __typename?: 'ProductPriceData';
            hideUvp: boolean | null;
            sellingUnit: string | null;
            sellingAmount: number | null;
            productUnitDiffersToPriceUnit: boolean | null;
            savedPercent: number | null;
            currentPrice: {
              __typename?: 'Price';
              currencyIso: string | null;
              value: number | null;
              specialOfferTypeData: {
                __typename?: 'SpecialOfferType';
                numberOfAsterisks: number | null;
              } | null;
            } | null;
            deliveryCost: { __typename?: 'Price'; value: number | null } | null;
            offerAttributes: { __typename?: 'Offer'; offerId: string | null } | null;
            postCost: { __typename?: 'Price'; value: number | null } | null;
            pricePerUnit: { __typename?: 'Price'; value: number | null } | null;
            shippingCost: { __typename?: 'Price'; value: number | null } | null;
            oldPrice: { __typename?: 'Price'; value: number | null } | null;
            currentNetPrice: { __typename?: 'Price'; value: number | null } | null;
            oldNetPrice: { __typename?: 'Price'; value: number | null } | null;
            last30DaysBestPrice: { __typename?: 'Price'; value: number | null } | null;
          } | null;
        } | null;
        productPicture: {
          __typename?: 'Image';
          altText: string | null;
          cdnFilename: string | null;
          hashCode: string | null;
          fileType: string | null;
          filename: string | null;
          fileExtension: string | null;
        } | null;
      }> | null;
    }> | null;
  } | null;
  hotdealData: {
    __typename?: 'HotDealData';
    assignedStore: { __typename?: 'PointOfService'; name: string | null } | null;
  } | null;
};

export type SeoDataFragment = {
  __typename?: 'Seo';
  canonicalUrl: string | null;
  title: string | null;
  url: string | null;
  description: string | null;
  keywords: string | null;
  name: string | null;
  noFollow: boolean | null;
  noIndex: boolean | null;
  baseStoreRelatedItems: Array<{
    __typename?: 'BaseStoreRelatedItem';
    locale: string | null;
    url: string | null;
  }> | null;
};

export type BrandsFragment = {
  __typename?: 'Brand';
  code: string | null;
  showBrandnameInPdpTitle: boolean | null;
  name: string | null;
  url: string | null;
  urlCode: string | null;
  image: {
    __typename?: 'Image';
    altText: string | null;
    filename: string | null;
    fileExtension: string | null;
    pictureHeight: number | null;
    pictureWidth: number | null;
  } | null;
};

export type CurrentPriceFragment = {
  __typename?: 'Price';
  endTime: string | null;
  value: number | null;
  currencyIso: string | null;
  specialOfferTypeData: {
    __typename?: 'SpecialOfferType';
    numberOfAsterisks: number | null;
  } | null;
};

export type HotDealDataFragment = {
  __typename?: 'HotDealData';
  itemNumber: string | null;
  assignedStore: {
    __typename?: 'PointOfService';
    name: string | null;
    regularOpeningHours: string | null;
    code: string | null;
    address: {
      __typename?: 'Address';
      email: string | null;
      firstName: string | null;
      lastName: string | null;
      phone: string | null;
      postalCode: string | null;
      streetname: string | null;
      streetnumber: string | null;
      town: string | null;
    } | null;
  } | null;
};

export type RatingStatisticFragment = {
  __typename?: 'RatingStatistic';
  ratingCount: number | null;
  ratingValue: number | null;
  ratingStatisticPerRatingValue: Array<{
    __typename?: 'RatingStatisticPerRating';
    ratingValue: number | null;
    ratingValueCount: number | null;
  }> | null;
};

export type PaginationFragment = {
  __typename?: 'ReviewList';
  pagination: {
    __typename?: 'ReviewPagination';
    currentPage: number | null;
    hasNextPage: boolean | null;
    hasPreviousPage: boolean | null;
    pageSize: number | null;
    totalPages: number | null;
    totalResults: number | null;
  } | null;
};

export type DimensionalRatingStatisticDataFragment = {
  __typename?: 'ReviewList';
  dimensionalRatingStatisticData: Array<{
    __typename?: 'RatingStatistic';
    ratingValue: number | null;
    ratingValueType: string | null;
    ratingStatisticPerRatingValue: Array<{
      __typename?: 'RatingStatisticPerRating';
      ratingValue: number | null;
      ratingValueCount: number | null;
    }> | null;
  }> | null;
};

export type DimensionTypesFragment = {
  __typename?: 'ReviewList';
  dimensionTypes: Array<{
    __typename?: 'DimensionType';
    type: string | null;
    value: string | null;
  }> | null;
};

export type ReviewsFragment = {
  __typename?: 'ReviewList';
  reviews: Array<{
    __typename?: 'Review';
    authorAlias: string | null;
    authorType: string | null;
    body: string | null;
    created: string | null;
    verifiedAuthor: boolean | null;
    id: string | null;
    ratingValue: number | null;
    title: string | null;
    countryCrossedData: {
      __typename?: 'CrossCountry';
      language: string | null;
      tld: string | null;
    } | null;
    variantData: Array<{
      __typename?: 'VariantData';
      name: string | null;
      title: string | null;
      value: string | null;
    }> | null;
    dimensionalRatingValues: Array<{
      __typename?: 'DimensionRating';
      type: string | null;
      value: number | null;
    }> | null;
    replies: Array<{
      __typename?: 'Reply';
      alias: string | null;
      createdTS: string | null;
      message: string | null;
    }> | null;
  }> | null;
};

export type PostContactCenterMutationVariables = Exact<{
  data: ContactCenterData;
  isComplaint?: InputMaybe<Scalars['Boolean']['input']>;
  isMarketplaceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type PostContactCenterMutation = {
  __typename?: 'Mutation';
  postContactCenter: boolean | null;
};

export type CheckAndPostMiraklContactCenterMutationVariables = Exact<{
  data: MiraklContactCenterData;
}>;

export type CheckAndPostMiraklContactCenterMutation = {
  __typename?: 'Mutation';
  checkAndPostMiraklContactCenter: {
    __typename?: 'MiraklContactCenterResponse';
    messageId: string | null;
    threadId: string | null;
  } | null;
};

export type ReplyToMiraklThreadMutationVariables = Exact<{
  data: MiraklThreadData;
}>;

export type ReplyToMiraklThreadMutation = {
  __typename?: 'Mutation';
  replyToMiraklThread: boolean | null;
};

export type GetCustomerCardQueryVariables = Exact<{
  bubbleErrors?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetCustomerCardQuery = {
  __typename?: 'Query';
  getCustomerCard: {
    __typename?: 'CustomerCardDetails';
    cardNumber: string | null;
    confirmed: boolean | null;
    created: string | null;
  } | null;
};

export type UpdateCustomerCardMutationVariables = Exact<{
  confirmed: Scalars['Boolean']['input'];
}>;

export type UpdateCustomerCardMutation = {
  __typename?: 'Mutation';
  updateCustomerCard: {
    __typename?: 'CustomerCardDetails';
    cardNumber: string | null;
    confirmed: boolean | null;
    created: string | null;
  } | null;
};

export type AcceptTermsAndConditionsMutationVariables = Exact<{
  created: Scalars['String']['input'];
}>;

export type AcceptTermsAndConditionsMutation = {
  __typename?: 'Mutation';
  acceptTermsAndConditions: boolean | null;
};

export type GetCustomerQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomerQuery = {
  __typename?: 'Query';
  getCustomer: {
    __typename?: 'CustomerInfo';
    tac: { __typename?: 'TermsAndConditions'; created: string } | null;
  } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
}>;

export type ChangePasswordMutation = { __typename?: 'Mutation'; changePassword: boolean | null };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: 'Query';
  getCurrentUser: {
    __typename?: 'User';
    anonymous: boolean | null;
    title: string | null;
    titleCode: string | null;
    firstName: string | null;
    lastName: string | null;
    name: string | null;
    uid: string | null;
    email: string | null;
    restType: string | null;
  } | null;
};

export type LoginMutationVariables = Exact<{
  userCredentials: UserBaseLoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'LoginResponse';
    isAnonymous: boolean | null;
    orderCartMerged: boolean | null;
  } | null;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  __typename?: 'Mutation';
  logout: { __typename?: 'LoginResponse'; orderCartMerged: boolean | null } | null;
};

export type ResetPasswordInquiryMutationVariables = Exact<{
  email: Scalars['String']['input'];
  captcha?: InputMaybe<Scalars['String']['input']>;
}>;

export type ResetPasswordInquiryMutation = {
  __typename?: 'Mutation';
  resetPasswordInquiry: boolean | null;
};

export type RegisterUserMutationVariables = Exact<{
  data: RegisterUserInput;
}>;

export type RegisterUserMutation = {
  __typename?: 'Mutation';
  registerUser: { __typename?: 'RegisterUserResponse'; email: string | null } | null;
};

export type RegisterUserConfirmationMutationVariables = Exact<{
  dblOptInKey: Scalars['String']['input'];
}>;

export type RegisterUserConfirmationMutation = {
  __typename?: 'Mutation';
  registerUserConfirmation: {
    __typename?: 'RegisterUserConfirmationResponse';
    email: string | null;
  } | null;
};

export type ResetPasswordMutationVariables = Exact<{
  tokenId: Scalars['String']['input'];
  token: Scalars['String']['input'];
  password: Scalars['String']['input'];
  captcha?: InputMaybe<Scalars['String']['input']>;
}>;

export type ResetPasswordMutation = { __typename?: 'Mutation'; resetPassword: boolean | null };

export type ValidateTokenMutationVariables = Exact<{
  tokenId: Scalars['String']['input'];
  token: Scalars['String']['input'];
  captcha?: InputMaybe<Scalars['String']['input']>;
}>;

export type ValidateTokenMutation = {
  __typename?: 'Mutation';
  validateToken: { __typename?: 'ValidateTokenResponse'; email: string | null } | null;
};

export type ResendBonuscardActivationMutationVariables = Exact<{
  email: Scalars['String']['input'];
  captcha?: InputMaybe<Scalars['String']['input']>;
}>;

export type ResendBonuscardActivationMutation = {
  __typename?: 'Mutation';
  resendBonuscardActivation: boolean | null;
};

export type DoubleOptInBonuscardMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type DoubleOptInBonuscardMutation = {
  __typename?: 'Mutation';
  doubleOptInBonuscard: { __typename?: 'BonuscardInfo'; number: string | null } | null;
};

export type AddBonuscardMutationVariables = Exact<{
  data: BonuscardRegisterRequestInput;
}>;

export type AddBonuscardMutation = { __typename?: 'Mutation'; addBonuscard: boolean | null };

export type GetNavigationNodesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetNavigationNodesQuery = {
  __typename?: 'Query';
  getNavigationNodes: { __typename?: 'Navigation'; data: any | null } | null;
};

export type GetFaqSuggestQueryVariables = Exact<{
  term: Scalars['String']['input'];
  maxWordsQuestion?: InputMaybe<Scalars['Int']['input']>;
  maxWordsAnswer?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetFaqSuggestQuery = {
  __typename?: 'Query';
  getFaqSuggest: {
    __typename: 'FaqSuggest';
    count: number | null;
    results: Array<{
      __typename: 'FaqSuggestItem';
      categoryIconName: string | null;
      questionTitle: string | null;
      answerContent: string | null;
      answerUrl: string | null;
    }> | null;
  } | null;
};

export type GetSellerQueryVariables = Exact<{
  sellerId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetSellerQuery = {
  __typename?: 'Query';
  getSeller: {
    __typename?: 'Seller';
    conditions: string | null;
    gdpr: string | null;
    name: string;
    description: string | null;
    imprint: string | null;
    returnPolicy: string | null;
    contactInformation: {
      __typename?: 'Address';
      companyName: string | null;
      titleCode: string | null;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
      phone: string | null;
      phone2: string | null;
      town: string | null;
      line1: string | null;
      line2: string | null;
      postalCode: string | null;
      streetname: string | null;
      formattedAddress: string | null;
      url: string | null;
      region: {
        __typename?: 'Region';
        countryIso: string | null;
        isocode: string | null;
        name: string | null;
      } | null;
      country: { __typename?: 'Country'; isocode: string | null; name: string | null } | null;
    };
    seoData: { __typename?: 'SellerSeoData'; url: string } | null;
  } | null;
};

export type BookAppointmentMutationVariables = Exact<{
  data: DeliveryAppointmentData;
}>;

export type BookAppointmentMutation = { __typename?: 'Mutation'; bookAppointment: boolean | null };

export type GetAppointmentProposalsQueryVariables = Exact<{
  lastname: Scalars['String']['input'];
  orderCode: Scalars['String']['input'];
}>;

export type GetAppointmentProposalsQuery = {
  __typename?: 'Query';
  getAppointmentProposals: {
    __typename?: 'AppointmentProposal';
    errorCode: string | null;
    noMoreAppointments: boolean | null;
    proposals: Array<{
      __typename?: 'Proposal';
      proposalId: string | null;
      startDate: string | null;
      endDate: string | null;
    }> | null;
  } | null;
};

export type BrochuresQueryVariables = Exact<{ [key: string]: never }>;

export type BrochuresQuery = {
  __typename?: 'Query';
  getBrochures: {
    __typename?: 'Brochures';
    brochureTypes: Array<{
      __typename?: 'BrochureType';
      name: string | null;
      seoCode: string | null;
      id: string | null;
      brochures: Array<{
        __typename?: 'Brochure';
        id: string | null;
        title: string | null;
        startDate: string | null;
        endDate: string | null;
        downloadUrl: string | null;
        image: {
          __typename?: 'Image';
          cdnFilename: string | null;
          fileExtension: string | null;
          realFilename: string | null;
        } | null;
      }> | null;
    }> | null;
  } | null;
};

export type ExpressPaymentAuthorizeMutationVariables = Exact<{
  cartId: Scalars['String']['input'];
  paymentMode: Scalars['String']['input'];
}>;

export type ExpressPaymentAuthorizeMutation = {
  __typename?: 'Mutation';
  expressPaymentAuthorize: {
    __typename?: 'ExpressAuthorize';
    customResponsePaymentData: string;
  } | null;
};

export type CategoryQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type CategoryQuery = {
  __typename?: 'Query';
  getCategory: {
    __typename?: 'Category';
    name: string | null;
    code: string | null;
    breadcrumbs: Array<{
      __typename?: 'Breadcrumb';
      itemCode: string | null;
      seoUrl: string | null;
      name: string | null;
      type: string | null;
    }> | null;
    image: { __typename?: 'Image'; hashCode: string | null } | null;
    seoData: {
      __typename?: 'Seo';
      canonicalUrl: string | null;
      description: string | null;
      keywords: string | null;
      noFollow: boolean | null;
      noIndex: boolean | null;
      title: string | null;
      url: string | null;
      baseStoreRelatedItems: Array<{
        __typename?: 'BaseStoreRelatedItem';
        locale: string | null;
        url: string | null;
      }> | null;
    } | null;
  } | null;
};

export type PlaceOrderMutationVariables = Exact<{
  data: PlaceOrderRequestInput;
}>;

export type PlaceOrderMutation = {
  __typename?: 'Mutation';
  placeOrder: {
    __typename?: 'Order';
    code: string | null;
    paymentStart: {
      __typename?: 'PaymentStart';
      customResponsePaymentData: any | null;
      error: boolean | null;
      htmlFragment: string | null;
      interactionCode: string | null;
      restType: string | null;
      returnCode: string | null;
      urlRedirect: string | null;
    } | null;
    messages: Array<{
      __typename?: 'MessageData';
      message: string | null;
      arguments: Array<{ __typename?: 'MessageData_arguments_items'; value: any | null }> | null;
    }> | null;
    paymentMode: {
      __typename?: 'PaymentMode';
      active: boolean | null;
      code: string | null;
      description: string | null;
      mobileDescription: string | null;
      name: string | null;
      pgProvider: string | null;
      redirectHint: string | null;
      redirectMessage: string | null;
      redirectPageHeader: string | null;
      paymentCost: { __typename?: 'Price'; value: number | null } | null;
    } | null;
    providerParameters: Array<{
      __typename?: 'ProviderParameter';
      key: string | null;
      value: string | null;
    }> | null;
  } | null;
};

export type PlaceOrderConfirmationAdyenMutationVariables = Exact<{
  data: PlaceOrderConfirmationAdyenInput;
}>;

export type PlaceOrderConfirmationAdyenMutation = {
  __typename?: 'Mutation';
  placeOrderConfirmationAdyen: {
    __typename?: 'PlaceOrderConfirmationAdyenResponse';
    pspReference: string;
    resultCode: string;
    merchantReference: string;
    paymentMethod: { __typename?: 'GenericTypeString'; type: string | null };
  } | null;
};

export type PostAppointmentMutationVariables = Exact<{
  data: AppointmentData;
}>;

export type PostAppointmentMutation = {
  __typename?: 'Mutation';
  postAppointment: { __typename?: 'AppointmentResponse'; appointmentId: string | null } | null;
};

export type GetAppointmentAreasQueryVariables = Exact<{ [key: string]: never }>;

export type GetAppointmentAreasQuery = {
  __typename?: 'Query';
  getAppointmentAreas: {
    __typename?: 'AreaList';
    areas: Array<{
      __typename?: 'Area';
      description: string | null;
      length: number | null;
      externId: string | null;
      id: number | null;
      name: string | null;
      publicName: string | null;
      thumbnail: {
        __typename?: 'Image';
        altText: string | null;
        cdnFilename: string | null;
        fileType: string | null;
        hashCode: string | null;
      } | null;
    }> | null;
  } | null;
};

export type AppointmentStoresQueryVariables = Exact<{
  areaId: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type AppointmentStoresQuery = {
  __typename?: 'Query';
  getAppointmentStores: {
    __typename?: 'PointOfServices';
    pointOfServices: Array<{
      __typename?: 'PointOfService';
      code: string | null;
      timendoGroupId: string | null;
      timendoInterventionId: string | null;
      longDescription: string | null;
      name: string | null;
      openingHoursText: string | null;
      address: {
        __typename?: 'Address';
        phone: string | null;
        email: string | null;
        firstName: string | null;
        lastName: string | null;
        postalCode: string | null;
        town: string | null;
        streetname: string | null;
        streetnumber: string | null;
      } | null;
      geoPoint: {
        __typename?: 'GeoPoint';
        latitude: number | null;
        longitude: number | null;
      } | null;
    }> | null;
  } | null;
};

export type GetAppointmentInformationQueryVariables = Exact<{
  areaId?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['String']['input']>;
  appointmentId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAppointmentInformationQuery = {
  __typename?: 'Query';
  getAppointmentInformation: {
    __typename?: 'AppointmentInformation';
    appointmentId: string | null;
    start: string | null;
    areaInformation: {
      __typename?: 'Area';
      description: string | null;
      length: number | null;
      externId: string | null;
      id: number | null;
      name: string | null;
      publicName: string | null;
      thumbnail: {
        __typename?: 'Image';
        altText: string | null;
        cdnFilename: string | null;
        fileType: string | null;
        hashCode: string | null;
      } | null;
    } | null;
    storeInformation: {
      __typename?: 'PointOfService';
      code: string | null;
      timendoGroupId: string | null;
      timendoInterventionId: string | null;
      longDescription: string | null;
      name: string | null;
      openingHoursText: string | null;
      address: {
        __typename?: 'Address';
        phone: string | null;
        email: string | null;
        firstName: string | null;
        lastName: string | null;
        postalCode: string | null;
        town: string | null;
        streetname: string | null;
        streetnumber: string | null;
      } | null;
      geoPoint: {
        __typename?: 'GeoPoint';
        latitude: number | null;
        longitude: number | null;
      } | null;
    } | null;
    customer: {
      __typename?: 'TimendoCustomer';
      email: string | null;
      firstName: string | null;
      gender: string | null;
      lastName: string | null;
      phone: string | null;
    } | null;
  } | null;
};

export type GetAppointmentTimeslotsQueryVariables = Exact<{
  areaId: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
}>;

export type GetAppointmentTimeslotsQuery = {
  __typename?: 'Query';
  getAppointmentTimeslots: {
    __typename?: 'Timeslots';
    availableTimeslots: Array<{
      __typename?: 'Timeslot';
      calendarIds: Array<number> | null;
      start: string | null;
    }> | null;
  } | null;
};

export type GetAppointmentTimeslotsWithInfoQueryVariables = Exact<{
  areaId: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
  start: Scalars['String']['input'];
  end: Scalars['String']['input'];
}>;

export type GetAppointmentTimeslotsWithInfoQuery = {
  __typename?: 'Query';
  getAppointmentTimeslots: {
    __typename?: 'Timeslots';
    availableTimeslots: Array<{
      __typename?: 'Timeslot';
      calendarIds: Array<number> | null;
      start: string | null;
    }> | null;
  } | null;
  getAppointmentInformation: {
    __typename?: 'AppointmentInformation';
    areaInformation: {
      __typename?: 'Area';
      description: string | null;
      length: number | null;
      externId: string | null;
      id: number | null;
      name: string | null;
      publicName: string | null;
      thumbnail: {
        __typename?: 'Image';
        altText: string | null;
        cdnFilename: string | null;
        fileType: string | null;
        hashCode: string | null;
      } | null;
    } | null;
    storeInformation: {
      __typename?: 'PointOfService';
      code: string | null;
      timendoGroupId: string | null;
      timendoInterventionId: string | null;
      longDescription: string | null;
      name: string | null;
      openingHoursText: string | null;
      address: {
        __typename?: 'Address';
        phone: string | null;
        email: string | null;
        firstName: string | null;
        lastName: string | null;
        postalCode: string | null;
        town: string | null;
        streetname: string | null;
        streetnumber: string | null;
      } | null;
      geoPoint: {
        __typename?: 'GeoPoint';
        latitude: number | null;
        longitude: number | null;
      } | null;
    } | null;
  } | null;
};

export type GetPocoContentTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetPocoContentTokenQuery = {
  __typename?: 'Query';
  getPocoContentToken: { __typename?: 'PocoContentTokenResponse'; token: string | null } | null;
};

export type ReverseImageSearchMutationVariables = Exact<{
  data: ImageSearchData;
}>;

export type ReverseImageSearchMutation = {
  __typename?: 'Mutation';
  reverseImageSearch: {
    __typename?: 'ProductCodes';
    codes: any | null;
    errorCode: string | null;
    restType: string | null;
  } | null;
};

export type ProductQueryVariables = Exact<{
  productCode: Scalars['String']['input'];
}>;

export type ProductQuery = {
  __typename?: 'Query';
  getProduct: {
    __typename?: 'Product';
    categoryId: number | null;
    color: string | null;
    name: string | null;
    code: string | null;
    timendoMasterId: string | null;
    timendoAreaCmsPage: string | null;
    description: string | null;
    transitionalDescription: boolean | null;
    teaserText: string | null;
    url: string | null;
    baseProduct: string | null;
    onlineOnly: boolean | null;
    availabilityStatus: string | null;
    legalInformationShown: boolean | null;
    configurable: boolean | null;
    configuredProduct: boolean | null;
    liveshopping: boolean | null;
    summary: string | null;
    eligibleForFreeDelivery: boolean | null;
    statisticKPI: string | null;
    hasRatings: boolean | null;
    manufactureCountry: { __typename?: 'ProductCountryOfOrigin'; name: string | null } | null;
    originCountry: { __typename?: 'ProductCountryOfOrigin'; name: string | null } | null;
    produceCountry: { __typename?: 'ProductCountryOfOrigin'; name: string | null } | null;
    attributes: {
      __typename?: 'ProductAttributes';
      material: string | null;
      colorMapping: {
        __typename?: 'ColorMapping';
        code: string | null;
        targetColor: string | null;
        webColor: string | null;
      } | null;
      dimensions: {
        __typename?: 'Dimensions';
        strRepresentation: string | null;
        strRepresentationKey: string | null;
        dimensions: Array<{
          __typename?: 'Dimension';
          type: string | null;
          value: string | null;
        }> | null;
      } | null;
    } | null;
    affirmators: Array<{
      __typename?: 'Affirmator';
      title: string | null;
      iconKey: string | null;
      link: string | null;
    }> | null;
    augmentedReality: {
      __typename?: 'ProductAugmentedReality';
      androidUrl: string | null;
      iosUrl: string | null;
    } | null;
    reservationData: {
      __typename?: 'ProductReservationData';
      maxReservableAmount: number | null;
      reservable: boolean | null;
    } | null;
    additionalProductExpenses: Array<{
      __typename?: 'EcoTax';
      price: number | null;
      type: EcoTaxType | null;
      includedInProductPrice: boolean | null;
      restType: string | null;
    }> | null;
    categories: Array<{ __typename?: 'Category'; name: string | null; code: string | null }> | null;
    configuratorData: {
      __typename?: 'ProductConfigurator';
      configurationId: string | null;
      configurationSystemId: string | null;
      restType: string | null;
    } | null;
    configuredProductData: {
      __typename?: 'ConfiguredProductData';
      configuredId: string | null;
      productNumber: string | null;
      description: string | null;
      name: string | null;
      restType: string | null;
      summary: string | null;
      attachments: Array<{
        __typename?: 'Image';
        altText: string | null;
        code: string | null;
        url: string | null;
      }> | null;
      images: Array<{ __typename?: 'Image'; altText: string | null; url: string | null }> | null;
    } | null;
    brands: Array<{
      __typename?: 'Brand';
      code: string | null;
      showBrandnameInPdpTitle: boolean | null;
      name: string | null;
      url: string | null;
      urlCode: string | null;
      image: {
        __typename?: 'Image';
        altText: string | null;
        filename: string | null;
        fileExtension: string | null;
        pictureHeight: number | null;
        pictureWidth: number | null;
      } | null;
    }> | null;
    deliveryData: {
      __typename?: 'ProductDeliveryData';
      assembly: boolean | null;
      bulkyProduct: boolean | null;
      customDeliveryType: string | null;
      deliveryCostsCharged: boolean | null;
      deliveryIndicator: string | null;
      deliveryInformationForCustomers: Array<{
        __typename?: 'ProductAttribute';
        code: string;
        name: string;
        value: string;
        valueId: string;
      }> | null;
    } | null;
    mediaData: {
      __typename?: 'ProductMediaData';
      presentation: Array<{
        __typename?: 'Image';
        cdnFilename: string | null;
        altText: string | null;
        realFilename: string | null;
        fileType: string | null;
      }> | null;
      datasheets: Array<{
        __typename?: 'Image';
        url: string | null;
        cdnFilename: string | null;
        downloadName: string | null;
        documentType: string | null;
      }> | null;
    } | null;
    characteristicData: {
      __typename?: 'ProductCharacteristicData';
      colorMapping: {
        __typename?: 'ColorMapping';
        code: string | null;
        targetColor: string | null;
        webColor: string | null;
      } | null;
      detailAttributes: Array<{
        __typename?: 'ProductAttributeGroupData';
        name: string | null;
        attributes: Array<{
          __typename?: 'ProductAttributeData';
          name: string | null;
          value: string | null;
        }> | null;
      }> | null;
    } | null;
    eyecatchers: Array<{
      __typename?: 'Eyecatcher';
      code: string | null;
      style: string | null;
    }> | null;
    shopInformation: {
      __typename?: 'ShopInformation';
      isExternal: boolean | null;
      shopId: string | null;
      shopName: string | null;
    } | null;
    seoData: {
      __typename?: 'Seo';
      canonicalUrl: string | null;
      title: string | null;
      url: string | null;
      description: string | null;
      keywords: string | null;
      name: string | null;
      noFollow: boolean | null;
      noIndex: boolean | null;
      baseStoreRelatedItems: Array<{
        __typename?: 'BaseStoreRelatedItem';
        locale: string | null;
        url: string | null;
      }> | null;
    } | null;
    breadcrumbs: Array<{
      __typename?: 'Breadcrumb';
      itemCode: string | null;
      seoUrl: string | null;
      name: string | null;
      type: string | null;
    }> | null;
    variantContainerData: {
      __typename?: 'VariantContainer';
      variantGroups: Array<{
        __typename?: 'VariantGroup';
        name: string | null;
        title: string | null;
        variantValues: Array<{
          __typename?: 'VariantValue';
          colorTitle: string | null;
          colorMapping: string | null;
          restType: string | null;
          enabled: boolean | null;
          selected: boolean | null;
          value: string | null;
          targetColor: string | null;
          product: {
            __typename?: 'Product';
            code: string | null;
            color: string | null;
            url: string | null;
          } | null;
          productPicture: {
            __typename?: 'Image';
            altText: string | null;
            cdnFilename: string | null;
            hashCode: string | null;
            fileExtension: string | null;
            fileType: string | null;
            mediaProvider: string | null;
            pictureHeight: number | null;
            pictureWidth: number | null;
            realFilename: string | null;
            restType: string | null;
            seoText: string | null;
          } | null;
        }> | null;
      }> | null;
    } | null;
    priceData: {
      __typename?: 'ProductPriceData';
      promotionalMessageShown: boolean | null;
      hideUvp: boolean | null;
      sellingUnit: string | null;
      sellingAmount: number | null;
      productUnitDiffersToPriceUnit: boolean | null;
      savedPercent: number | null;
      currentPrice: {
        __typename?: 'Price';
        endTime: string | null;
        currencyIso: string | null;
        value: number | null;
        specialOfferTypeData: {
          __typename?: 'SpecialOfferType';
          numberOfAsterisks: number | null;
        } | null;
      } | null;
      postCost: { __typename?: 'Price'; value: number | null } | null;
      deliveryCost: { __typename?: 'Price'; value: number | null } | null;
      deliveryMethods: Array<{
        __typename?: 'ProductDeliveryMethods';
        code: string | null;
        price: { __typename?: 'Price'; value: number | null } | null;
      } | null> | null;
      assemblyCost: { __typename?: 'Price'; value: number | null } | null;
      shippingCost: { __typename?: 'Price'; value: number | null } | null;
      offerAttributes: { __typename?: 'Offer'; offerId: string | null } | null;
      pricePerUnit: { __typename?: 'Price'; value: number | null } | null;
      oldPrice: { __typename?: 'Price'; value: number | null } | null;
      currentNetPrice: { __typename?: 'Price'; value: number | null } | null;
      oldNetPrice: { __typename?: 'Price'; value: number | null } | null;
      last30DaysBestPrice: { __typename?: 'Price'; value: number | null } | null;
    } | null;
    style: { __typename?: 'ProductStyleData'; code: string | null; name: string | null } | null;
    energyEfficiencyData: {
      __typename?: 'EnergyEfficiencyData';
      classColor: string | null;
      eeClass: string | null;
      energyLabel2021Shown: boolean | null;
      datasheet: { __typename?: 'Image'; url: string | null; realFilename: string | null } | null;
      label: {
        __typename?: 'Image';
        altText: string | null;
        cdnFilename: string | null;
        filename: string | null;
      } | null;
    } | null;
    orderData: {
      __typename?: 'ProductOrderData';
      buyable: boolean | null;
      maxOrderableAmount: number | null;
      sellable: boolean | null;
    } | null;
    additionalDescription: Array<{
      __typename?: 'ProductAdditionalDescription';
      name: string | null;
      attributes: Array<{
        __typename?: 'ProductAttributeData';
        name: string | null;
        value: string | null;
      }> | null;
    }> | null;
    relatedProductAvailability: {
      __typename?: 'RelatedProductAvailability';
      hasAccessories: boolean | null;
    } | null;
    productServices: Array<{
      __typename?: 'ProductService';
      code: string | null;
      price: number | null;
      type: string | null;
    }> | null;
  } | null;
};

export type GetProductComparisonQueryVariables = Exact<{
  productCode: Scalars['String']['input'];
}>;

export type GetProductComparisonQuery = {
  __typename?: 'Query';
  getProductComparison: {
    __typename?: 'ProductComparison';
    title: string | null;
    products: Array<{
      __typename?: 'ComparisonProduct';
      productId: string | null;
      productName: string | null;
      attributes: any | null;
      formattedPrice: string | null;
      price: string | null;
      image: string | null;
      url: string | null;
    }> | null;
    attributes: Array<{
      __typename?: 'ProductComparisonAttributes';
      key: string | null;
      name: string | null;
    }> | null;
  } | null;
};

export type GetProductComparisonServiceQueryVariables = Exact<{
  productCode: Scalars['String']['input'];
}>;

export type GetProductComparisonServiceQuery = {
  __typename?: 'Query';
  getProductComparisonService: {
    __typename?: 'ProductComparisonService';
    products: Array<{
      __typename?: 'ComparisonProductService';
      productId: string | null;
      productName: string | null;
      attributes: any | null;
      price: number | null;
      url: string | null;
      brand: { __typename?: 'ComparisonBrand'; name: string | null } | null;
      image: { __typename?: 'Image'; cdnFilename: string | null; altText: string | null } | null;
    }> | null;
    attributes: Array<{
      __typename?: 'ProductComparisonAttributes';
      key: string | null;
      name: string | null;
    }> | null;
  } | null;
};

export type GetProductComparisonServiceValidationQueryVariables = Exact<{
  productCode: Scalars['String']['input'];
  categoryId: Scalars['Int']['input'];
}>;

export type GetProductComparisonServiceValidationQuery = {
  __typename?: 'Query';
  getProductComparisonServiceValidation: {
    __typename?: 'ProductComparisonService';
    products: Array<{
      __typename?: 'ComparisonProductService';
      productId: string | null;
      productName: string | null;
      attributes: any | null;
      price: number | null;
      url: string | null;
      brand: { __typename?: 'ComparisonBrand'; name: string | null } | null;
      image: { __typename?: 'Image'; cdnFilename: string | null; altText: string | null } | null;
    }> | null;
    attributes: Array<{
      __typename?: 'ProductComparisonAttributes';
      key: string | null;
      name: string | null;
    }> | null;
  } | null;
};

export type ProductAvailabilityQueryVariables = Exact<{
  productCode: Scalars['String']['input'];
  qty?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
  maxSubsidiariesShown?: InputMaybe<Scalars['String']['input']>;
}>;

export type ProductAvailabilityQuery = {
  __typename?: 'Query';
  getProductAvailability: {
    __typename?: 'ProductAvailabilityInformation';
    assembly: boolean | null;
    status: string | null;
    nearestAvailableSubsidiaries: {
      __typename?: 'NearestAvailableSubsidiaries';
      reservationSubsidiaries: Array<{
        __typename?: 'SubsidiaryInformation';
        distance: number | null;
        reservable: boolean | null;
        selfService: boolean | null;
        displayInformation: {
          __typename?: 'DisplayInformation';
          displayed: boolean | null;
          location: {
            __typename?: 'Location';
            department: string | null;
            floor: string | null;
            formattedFloorText: string | null;
          } | null;
        } | null;
        pointOfService: {
          __typename?: 'PointOfService';
          code: string | null;
          name: string | null;
          address: {
            __typename?: 'Address';
            postalCode: string | null;
            streetname: string | null;
            streetnumber: string | null;
            town: string | null;
            formattedAddress: string | null;
          } | null;
          geoPoint: {
            __typename?: 'GeoPoint';
            latitude: number | null;
            longitude: number | null;
          } | null;
          seoData: { __typename?: 'PointOfService_seoData'; url: string | null } | null;
        } | null;
        shippingInformation: Array<{
          __typename?: 'ShippingInformation';
          deliveryTime: number | null;
          deliveryTimeText: string | null;
          shippingType: string | null;
        }> | null;
      }> | null;
      selfServiceSubsidiaries: Array<{
        __typename?: 'SubsidiaryInformation';
        distance: number | null;
        reservable: boolean | null;
        selfService: boolean | null;
        displayInformation: {
          __typename?: 'DisplayInformation';
          displayed: boolean | null;
          location: {
            __typename?: 'Location';
            department: string | null;
            floor: string | null;
            formattedFloorText: string | null;
          } | null;
        } | null;
        pointOfService: {
          __typename?: 'PointOfService';
          code: string | null;
          name: string | null;
          address: {
            __typename?: 'Address';
            postalCode: string | null;
            streetname: string | null;
            streetnumber: string | null;
            town: string | null;
            formattedAddress: string | null;
          } | null;
          geoPoint: {
            __typename?: 'GeoPoint';
            latitude: number | null;
            longitude: number | null;
          } | null;
          seoData: { __typename?: 'PointOfService_seoData'; url: string | null } | null;
        } | null;
        shippingInformation: Array<{
          __typename?: 'ShippingInformation';
          deliveryTime: number | null;
          deliveryTimeText: string | null;
          shippingType: string | null;
        }> | null;
      }> | null;
    } | null;
    shippingInformation: Array<{
      __typename?: 'ShippingInformation';
      deliveryTime: number | null;
      deliveryTimeText: string | null;
      shippingType: string | null;
    }> | null;
    subsidiaries: Array<{
      __typename?: 'SubsidiaryInformation';
      distance: number | null;
      reservable: boolean | null;
      selfService: boolean | null;
      displayInformation: {
        __typename?: 'DisplayInformation';
        displayed: boolean | null;
        location: {
          __typename?: 'Location';
          department: string | null;
          floor: string | null;
          formattedFloorText: string | null;
        } | null;
      } | null;
      pointOfService: {
        __typename?: 'PointOfService';
        code: string | null;
        name: string | null;
        address: {
          __typename?: 'Address';
          postalCode: string | null;
          streetname: string | null;
          streetnumber: string | null;
          town: string | null;
          formattedAddress: string | null;
        } | null;
        geoPoint: {
          __typename?: 'GeoPoint';
          latitude: number | null;
          longitude: number | null;
        } | null;
        seoData: { __typename?: 'PointOfService_seoData'; url: string | null } | null;
      } | null;
      shippingInformation: Array<{
        __typename?: 'ShippingInformation';
        deliveryTime: number | null;
        deliveryTimeText: string | null;
        shippingType: string | null;
      }> | null;
    }> | null;
  } | null;
};

export type GetZipCodeQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
}>;

export type GetZipCodeQuery = {
  __typename?: 'Query';
  getZipCode: {
    __typename?: 'ZipCodeResponse';
    zipCode: {
      __typename?: 'PostalCode';
      id: string | null;
      label: string | null;
      value: string | null;
    } | null;
    geometry: {
      __typename?: 'Geometry';
      location: { __typename?: 'Coordinates'; lat: number | null; lng: number | null } | null;
      viewport: {
        __typename?: 'Viewport';
        northeast: { __typename?: 'Coordinates'; lat: number | null; lng: number | null } | null;
        southwest: { __typename?: 'Coordinates'; lat: number | null; lng: number | null } | null;
      } | null;
    } | null;
  } | null;
};

export type ZipCodeQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;

export type ZipCodeQuery = {
  __typename?: 'Query';
  suggestZipCode: {
    __typename?: 'PostalCodes';
    postalcodes: Array<{
      __typename?: 'PostalCode';
      value: string | null;
      town: string | null;
      label: string | null;
    }> | null;
  } | null;
};

export type GetProductReviewsQueryVariables = Exact<{
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  productCode: Scalars['String']['input'];
  filters?: InputMaybe<ReviewsFilters>;
}>;

export type GetProductReviewsQuery = {
  __typename?: 'Query';
  getProductReviews: {
    __typename?: 'ReviewList';
    approvalMode: ReviewApprovalMode;
    ratingStatistic: {
      __typename?: 'RatingStatistic';
      ratingCount: number | null;
      ratingValue: number | null;
      ratingStatisticPerRatingValue: Array<{
        __typename?: 'RatingStatisticPerRating';
        ratingValue: number | null;
        ratingValueCount: number | null;
      }> | null;
    } | null;
    dimensionTypes: Array<{
      __typename?: 'DimensionType';
      type: string | null;
      value: string | null;
    }> | null;
    pagination: {
      __typename?: 'ReviewPagination';
      currentPage: number | null;
      hasNextPage: boolean | null;
      hasPreviousPage: boolean | null;
      pageSize: number | null;
      totalPages: number | null;
      totalResults: number | null;
    } | null;
    dimensionalRatingStatisticData: Array<{
      __typename?: 'RatingStatistic';
      ratingValue: number | null;
      ratingValueType: string | null;
      ratingStatisticPerRatingValue: Array<{
        __typename?: 'RatingStatisticPerRating';
        ratingValue: number | null;
        ratingValueCount: number | null;
      }> | null;
    }> | null;
    reviews: Array<{
      __typename?: 'Review';
      authorAlias: string | null;
      authorType: string | null;
      body: string | null;
      created: string | null;
      verifiedAuthor: boolean | null;
      id: string | null;
      ratingValue: number | null;
      title: string | null;
      countryCrossedData: {
        __typename?: 'CrossCountry';
        language: string | null;
        tld: string | null;
      } | null;
      variantData: Array<{
        __typename?: 'VariantData';
        name: string | null;
        title: string | null;
        value: string | null;
      }> | null;
      dimensionalRatingValues: Array<{
        __typename?: 'DimensionRating';
        type: string | null;
        value: number | null;
      }> | null;
      replies: Array<{
        __typename?: 'Reply';
        alias: string | null;
        createdTS: string | null;
        message: string | null;
      }> | null;
    }> | null;
  } | null;
};

export type GetProductReviewOptionsQueryVariables = Exact<{
  productCode: Scalars['String']['input'];
}>;

export type GetProductReviewOptionsQuery = {
  __typename?: 'Query';
  getProductReviewOptions: {
    __typename?: 'ReviewOptions';
    canCreateReview: boolean | null;
    canGetReviews: boolean | null;
  } | null;
};

export type HotdealsProductQueryVariables = Exact<{
  productCode: Scalars['String']['input'];
}>;

export type HotdealsProductQuery = {
  __typename?: 'Query';
  getProduct: {
    __typename?: 'Product';
    name: string | null;
    code: string | null;
    url: string | null;
    baseProduct: string | null;
    availabilityStatus: string | null;
    legalInformationShown: boolean | null;
    description: string | null;
    summary: string | null;
    statisticKPI: string | null;
    brands: Array<{
      __typename?: 'Brand';
      code: string | null;
      showBrandnameInPdpTitle: boolean | null;
      name: string | null;
      url: string | null;
      urlCode: string | null;
      image: {
        __typename?: 'Image';
        altText: string | null;
        filename: string | null;
        fileExtension: string | null;
        pictureHeight: number | null;
        pictureWidth: number | null;
      } | null;
    }> | null;
    mediaData: {
      __typename?: 'ProductMediaData';
      presentation: Array<{
        __typename?: 'Image';
        cdnFilename: string | null;
        altText: string | null;
        realFilename: string | null;
        fileType: string | null;
      }> | null;
      datasheets: Array<{
        __typename?: 'Image';
        url: string | null;
        cdnFilename: string | null;
        downloadName: string | null;
        documentType: string | null;
      }> | null;
    } | null;
    seoData: {
      __typename?: 'Seo';
      canonicalUrl: string | null;
      title: string | null;
      url: string | null;
      description: string | null;
      keywords: string | null;
      name: string | null;
      noFollow: boolean | null;
      noIndex: boolean | null;
      baseStoreRelatedItems: Array<{
        __typename?: 'BaseStoreRelatedItem';
        locale: string | null;
        url: string | null;
      }> | null;
    } | null;
    breadcrumbs: Array<{
      __typename?: 'Breadcrumb';
      itemCode: string | null;
      seoUrl: string | null;
      name: string | null;
      type: string | null;
    }> | null;
    hotdealData: {
      __typename?: 'HotDealData';
      itemNumber: string | null;
      assignedStore: {
        __typename?: 'PointOfService';
        name: string | null;
        regularOpeningHours: string | null;
        code: string | null;
        address: {
          __typename?: 'Address';
          email: string | null;
          firstName: string | null;
          lastName: string | null;
          phone: string | null;
          postalCode: string | null;
          streetname: string | null;
          streetnumber: string | null;
          town: string | null;
        } | null;
      } | null;
    } | null;
    priceData: {
      __typename?: 'ProductPriceData';
      productUnitDiffersToPriceUnit: boolean | null;
      sellingUnit: string | null;
      sellingAmount: number | null;
      savedPercent: number | null;
      promotionalMessageShown: boolean | null;
      currentPrice: {
        __typename?: 'Price';
        endTime: string | null;
        value: number | null;
        currencyIso: string | null;
        specialOfferTypeData: {
          __typename?: 'SpecialOfferType';
          numberOfAsterisks: number | null;
        } | null;
      } | null;
      oldPrice: { __typename?: 'Price'; value: number | null } | null;
      pricePerUnit: { __typename?: 'Price'; value: number | null } | null;
    } | null;
  } | null;
};

export type PreloadHotdealsProductQueryVariables = Exact<{
  productCode: Scalars['String']['input'];
}>;

export type PreloadHotdealsProductQuery = {
  __typename?: 'Query';
  getProduct: {
    __typename?: 'Product';
    name: string | null;
    code: string | null;
    priceData: {
      __typename?: 'ProductPriceData';
      savedPercent: number | null;
      currentPrice: {
        __typename?: 'Price';
        value: number | null;
        specialOfferTypeData: {
          __typename?: 'SpecialOfferType';
          numberOfAsterisks: number | null;
        } | null;
      } | null;
      oldPrice: { __typename?: 'Price'; value: number | null } | null;
    } | null;
  } | null;
};

export type GetRatingProductDimensionTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRatingProductDimensionTypesQuery = {
  __typename?: 'Query';
  getRatingProductDimensionTypes: Array<{
    __typename?: 'DimensionType';
    type: string | null;
    value: string | null;
  }> | null;
};

export type GetProductsToReviewQueryVariables = Exact<{
  codes: Scalars['String']['input'];
  email: Scalars['String']['input'];
  orderCode: Scalars['String']['input'];
  year: Scalars['String']['input'];
  mac: Scalars['String']['input'];
}>;

export type GetProductsToReviewQuery = {
  __typename?: 'Query';
  getProductsToReview: {
    __typename?: 'ProductList';
    products: Array<{
      __typename?: 'Product';
      name: string | null;
      code: string | null;
      url: string | null;
      attributes: {
        __typename?: 'ProductAttributes';
        material: string | null;
        colorMapping: {
          __typename?: 'ColorMapping';
          code: string | null;
          targetColor: string | null;
          webColor: string | null;
        } | null;
        dimensions: {
          __typename?: 'Dimensions';
          strRepresentation: string | null;
          strRepresentationKey: string | null;
          dimensions: Array<{
            __typename?: 'Dimension';
            type: string | null;
            value: string | null;
          }> | null;
        } | null;
      } | null;
      brands: Array<{
        __typename?: 'Brand';
        code: string | null;
        showBrandnameInPdpTitle: boolean | null;
        name: string | null;
        url: string | null;
        urlCode: string | null;
        image: {
          __typename?: 'Image';
          altText: string | null;
          filename: string | null;
          fileExtension: string | null;
          pictureHeight: number | null;
          pictureWidth: number | null;
        } | null;
      }> | null;
      mediaData: {
        __typename?: 'ProductMediaData';
        presentation: Array<{
          __typename?: 'Image';
          cdnFilename: string | null;
          altText: string | null;
          realFilename: string | null;
          fileType: string | null;
        }> | null;
        datasheets: Array<{
          __typename?: 'Image';
          url: string | null;
          cdnFilename: string | null;
          downloadName: string | null;
          documentType: string | null;
        }> | null;
      } | null;
    }> | null;
  } | null;
};

export type CreateProductVerifiedReviewMutationVariables = Exact<{
  data: ReviewCreateInput;
  productCode: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  orderCode?: InputMaybe<Scalars['String']['input']>;
  orderProductCodes?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
  mac?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
}>;

export type CreateProductVerifiedReviewMutation = {
  __typename?: 'Mutation';
  createProductReview: boolean | null;
};

export type PlaceReservationMutationVariables = Exact<{
  data: PlaceReservationData;
}>;

export type PlaceReservationMutation = {
  __typename?: 'Mutation';
  placeReservation: {
    __typename?: 'PlaceReservationResponse';
    code: string | null;
    paymentAddress: {
      __typename?: 'Address';
      email: string | null;
      firstName: string | null;
      lastName: string | null;
      country: { __typename?: 'Country'; isocode: string | null } | null;
    } | null;
    subOrders: Array<{
      __typename?: 'Order';
      code: string | null;
      selectedStore: {
        __typename?: 'PointOfService';
        code: string | null;
        name: string | null;
        seoData: { __typename?: 'PointOfService_seoData'; url: string | null } | null;
      } | null;
      totalPriceWithTax: {
        __typename?: 'Price';
        currencyIso: string | null;
        value: number | null;
      } | null;
    }> | null;
  } | null;
};

export type ReservationExportStatusQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type ReservationExportStatusQuery = {
  __typename?: 'Query';
  reservationExportStatus: { __typename?: 'ExportStatus'; status: string | null } | null;
};

export type GetRestaurantOverviewQueryVariables = Exact<{
  subsidiaryId?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetRestaurantOverviewQuery = {
  __typename?: 'Query';
  getRestaurants: {
    __typename?: 'Restaurants';
    restaurants: Array<{
      __typename?: 'Restaurant';
      name: string | null;
      restType: string | null;
      type: string | null;
      distanceFromPostalCode: number | null;
      code: string | null;
      urlCode: string | null;
      address: {
        __typename?: 'Address';
        fax: string | null;
        phone: string | null;
        email: string | null;
        postalCode: string | null;
        town: string | null;
        streetname: string | null;
        streetnumber: string | null;
      } | null;
      openingHoursSchedule: {
        __typename?: 'RestaurantSchedule';
        regularOpeningDays: Array<{
          __typename?: 'OpeningDay';
          closed: boolean | null;
          code: string | null;
          weekDay: string | null;
          formattedDate: string | null;
          closingTime: { __typename?: 'OpeningHours'; formattedHour: string | null } | null;
          openingTime: { __typename?: 'OpeningHours'; formattedHour: string | null } | null;
        }> | null;
      } | null;
      geoPoint: {
        __typename?: 'GeoPoint';
        latitude: number | null;
        longitude: number | null;
      } | null;
      seoData: { __typename?: 'RestaurantSeoData'; url: string | null } | null;
    }> | null;
  } | null;
};

export type GetStoreOverviewQueryVariables = Exact<{
  subsidiaryId?: InputMaybe<Scalars['String']['input']>;
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetStoreOverviewQuery = {
  __typename?: 'Query';
  getPointOfServices: {
    __typename?: 'PointOfServices';
    pointOfServices: Array<{
      __typename?: 'PointOfService';
      regularOpeningHours: string | null;
      distanceFromPostalCode: number | null;
      code: string | null;
      longDescription: string | null;
      name: string | null;
      newFlag: boolean | null;
      openingHoursText: string | null;
      type: string | null;
      seoData: {
        __typename?: 'PointOfService_seoData';
        title: string | null;
        description: string | null;
        noIndex: boolean | null;
        noFollow: boolean | null;
        url: string | null;
      } | null;
      address: {
        __typename?: 'Address';
        fax: string | null;
        phone: string | null;
        email: string | null;
        firstName: string | null;
        lastName: string | null;
        postalCode: string | null;
        town: string | null;
        streetname: string | null;
        streetnumber: string | null;
      } | null;
      bigPicture: { __typename?: 'Image'; altText: string | null; url: string | null } | null;
      geoPoint: {
        __typename?: 'GeoPoint';
        latitude: number | null;
        longitude: number | null;
      } | null;
      services: Array<{
        __typename?: 'PosService';
        code: string | null;
        name: string | null;
        icon: { __typename?: 'Image'; altText: string | null; url: string | null } | null;
      }> | null;
    }> | null;
  } | null;
};

export type OpeningDayFragment = {
  __typename?: 'OpeningDay';
  closed: boolean | null;
  code: string | null;
  weekDay: string | null;
  formattedDate: string | null;
  closingTime: { __typename?: 'OpeningHours'; formattedHour: string | null } | null;
  openingTime: { __typename?: 'OpeningHours'; formattedHour: string | null } | null;
};

export type GetSubsidiaryQueryVariables = Exact<{
  subsidiaryId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetSubsidiaryQuery = {
  __typename?: 'Query';
  getPointOfServices: {
    __typename?: 'PointOfServices';
    pointOfServices: Array<{
      __typename?: 'PointOfService';
      code: string | null;
      longDescription: string | null;
      name: string | null;
      dailyMenu: string | null;
      restaurantDescription: string | null;
      restaurantInfo: string | null;
      openingHoursText: string | null;
      specialOpeningHours: string | null;
      restaurantOpeningHours: string | null;
      regularOpeningHours: string | null;
      timendoGroupId: string | null;
      type: string | null;
      seoData: {
        __typename?: 'PointOfService_seoData';
        title: string | null;
        description: string | null;
        noIndex: boolean | null;
        noFollow: boolean | null;
        url: string | null;
      } | null;
      address: {
        __typename?: 'Address';
        fax: string | null;
        phone: string | null;
        email: string | null;
        firstName: string | null;
        lastName: string | null;
        postalCode: string | null;
        town: string | null;
        streetname: string | null;
        streetnumber: string | null;
      } | null;
      attachedPointOfServices: Array<{
        __typename?: 'PointOfService';
        code: string | null;
        name: string | null;
        openingHoursText: string | null;
        restType: string | null;
        type: string | null;
        address: {
          __typename?: 'Address';
          defaultAddress: boolean | null;
          fax: string | null;
          firstName: string | null;
          formattedAddress: string | null;
          id: string | null;
          lastName: string | null;
          lift: boolean | null;
          line1: string | null;
          line2: string | null;
          phone: string | null;
          postalCode: string | null;
          restType: string | null;
          shippingAddress: boolean | null;
          streetname: string | null;
          streetnumber: string | null;
          town: string | null;
          visibleInAddressBook: boolean | null;
          country: {
            __typename?: 'Country';
            isocode: string | null;
            name: string | null;
            restType: string | null;
          } | null;
          region: {
            __typename?: 'Region';
            countryIso: string | null;
            isocode: string | null;
            name: string | null;
            restType: string | null;
          } | null;
        } | null;
        geoPoint: {
          __typename?: 'GeoPoint';
          latitude: number | null;
          longitude: number | null;
          restType: string | null;
        } | null;
      }> | null;
      bigPicture: {
        __typename?: 'Image';
        altText: string | null;
        cdnFilename: string | null;
        filename: string | null;
        fileType: string | null;
        hashCode: string | null;
        pictureHeight: number | null;
        pictureWidth: number | null;
        url: string | null;
      } | null;
      geoPoint: {
        __typename?: 'GeoPoint';
        latitude: number | null;
        longitude: number | null;
      } | null;
      openingHours: {
        __typename?: 'OpeningSchedule';
        regularOpeningDays: Array<{
          __typename?: 'OpeningDay';
          closed: boolean | null;
          code: string | null;
          weekDay: string | null;
          formattedDate: string | null;
          closingTime: { __typename?: 'OpeningHours'; formattedHour: string | null } | null;
          openingTime: { __typename?: 'OpeningHours'; formattedHour: string | null } | null;
        }> | null;
        restaurantOpeningDays: Array<{
          __typename?: 'OpeningDay';
          closed: boolean | null;
          code: string | null;
          weekDay: string | null;
          formattedDate: string | null;
          closingTime: { __typename?: 'OpeningHours'; formattedHour: string | null } | null;
          openingTime: { __typename?: 'OpeningHours'; formattedHour: string | null } | null;
        }> | null;
        specialOpeningDays: Array<{
          __typename?: 'OpeningDay';
          closed: boolean | null;
          code: string | null;
          weekDay: string | null;
          formattedDate: string | null;
          closingTime: { __typename?: 'OpeningHours'; formattedHour: string | null } | null;
          openingTime: { __typename?: 'OpeningHours'; formattedHour: string | null } | null;
        }> | null;
      } | null;
      services: Array<{
        __typename?: 'PosService';
        code: string | null;
        name: string | null;
        iconName: string | null;
        url: string | null;
        icon: { __typename?: 'Image'; altText: string | null; url: string | null } | null;
      }> | null;
    }> | null;
  } | null;
};

export const AddressFragmentDoc = gql`
  fragment address on Address {
    addition1
    companyName
    country {
      isocode
      name
    }
    dateOfBirth
    defaultAddress
    email
    fax
    firstName
    floor
    formattedAddress
    gender
    id
    lastName
    lift
    line1
    line2
    phone
    phone2
    postalCode
    pricepassNr
    region {
      countryIso
      isocode
      isocodeShort
      name
    }
    restType
    salesTaxNumbers {
      number
      rank
    }
    shippingAddress
    streetname
    streetnumber
    title
    titleCode
    town
    uid
    visibleInAddressBook
  }
`;
export const CoreFacetFragmentDoc = gql`
  fragment coreFacet on CoreFacet {
    __typename
    filterId
    id
    title
  }
`;
export const MultiSelectColorValuesFragmentDoc = gql`
  fragment multiSelectColorValues on MultiSelectColorValues {
    __typename
    count
    id
    title
    valueId
  }
`;
export const MultiSelectValuesFragmentDoc = gql`
  fragment multiSelectValues on MultiSelectValues {
    __typename
    count
    id
    title
    valueId
  }
`;
export const SubsidiaryFragmentDoc = gql`
  fragment subsidiary on SubsidiaryInformation {
    displayInformation {
      displayed
      location {
        department
        floor
        formattedFloorText
      }
    }
    distance
    pointOfService {
      address {
        postalCode
        streetname
        streetnumber
        town
        formattedAddress
      }
      code
      name
      geoPoint {
        latitude
        longitude
      }
      seoData {
        url
      }
    }
    reservable
    selfService
    shippingInformation {
      deliveryTime
      deliveryTimeText
      shippingType
    }
  }
`;
export const ConfiguredProductFragmentDoc = gql`
  fragment configuredProduct on Product {
    configuredProduct
    configuredProductData {
      attachments {
        altText
        url
      }
      images {
        altText
        url
      }
      configuredId
      productNumber
      description
      name
      restType
      summary
    }
  }
`;
export const DimensionsFragmentDoc = gql`
  fragment dimensions on Dimensions {
    dimensions {
      type
      value
    }
  }
`;
export const PriceFragmentDoc = gql`
  fragment price on Price {
    currencyIso
    value
  }
`;
export const ReducedPriceDataFragmentDoc = gql`
  fragment reducedPriceData on ProductPriceData {
    currentPrice {
      ...price
    }
    oldPrice {
      value
    }
    currentNetPrice {
      value
    }
    oldNetPrice {
      value
    }
    last30DaysBestPrice {
      value
    }
    shippingCost {
      value
    }
    postCost {
      value
    }
    savedPercent
    productUnitDiffersToPriceUnit
  }
  ${PriceFragmentDoc}
`;
export const CommonPriceDataFragmentDoc = gql`
  fragment commonPriceData on ProductPriceData {
    ...reducedPriceData
    deliveryCost {
      value
    }
    offerAttributes {
      offerId
    }
    postCost {
      value
    }
    pricePerUnit {
      value
    }
    shippingCost {
      value
    }
    hideUvp
    sellingUnit
    sellingAmount
    productUnitDiffersToPriceUnit
  }
  ${ReducedPriceDataFragmentDoc}
`;
export const FavouriteProductFragmentDoc = gql`
  fragment favouriteProduct on Product {
    type
    availabilityStatus
    baseProduct
    additionalProductExpenses {
      price
      type
      includedInProductPrice
    }
    attributes {
      dimensions {
        ...dimensions
        strRepresentation
        strRepresentationKey
      }
      material
    }
    mediaData {
      presentation {
        cdnFilename
        altText
        realFilename
        fileType
      }
      datasheets {
        url
        cdnFilename
        downloadName
        documentType
      }
    }
    code
    energyEfficiencyData {
      classColor
      eeClass
      datasheet {
        url
        realFilename
      }
      energyLabel2021Shown
      label {
        altText
        cdnFilename
        filename
        cdnFilename
      }
    }
    color
    name
    picture {
      altText
      cdnFilename
    }
    orderData {
      buyable
    }
    price {
      value
      specialOfferTypeData {
        numberOfAsterisks
      }
    }
    priceData {
      ...commonPriceData
      currentPrice {
        specialOfferTypeData {
          numberOfAsterisks
        }
      }
    }
    statisticKPI
    url
  }
  ${DimensionsFragmentDoc}
  ${CommonPriceDataFragmentDoc}
`;
export const FavouriteFragmentDoc = gql`
  fragment favourite on FavouritesList {
    id
    name
    entries {
      id
      item {
        code
        entryNumber
        product {
          ...favouriteProduct
        }
        quantity
        type
      }
    }
  }
  ${FavouriteProductFragmentDoc}
`;
export const FavouriteWithDefaultFragmentDoc = gql`
  fragment favouriteWithDefault on FavouritesList {
    id
    name
    defaultList
    entries {
      id
      item {
        code
        entryNumber
        product {
          ...favouriteProduct
        }
        quantity
        type
      }
    }
  }
  ${FavouriteProductFragmentDoc}
`;
export const PromotionResultFragmentDoc = gql`
  fragment promotionResult on PromotionResult {
    consumedEntries {
      adjustedUnitPrice
      orderEntryNumber
      reductionValue
      quantity
    }
    description
    id
    name
    productPriceReduction
    promotion {
      code
    }
    promotionDiscount {
      ...price
    }
    valueDisplayed
  }
  ${PriceFragmentDoc}
`;
export const DeliveryAddressFragmentDoc = gql`
  fragment deliveryAddress on Address {
    addition1
    addressId
    email
    firstName
    floor
    gender
    lastName
    lift
    line1
    line2
    phone
    postalCode
    pricepassNr
    regionIsoCode
    region {
      isocode
      name
    }
    streetname
    streetnumber
    titleCode
    town
  }
`;
export const PaymentModeFragmentDoc = gql`
  fragment paymentMode on PaymentMode {
    active
    code
    paymentModeId
    description
    logo {
      altText
      cdnFilename
      fileExtension
      format
      mediaProvider
      mime
      pictureHeight
      pictureWidth
      realFilename
      seoText
      url
    }
    mobileDescription
    name
    paymentCost {
      currencyIso
      value
    }
    pgProvider
    pspGroupedCode
    redirectHint
    redirectMessage
    redirectPageHeader
  }
`;
export const PointOfServiceFragmentDoc = gql`
  fragment pointOfService on PointOfService {
    name
    code
    address {
      formattedAddress
    }
    description
  }
`;
export const DeliveryModesFragmentDoc = gql`
  fragment deliveryModes on OrderEntry {
    deliveryModes {
      assemblyCost
      code
      customShippingType
      deliveryCost {
        value
      }
      deliveryCostSpecial {
        value
      }
      deliveryCostSpecialType
      deliveryTime
      description
      deliveryTimeText
      isFreeDeliveryCost
      name
      selfServiceStores {
        address {
          formattedAddress
          line1
          line2
          phone
          postalCode
          region {
            countryIso
            isocode
            name
          }
          streetname
          streetnumber
          town
        }
        code
        openingHoursText
        name
        geoPoint {
          latitude
          longitude
        }
        seoData {
          canonicalUrl
          title
          description
          noIndex
          noFollow
          url
        }
      }
    }
    deliveryMode {
      code
      description
      name
    }
  }
`;
export const BreadcrumbsFragmentDoc = gql`
  fragment breadcrumbs on Breadcrumb {
    itemCode
    seoUrl
    name
    type
  }
`;
export const MediaDataFragmentDoc = gql`
  fragment mediaData on ProductMediaData {
    presentation {
      cdnFilename
      altText
      realFilename
      fileType
    }
    datasheets {
      url
      cdnFilename
      downloadName
      documentType
    }
  }
`;
export const EnergyEfficiencyDataFragmentDoc = gql`
  fragment energyEfficiencyData on EnergyEfficiencyData {
    classColor
    eeClass
    datasheet {
      url
      realFilename
    }
    energyLabel2021Shown
    label {
      altText
      cdnFilename
      filename
      cdnFilename
    }
  }
`;
export const BasicProductFragmentDoc = gql`
  fragment basicProduct on Product {
    baseProduct
    deliveryData {
      deliveryCostsCharged
      assembly
      bulkyProduct
      customDeliveryType
      defaultDeliveryTimeRetrievalEnabled
      delivery
      deliveryCostsCharged
      deliveryIndicator
      fastDeliveryShown
    }
    characteristicData {
      colorMapping {
        targetColor
        webColor
      }
      detailAttributes {
        attributes {
          code
          name
          value
        }
      }
    }
    breadcrumbs {
      ...breadcrumbs
    }
    code
    mediaData {
      ...mediaData
    }
    energyEfficiencyData {
      ...energyEfficiencyData
    }
    eyecatchers {
      code
      name
    }
    style {
      code
      name
    }
    brands {
      code
      image {
        altText
        filename
        pictureHeight
        pictureWidth
      }
      name
      url
      urlCode
    }
    attributes {
      colorMapping {
        code
        targetColor
        webColor
      }
      dimensions {
        ...dimensions
        strRepresentation
        strRepresentationKey
      }
      material
    }
    name
    picture {
      altText
      cdnFilename
    }
    price {
      specialOfferTypeData {
        numberOfAsterisks
      }
      value
    }
    priceData {
      ...commonPriceData
      currentPrice {
        endTime
        specialOfferTypeData {
          numberOfAsterisks
        }
      }
    }
    reservationData {
      maxReservableAmount
      reservable
    }
    color
    url
  }
  ${BreadcrumbsFragmentDoc}
  ${MediaDataFragmentDoc}
  ${EnergyEfficiencyDataFragmentDoc}
  ${DimensionsFragmentDoc}
  ${CommonPriceDataFragmentDoc}
`;
export const ServicesFragmentDoc = gql`
  fragment services on OrderEntry {
    services {
      code
      consumed
      price
      restType
      totalPrice
      type
    }
  }
`;
export const MessagesFragmentDoc = gql`
  fragment messages on MessageData {
    arguments {
      type
      value
    }
    message
  }
`;
export const PaymentAddressFragmentDoc = gql`
  fragment paymentAddress on Address {
    addressId
    companyName
    country {
      isocode
      name
    }
    countryIsoCode
    email
    firstName
    floor
    gender
    lastName
    lift
    line1
    line2
    phone
    postalCode
    pricepassNr
    regionIsoCode
    region {
      isocode
      name
    }
    salesTaxNumbers {
      number
    }
    streetname
    streetnumber
    titleCode
    town
  }
`;
export const OrderFragmentDoc = gql`
  fragment order on Order {
    code
    appliedOrderPromotions {
      ...promotionResult
      removable
      usedCouponCodes
    }
    appliedProductPromotions {
      ...promotionResult
      removable
      usedCouponCodes
    }
    appliedVoucherDataList {
      appliedValue {
        ...price
      }
      applied
      code
      currency {
        name
      }
      description
      freeShipping
      overflowAmount
      promotionCode
      name
      value
      violationMessage
      restrictions {
        type
        ... on ProductQuantityRestriction {
          unit
          quantity
        }
        ... on ProductGroupRestriction {
          categoryMappingCodesList
          included
        }
        ... on ProductRestriction {
          products {
            code
          }
        }
        ... on ProductBrandRestriction {
          brands {
            code
          }
        }
        ... on ProductConfiguredRestriction {
          configuredOnly
        }
        ... on StatisticIdentifierRestriction {
          statisticIdentifier
        }
        ... on ProductEyecatcherRestriction {
          eyecatchers {
            code
          }
        }
        ... on ProductCategoryRestriction {
          categories {
            code
          }
        }
      }
      voucherType
    }
    code
    currency {
      isocode
    }
    deliveryAddress {
      ...deliveryAddress
    }
    paymentMode {
      ...paymentMode
    }
    paymentModes {
      active
      code
      description
      logo {
        altText
        cdnFilename
        fileExtension
        format
        mediaProvider
        mime
        pictureHeight
        pictureWidth
        realFilename
        seoText
        url
      }
    }
    entries {
      pointOfService {
        ...pointOfService
      }
      ...deliveryModes
      deliveryTime
      entryNumber
      product {
        ...basicProduct
      }
      quantity
      totalPrice {
        ...price
      }
      selectedDeliveryMode
      selectedQuantity
      ...services
      unitBasedCalculation {
        deliveryAmount
        oldPriceTotal {
          currencyIso
          formattedValue
          priceType
          value
        }
        packageAmount
        selectedDeliveryAmount
        amount
      }
      updateable
    }
    guid
    messages {
      ...messages
    }
    paymentAddress {
      ...paymentAddress
    }
    deliveryCost {
      value
    }
    deliveryCostAssembling {
      value
    }
    deliveryCostWithoutSpecial {
      value
    }
    orderType
    saveAddresses
    subOrders {
      code
      selectedStore {
        code
        name
        seoData {
          url
        }
      }
      totalPriceWithTax {
        currencyIso
        value
      }
    }
    subTotal {
      ...price
    }
    totalItems
    totalPrice {
      ...price
    }
    totalPriceWithTax {
      ...price
    }
    totalTax {
      ...price
    }
    totalPriceWithoutCoupons {
      ...price
    }
    totalNetPriceWithoutCoupons {
      ...price
    }
    voucherSum {
      ...price
    }
  }
  ${PromotionResultFragmentDoc}
  ${PriceFragmentDoc}
  ${DeliveryAddressFragmentDoc}
  ${PaymentModeFragmentDoc}
  ${PointOfServiceFragmentDoc}
  ${DeliveryModesFragmentDoc}
  ${BasicProductFragmentDoc}
  ${ServicesFragmentDoc}
  ${MessagesFragmentDoc}
  ${PaymentAddressFragmentDoc}
`;
export const ProductConfiguratorResponseFragmentDoc = gql`
  fragment productConfiguratorResponse on ProductConfigurationResponse {
    openType
    restType
    url
  }
`;
export const CustomerDetailsFragmentDoc = gql`
  fragment customerDetails on CustomerDetails {
    salutation
    title
    firstname
    lastname
    birthdate
    phone
    created
  }
`;
export const AddressDataFragmentDoc = gql`
  fragment addressData on AddressData {
    id
    maddress
    saddress
    baddress
    gender
    titleCode
    firstName
    lastName
    email
    phone
    companyName
    attn
    salesTaxNumbers {
      id
      number
      rank
      created
    }
    streetname
    streetnumber
    floor
    lift
    postalCode
    town
    country
    addition1
    created
  }
`;
export const ProductCampaignAndListingDataFragmentDoc = gql`
  fragment productCampaignAndListingData on Product {
    availabilityStatus
    averageRating
    name
    numberOfReviews
    url
    code
    firstItemOfPage
    hasRatings
    legalInformationShown
    color
    eyecatcherABIsShown
    freeDelivery
    attributes {
      colorMapping {
        code
        targetColor
        webColor
      }
      dimensions {
        ...dimensions
        strRepresentation
        strRepresentationKey
      }
      material
    }
    priceData {
      ...commonPriceData
      currentPrice {
        specialOfferTypeData {
          numberOfAsterisks
        }
      }
    }
    configurable
    energyEfficiencyData {
      ...energyEfficiencyData
    }
    mediaData {
      presentation {
        altText
        cdnFilename
        realFilename
        seoText
        fileType
      }
      datasheets {
        url
        realFilename
        documentType
      }
    }
    brands {
      name
    }
    eyecatchers {
      code
      style
    }
    orderData {
      buyable
    }
    style {
      code
      name
    }
    statisticKPI
    variantContainerData {
      variantHighlightType
      variantGroups {
        name
        variantValues {
          enabled
          product {
            availabilityStatus
            code
            color
            name
            url
            priceData {
              ...commonPriceData
              currentPrice {
                specialOfferTypeData {
                  numberOfAsterisks
                }
              }
            }
          }
          productPicture {
            altText
            cdnFilename
            hashCode
            fileType
            filename
            fileExtension
          }
          restType
          selected
          value
        }
      }
    }
    hotdealData {
      assignedStore {
        name
      }
    }
  }
  ${DimensionsFragmentDoc}
  ${CommonPriceDataFragmentDoc}
  ${EnergyEfficiencyDataFragmentDoc}
`;
export const SeoDataFragmentDoc = gql`
  fragment seoData on Seo {
    baseStoreRelatedItems {
      locale
      url
    }
    canonicalUrl
    title
    url
    description
    keywords
    name
    noFollow
    noIndex
  }
`;
export const BrandsFragmentDoc = gql`
  fragment brands on Brand {
    code
    image {
      altText
      filename
      fileExtension
      pictureHeight
      pictureWidth
    }
    showBrandnameInPdpTitle
    name
    url
    urlCode
  }
`;
export const CurrentPriceFragmentDoc = gql`
  fragment currentPrice on Price {
    endTime
    value
    currencyIso
    specialOfferTypeData {
      numberOfAsterisks
    }
  }
`;
export const HotDealDataFragmentDoc = gql`
  fragment hotDealData on HotDealData {
    assignedStore {
      name
      regularOpeningHours
      code
      address {
        email
        firstName
        lastName
        phone
        postalCode
        streetname
        streetnumber
        town
      }
    }
    itemNumber
  }
`;
export const RatingStatisticFragmentDoc = gql`
  fragment ratingStatistic on RatingStatistic {
    ratingCount
    ratingValue
    ratingStatisticPerRatingValue {
      ratingValue
      ratingValueCount
    }
  }
`;
export const PaginationFragmentDoc = gql`
  fragment pagination on ReviewList {
    pagination {
      currentPage
      hasNextPage
      hasPreviousPage
      pageSize
      totalPages
      totalResults
    }
  }
`;
export const DimensionalRatingStatisticDataFragmentDoc = gql`
  fragment dimensionalRatingStatisticData on ReviewList {
    dimensionalRatingStatisticData {
      ratingValue
      ratingValueType
      ratingStatisticPerRatingValue {
        ratingValue
        ratingValueCount
      }
    }
  }
`;
export const DimensionTypesFragmentDoc = gql`
  fragment dimensionTypes on ReviewList {
    dimensionTypes {
      type
      value
    }
  }
`;
export const ReviewsFragmentDoc = gql`
  fragment reviews on ReviewList {
    reviews {
      authorAlias
      authorType
      body
      created
      countryCrossedData {
        language
        tld
      }
      verifiedAuthor
      variantData {
        name
        title
        value
      }
      dimensionalRatingValues {
        type
        value
      }
      id
      ratingValue
      title
      replies {
        alias
        createdTS
        message
      }
    }
  }
`;
export const OpeningDayFragmentDoc = gql`
  fragment OpeningDay on OpeningDay {
    closingTime {
      formattedHour
    }
    openingTime {
      formattedHour
    }
    closed
    code
    weekDay
    formattedDate
  }
`;
export const CampaignsDocument = gql`
  query campaigns($cmsPageCode: String!, $productCode: String, $include: Boolean!) {
    getFHCampaigns(cmsPageCode: $cmsPageCode, productCode: $productCode) @include(if: $include) {
      restType
      type
      id
      fhPreview {
        showPreviewButton
        url
      }
      campaigns {
        restType
        id
        title
        moreItemsLink
        provider
        widgetId
        recommendationId
        searchResult {
          pagination {
            totalResults
          }
        }
        products {
          id
          type
          ... on Product {
            baseProduct
            ...productCampaignAndListingData
          }
          ... on Campaign {
            title
            searchResult {
              searchResults {
                columnSpan
                type
                links {
                  url
                  alternativeText
                  openInNewWindow
                }
                media {
                  altText
                  fileType
                  seoText
                  url
                  cdnFilename
                  realFilename
                }
              }
            }
          }
        }
      }
    }
  }
  ${ProductCampaignAndListingDataFragmentDoc}
`;

/**
 * __useCampaignsQuery__
 *
 * To run a query within a React component, call `useCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsQuery({
 *   variables: {
 *      cmsPageCode: // value for 'cmsPageCode'
 *      productCode: // value for 'productCode'
 *      include: // value for 'include'
 *   },
 * });
 */
export function useCampaignsQuery(
  baseOptions: Apollo.QueryHookOptions<CampaignsQuery, CampaignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
}
export function useCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CampaignsQuery, CampaignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
}
export type CampaignsQueryHookResult = ReturnType<typeof useCampaignsQuery>;
export type CampaignsLazyQueryHookResult = ReturnType<typeof useCampaignsLazyQuery>;
export type CampaignsQueryResult = Apollo.QueryResult<CampaignsQuery, CampaignsQueryVariables>;
export const GetAiRecommendationsDocument = gql`
  query getAIRecommendations(
    $productCode: String!
    $recommendationsParams: AIRecommendationsParams
  ) {
    getAIRecommendations(productCode: $productCode, recommendationsParams: $recommendationsParams) {
      restType
      products {
        id
        type
        source
        originalresponse
        ... on Product {
          baseProduct
          orderData {
            buyable
          }
          ...productCampaignAndListingData
        }
      }
    }
  }
  ${ProductCampaignAndListingDataFragmentDoc}
`;

/**
 * __useGetAiRecommendationsQuery__
 *
 * To run a query within a React component, call `useGetAiRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAiRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAiRecommendationsQuery({
 *   variables: {
 *      productCode: // value for 'productCode'
 *      recommendationsParams: // value for 'recommendationsParams'
 *   },
 * });
 */
export function useGetAiRecommendationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAiRecommendationsQuery,
    GetAiRecommendationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAiRecommendationsQuery, GetAiRecommendationsQueryVariables>(
    GetAiRecommendationsDocument,
    options,
  );
}
export function useGetAiRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAiRecommendationsQuery,
    GetAiRecommendationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAiRecommendationsQuery, GetAiRecommendationsQueryVariables>(
    GetAiRecommendationsDocument,
    options,
  );
}
export type GetAiRecommendationsQueryHookResult = ReturnType<typeof useGetAiRecommendationsQuery>;
export type GetAiRecommendationsLazyQueryHookResult = ReturnType<
  typeof useGetAiRecommendationsLazyQuery
>;
export type GetAiRecommendationsQueryResult = Apollo.QueryResult<
  GetAiRecommendationsQuery,
  GetAiRecommendationsQueryVariables
>;
export const ContentPagesDocument = gql`
  query contentPages($code: String!) {
    getContentPage(code: $code) {
      uid
      code
      name
      restType
      title
      name
      restType
      seoData {
        canonicalUrl
        code
        description
        keywords
        name
        noFollow
        noIndex
        seoPath
        title
        url
      }
    }
  }
`;

/**
 * __useContentPagesQuery__
 *
 * To run a query within a React component, call `useContentPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentPagesQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useContentPagesQuery(
  baseOptions: Apollo.QueryHookOptions<ContentPagesQuery, ContentPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContentPagesQuery, ContentPagesQueryVariables>(
    ContentPagesDocument,
    options,
  );
}
export function useContentPagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContentPagesQuery, ContentPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContentPagesQuery, ContentPagesQueryVariables>(
    ContentPagesDocument,
    options,
  );
}
export type ContentPagesQueryHookResult = ReturnType<typeof useContentPagesQuery>;
export type ContentPagesLazyQueryHookResult = ReturnType<typeof useContentPagesLazyQuery>;
export type ContentPagesQueryResult = Apollo.QueryResult<
  ContentPagesQuery,
  ContentPagesQueryVariables
>;
export const GetProductConfiguratorPdfDocument = gql`
  query getProductConfiguratorPdf($code: String!, $attachment: String!, $skip: Boolean! = true) {
    getProductConfiguratorPdf(code: $code, attachment: $attachment) @skip(if: $skip)
  }
`;

/**
 * __useGetProductConfiguratorPdfQuery__
 *
 * To run a query within a React component, call `useGetProductConfiguratorPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductConfiguratorPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductConfiguratorPdfQuery({
 *   variables: {
 *      code: // value for 'code'
 *      attachment: // value for 'attachment'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetProductConfiguratorPdfQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductConfiguratorPdfQuery,
    GetProductConfiguratorPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductConfiguratorPdfQuery, GetProductConfiguratorPdfQueryVariables>(
    GetProductConfiguratorPdfDocument,
    options,
  );
}
export function useGetProductConfiguratorPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductConfiguratorPdfQuery,
    GetProductConfiguratorPdfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductConfiguratorPdfQuery,
    GetProductConfiguratorPdfQueryVariables
  >(GetProductConfiguratorPdfDocument, options);
}
export type GetProductConfiguratorPdfQueryHookResult = ReturnType<
  typeof useGetProductConfiguratorPdfQuery
>;
export type GetProductConfiguratorPdfLazyQueryHookResult = ReturnType<
  typeof useGetProductConfiguratorPdfLazyQuery
>;
export type GetProductConfiguratorPdfQueryResult = Apollo.QueryResult<
  GetProductConfiguratorPdfQuery,
  GetProductConfiguratorPdfQueryVariables
>;
export const CountryOverlayDocument = gql`
  query countryOverlay($ip: String) {
    getCountryOverlay(ip: $ip) {
      code
      contentSlots
    }
  }
`;

/**
 * __useCountryOverlayQuery__
 *
 * To run a query within a React component, call `useCountryOverlayQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryOverlayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryOverlayQuery({
 *   variables: {
 *      ip: // value for 'ip'
 *   },
 * });
 */
export function useCountryOverlayQuery(
  baseOptions?: Apollo.QueryHookOptions<CountryOverlayQuery, CountryOverlayQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountryOverlayQuery, CountryOverlayQueryVariables>(
    CountryOverlayDocument,
    options,
  );
}
export function useCountryOverlayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountryOverlayQuery, CountryOverlayQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountryOverlayQuery, CountryOverlayQueryVariables>(
    CountryOverlayDocument,
    options,
  );
}
export type CountryOverlayQueryHookResult = ReturnType<typeof useCountryOverlayQuery>;
export type CountryOverlayLazyQueryHookResult = ReturnType<typeof useCountryOverlayLazyQuery>;
export type CountryOverlayQueryResult = Apollo.QueryResult<
  CountryOverlayQuery,
  CountryOverlayQueryVariables
>;
export const GetCountryRegionsDocument = gql`
  query getCountryRegions {
    countries {
      isocode
      name
      restType
    }
  }
`;

/**
 * __useGetCountryRegionsQuery__
 *
 * To run a query within a React component, call `useGetCountryRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountryRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCountryRegionsQuery, GetCountryRegionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCountryRegionsQuery, GetCountryRegionsQueryVariables>(
    GetCountryRegionsDocument,
    options,
  );
}
export function useGetCountryRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountryRegionsQuery,
    GetCountryRegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCountryRegionsQuery, GetCountryRegionsQueryVariables>(
    GetCountryRegionsDocument,
    options,
  );
}
export type GetCountryRegionsQueryHookResult = ReturnType<typeof useGetCountryRegionsQuery>;
export type GetCountryRegionsLazyQueryHookResult = ReturnType<typeof useGetCountryRegionsLazyQuery>;
export type GetCountryRegionsQueryResult = Apollo.QueryResult<
  GetCountryRegionsQuery,
  GetCountryRegionsQueryVariables
>;
export const DistrictsDocument = gql`
  query districts($regionIsoCode: String!) {
    districts(regionIsoCode: $regionIsoCode) {
      id
      municipality
      restType
      town
    }
  }
`;

/**
 * __useDistrictsQuery__
 *
 * To run a query within a React component, call `useDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictsQuery({
 *   variables: {
 *      regionIsoCode: // value for 'regionIsoCode'
 *   },
 * });
 */
export function useDistrictsQuery(
  baseOptions: Apollo.QueryHookOptions<DistrictsQuery, DistrictsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DistrictsQuery, DistrictsQueryVariables>(DistrictsDocument, options);
}
export function useDistrictsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DistrictsQuery, DistrictsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DistrictsQuery, DistrictsQueryVariables>(DistrictsDocument, options);
}
export type DistrictsQueryHookResult = ReturnType<typeof useDistrictsQuery>;
export type DistrictsLazyQueryHookResult = ReturnType<typeof useDistrictsLazyQuery>;
export type DistrictsQueryResult = Apollo.QueryResult<DistrictsQuery, DistrictsQueryVariables>;
export const GetRegionsDocument = gql`
  query getRegions {
    regions {
      isocode
      name
      restType
    }
  }
`;

/**
 * __useGetRegionsQuery__
 *
 * To run a query within a React component, call `useGetRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegionsQuery, GetRegionsQueryVariables>(GetRegionsDocument, options);
}
export function useGetRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegionsQuery, GetRegionsQueryVariables>(
    GetRegionsDocument,
    options,
  );
}
export type GetRegionsQueryHookResult = ReturnType<typeof useGetRegionsQuery>;
export type GetRegionsLazyQueryHookResult = ReturnType<typeof useGetRegionsLazyQuery>;
export type GetRegionsQueryResult = Apollo.QueryResult<GetRegionsQuery, GetRegionsQueryVariables>;
export const NavigationDocument = gql`
  query navigation($codes: [String!], $levelAmount: Int, $levelOffset: Int) {
    navigation(codes: $codes, levelAmount: $levelAmount, levelOffset: $levelOffset) {
      data
    }
  }
`;

/**
 * __useNavigationQuery__
 *
 * To run a query within a React component, call `useNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationQuery({
 *   variables: {
 *      codes: // value for 'codes'
 *      levelAmount: // value for 'levelAmount'
 *      levelOffset: // value for 'levelOffset'
 *   },
 * });
 */
export function useNavigationQuery(
  baseOptions?: Apollo.QueryHookOptions<NavigationQuery, NavigationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NavigationQuery, NavigationQueryVariables>(NavigationDocument, options);
}
export function useNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NavigationQuery, NavigationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NavigationQuery, NavigationQueryVariables>(
    NavigationDocument,
    options,
  );
}
export type NavigationQueryHookResult = ReturnType<typeof useNavigationQuery>;
export type NavigationLazyQueryHookResult = ReturnType<typeof useNavigationLazyQuery>;
export type NavigationQueryResult = Apollo.QueryResult<NavigationQuery, NavigationQueryVariables>;
export const SearchtermDocument = gql`
  query searchterm {
    searchBar @client {
      term
      suggest
    }
  }
`;

/**
 * __useSearchtermQuery__
 *
 * To run a query within a React component, call `useSearchtermQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchtermQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchtermQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchtermQuery(
  baseOptions?: Apollo.QueryHookOptions<SearchtermQuery, SearchtermQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchtermQuery, SearchtermQueryVariables>(SearchtermDocument, options);
}
export function useSearchtermLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchtermQuery, SearchtermQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchtermQuery, SearchtermQueryVariables>(
    SearchtermDocument,
    options,
  );
}
export type SearchtermQueryHookResult = ReturnType<typeof useSearchtermQuery>;
export type SearchtermLazyQueryHookResult = ReturnType<typeof useSearchtermLazyQuery>;
export type SearchtermQueryResult = Apollo.QueryResult<SearchtermQuery, SearchtermQueryVariables>;
export const GetMetaNavigationDocument = gql`
  query getMetaNavigation {
    getMetaNavigation {
      promotionNodes {
        link {
          id
          linkName
          name
          target
          url
        }
        iconName
      }
      serviceNodes {
        iconName
        link {
          id
          linkName
          name
          target
          url
        }
      }
      sidebarMenuNodes {
        iconName
        link {
          id
          linkName
          name
          target
          url
        }
      }
    }
  }
`;

/**
 * __useGetMetaNavigationQuery__
 *
 * To run a query within a React component, call `useGetMetaNavigationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetaNavigationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetaNavigationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMetaNavigationQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMetaNavigationQuery, GetMetaNavigationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMetaNavigationQuery, GetMetaNavigationQueryVariables>(
    GetMetaNavigationDocument,
    options,
  );
}
export function useGetMetaNavigationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMetaNavigationQuery,
    GetMetaNavigationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMetaNavigationQuery, GetMetaNavigationQueryVariables>(
    GetMetaNavigationDocument,
    options,
  );
}
export type GetMetaNavigationQueryHookResult = ReturnType<typeof useGetMetaNavigationQuery>;
export type GetMetaNavigationLazyQueryHookResult = ReturnType<typeof useGetMetaNavigationLazyQuery>;
export type GetMetaNavigationQueryResult = Apollo.QueryResult<
  GetMetaNavigationQuery,
  GetMetaNavigationQueryVariables
>;
export const SubscribeNewsletterDocument = gql`
  mutation subscribeNewsletter(
    $email: String
    $firstName: String
    $lastName: String
    $sourceId: String
    $timendoArea: String
  ) {
    subscribeNewsletter(
      email: $email
      firstName: $firstName
      lastName: $lastName
      sourceId: $sourceId
      timendoArea: $timendoArea
    )
  }
`;
export type SubscribeNewsletterMutationFn = Apollo.MutationFunction<
  SubscribeNewsletterMutation,
  SubscribeNewsletterMutationVariables
>;

/**
 * __useSubscribeNewsletterMutation__
 *
 * To run a mutation, you first call `useSubscribeNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeNewsletterMutation, { data, loading, error }] = useSubscribeNewsletterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      sourceId: // value for 'sourceId'
 *      timendoArea: // value for 'timendoArea'
 *   },
 * });
 */
export function useSubscribeNewsletterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeNewsletterMutation,
    SubscribeNewsletterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables>(
    SubscribeNewsletterDocument,
    options,
  );
}
export type SubscribeNewsletterMutationHookResult = ReturnType<
  typeof useSubscribeNewsletterMutation
>;
export type SubscribeNewsletterMutationResult = Apollo.MutationResult<SubscribeNewsletterMutation>;
export type SubscribeNewsletterMutationOptions = Apollo.BaseMutationOptions<
  SubscribeNewsletterMutation,
  SubscribeNewsletterMutationVariables
>;
export const SubscribeNewsletterCdmDocument = gql`
  mutation subscribeNewsletterCDM(
    $email: String!
    $time: String!
    $firstName: String
    $lastName: String
    $socialAndPartnersConsent: Boolean
  ) {
    subscribeNewsletterCDM(
      email: $email
      time: $time
      firstName: $firstName
      lastName: $lastName
      socialAndPartnersConsent: $socialAndPartnersConsent
    )
  }
`;
export type SubscribeNewsletterCdmMutationFn = Apollo.MutationFunction<
  SubscribeNewsletterCdmMutation,
  SubscribeNewsletterCdmMutationVariables
>;

/**
 * __useSubscribeNewsletterCdmMutation__
 *
 * To run a mutation, you first call `useSubscribeNewsletterCdmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeNewsletterCdmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeNewsletterCdmMutation, { data, loading, error }] = useSubscribeNewsletterCdmMutation({
 *   variables: {
 *      email: // value for 'email'
 *      time: // value for 'time'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      socialAndPartnersConsent: // value for 'socialAndPartnersConsent'
 *   },
 * });
 */
export function useSubscribeNewsletterCdmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeNewsletterCdmMutation,
    SubscribeNewsletterCdmMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubscribeNewsletterCdmMutation,
    SubscribeNewsletterCdmMutationVariables
  >(SubscribeNewsletterCdmDocument, options);
}
export type SubscribeNewsletterCdmMutationHookResult = ReturnType<
  typeof useSubscribeNewsletterCdmMutation
>;
export type SubscribeNewsletterCdmMutationResult =
  Apollo.MutationResult<SubscribeNewsletterCdmMutation>;
export type SubscribeNewsletterCdmMutationOptions = Apollo.BaseMutationOptions<
  SubscribeNewsletterCdmMutation,
  SubscribeNewsletterCdmMutationVariables
>;
export const SubscribeAnonymousNewsletterCdmDocument = gql`
  mutation subscribeAnonymousNewsletterCDM($data: AnonymousNewsletterData!) {
    subscribeAnonymousNewsletterCDM(data: $data)
  }
`;
export type SubscribeAnonymousNewsletterCdmMutationFn = Apollo.MutationFunction<
  SubscribeAnonymousNewsletterCdmMutation,
  SubscribeAnonymousNewsletterCdmMutationVariables
>;

/**
 * __useSubscribeAnonymousNewsletterCdmMutation__
 *
 * To run a mutation, you first call `useSubscribeAnonymousNewsletterCdmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeAnonymousNewsletterCdmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeAnonymousNewsletterCdmMutation, { data, loading, error }] = useSubscribeAnonymousNewsletterCdmMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubscribeAnonymousNewsletterCdmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeAnonymousNewsletterCdmMutation,
    SubscribeAnonymousNewsletterCdmMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubscribeAnonymousNewsletterCdmMutation,
    SubscribeAnonymousNewsletterCdmMutationVariables
  >(SubscribeAnonymousNewsletterCdmDocument, options);
}
export type SubscribeAnonymousNewsletterCdmMutationHookResult = ReturnType<
  typeof useSubscribeAnonymousNewsletterCdmMutation
>;
export type SubscribeAnonymousNewsletterCdmMutationResult =
  Apollo.MutationResult<SubscribeAnonymousNewsletterCdmMutation>;
export type SubscribeAnonymousNewsletterCdmMutationOptions = Apollo.BaseMutationOptions<
  SubscribeAnonymousNewsletterCdmMutation,
  SubscribeAnonymousNewsletterCdmMutationVariables
>;
export const SubscribeLoggedInNewsletterCdmDocument = gql`
  mutation subscribeLoggedInNewsletterCDM($data: LoggedInNewsletterData!) {
    subscribeLoggedInNewsletterCDM(data: $data)
  }
`;
export type SubscribeLoggedInNewsletterCdmMutationFn = Apollo.MutationFunction<
  SubscribeLoggedInNewsletterCdmMutation,
  SubscribeLoggedInNewsletterCdmMutationVariables
>;

/**
 * __useSubscribeLoggedInNewsletterCdmMutation__
 *
 * To run a mutation, you first call `useSubscribeLoggedInNewsletterCdmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeLoggedInNewsletterCdmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeLoggedInNewsletterCdmMutation, { data, loading, error }] = useSubscribeLoggedInNewsletterCdmMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubscribeLoggedInNewsletterCdmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubscribeLoggedInNewsletterCdmMutation,
    SubscribeLoggedInNewsletterCdmMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubscribeLoggedInNewsletterCdmMutation,
    SubscribeLoggedInNewsletterCdmMutationVariables
  >(SubscribeLoggedInNewsletterCdmDocument, options);
}
export type SubscribeLoggedInNewsletterCdmMutationHookResult = ReturnType<
  typeof useSubscribeLoggedInNewsletterCdmMutation
>;
export type SubscribeLoggedInNewsletterCdmMutationResult =
  Apollo.MutationResult<SubscribeLoggedInNewsletterCdmMutation>;
export type SubscribeLoggedInNewsletterCdmMutationOptions = Apollo.BaseMutationOptions<
  SubscribeLoggedInNewsletterCdmMutation,
  SubscribeLoggedInNewsletterCdmMutationVariables
>;
export const SendRecommendationsSeenDocument = gql`
  mutation sendRecommendationsSeen($seenProducts: [String]!) {
    sendRecommendationsSeen(seenProducts: $seenProducts)
  }
`;
export type SendRecommendationsSeenMutationFn = Apollo.MutationFunction<
  SendRecommendationsSeenMutation,
  SendRecommendationsSeenMutationVariables
>;

/**
 * __useSendRecommendationsSeenMutation__
 *
 * To run a mutation, you first call `useSendRecommendationsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRecommendationsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRecommendationsSeenMutation, { data, loading, error }] = useSendRecommendationsSeenMutation({
 *   variables: {
 *      seenProducts: // value for 'seenProducts'
 *   },
 * });
 */
export function useSendRecommendationsSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendRecommendationsSeenMutation,
    SendRecommendationsSeenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendRecommendationsSeenMutation,
    SendRecommendationsSeenMutationVariables
  >(SendRecommendationsSeenDocument, options);
}
export type SendRecommendationsSeenMutationHookResult = ReturnType<
  typeof useSendRecommendationsSeenMutation
>;
export type SendRecommendationsSeenMutationResult =
  Apollo.MutationResult<SendRecommendationsSeenMutation>;
export type SendRecommendationsSeenMutationOptions = Apollo.BaseMutationOptions<
  SendRecommendationsSeenMutation,
  SendRecommendationsSeenMutationVariables
>;
export const SearchPickupStationsDocument = gql`
  query searchPickupStations($postalCode: String!, $limit: Int) {
    searchPickupStations(postalCode: $postalCode, limit: $limit) {
      defaultPostalCode {
        id
        label
        latitude
        longitude
        restType
        town
        value
      }
      pointOfServices {
        address {
          formattedAddress
          line1
          line2
          phone
          postalCode
          region {
            countryIso
            isocode
            name
            restType
          }
          streetname
          streetnumber
          town
          restType
        }
        code
        distanceKm
        formattedDistance
        openingHoursText
        name
        geoPoint {
          latitude
          longitude
          restType
        }
        restType
        services {
          code
          name
        }
        seoData {
          title
          description
          noIndex
          noFollow
        }
        locationType
      }
      restType
    }
  }
`;

/**
 * __useSearchPickupStationsQuery__
 *
 * To run a query within a React component, call `useSearchPickupStationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPickupStationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPickupStationsQuery({
 *   variables: {
 *      postalCode: // value for 'postalCode'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchPickupStationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchPickupStationsQuery,
    SearchPickupStationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPickupStationsQuery, SearchPickupStationsQueryVariables>(
    SearchPickupStationsDocument,
    options,
  );
}
export function useSearchPickupStationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchPickupStationsQuery,
    SearchPickupStationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchPickupStationsQuery, SearchPickupStationsQueryVariables>(
    SearchPickupStationsDocument,
    options,
  );
}
export type SearchPickupStationsQueryHookResult = ReturnType<typeof useSearchPickupStationsQuery>;
export type SearchPickupStationsLazyQueryHookResult = ReturnType<
  typeof useSearchPickupStationsLazyQuery
>;
export type SearchPickupStationsQueryResult = Apollo.QueryResult<
  SearchPickupStationsQuery,
  SearchPickupStationsQueryVariables
>;
export const UnitBasedCalculationDocument = gql`
  query unitBasedCalculation($amount: Float!, $code: String!) {
    getUnitBasedCalculation(amount: $amount, code: $code) {
      amount
      deliveryAmount
      packageAmount
      price {
        currencyIso
        formattedValue
        priceType
        restType
        value
      }
      selectedDeliveryAmount
      productUnitBasedAmount
      restType
    }
  }
`;

/**
 * __useUnitBasedCalculationQuery__
 *
 * To run a query within a React component, call `useUnitBasedCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitBasedCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitBasedCalculationQuery({
 *   variables: {
 *      amount: // value for 'amount'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useUnitBasedCalculationQuery(
  baseOptions: Apollo.QueryHookOptions<
    UnitBasedCalculationQuery,
    UnitBasedCalculationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnitBasedCalculationQuery, UnitBasedCalculationQueryVariables>(
    UnitBasedCalculationDocument,
    options,
  );
}
export function useUnitBasedCalculationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnitBasedCalculationQuery,
    UnitBasedCalculationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnitBasedCalculationQuery, UnitBasedCalculationQueryVariables>(
    UnitBasedCalculationDocument,
    options,
  );
}
export type UnitBasedCalculationQueryHookResult = ReturnType<typeof useUnitBasedCalculationQuery>;
export type UnitBasedCalculationLazyQueryHookResult = ReturnType<
  typeof useUnitBasedCalculationLazyQuery
>;
export type UnitBasedCalculationQueryResult = Apollo.QueryResult<
  UnitBasedCalculationQuery,
  UnitBasedCalculationQueryVariables
>;
export const PostContactCenterDocument = gql`
  mutation postContactCenter(
    $data: ContactCenterData!
    $isComplaint: Boolean
    $isMarketplaceEnabled: Boolean
  ) {
    postContactCenter(
      data: $data
      isComplaint: $isComplaint
      isMarketplaceEnabled: $isMarketplaceEnabled
    )
  }
`;
export type PostContactCenterMutationFn = Apollo.MutationFunction<
  PostContactCenterMutation,
  PostContactCenterMutationVariables
>;

/**
 * __usePostContactCenterMutation__
 *
 * To run a mutation, you first call `usePostContactCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostContactCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postContactCenterMutation, { data, loading, error }] = usePostContactCenterMutation({
 *   variables: {
 *      data: // value for 'data'
 *      isComplaint: // value for 'isComplaint'
 *      isMarketplaceEnabled: // value for 'isMarketplaceEnabled'
 *   },
 * });
 */
export function usePostContactCenterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PostContactCenterMutation,
    PostContactCenterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PostContactCenterMutation, PostContactCenterMutationVariables>(
    PostContactCenterDocument,
    options,
  );
}
export type PostContactCenterMutationHookResult = ReturnType<typeof usePostContactCenterMutation>;
export type PostContactCenterMutationResult = Apollo.MutationResult<PostContactCenterMutation>;
export type PostContactCenterMutationOptions = Apollo.BaseMutationOptions<
  PostContactCenterMutation,
  PostContactCenterMutationVariables
>;
export const CheckAndPostMiraklContactCenterDocument = gql`
  mutation checkAndPostMiraklContactCenter($data: MiraklContactCenterData!) {
    checkAndPostMiraklContactCenter(data: $data) {
      messageId
      threadId
    }
  }
`;
export type CheckAndPostMiraklContactCenterMutationFn = Apollo.MutationFunction<
  CheckAndPostMiraklContactCenterMutation,
  CheckAndPostMiraklContactCenterMutationVariables
>;

/**
 * __useCheckAndPostMiraklContactCenterMutation__
 *
 * To run a mutation, you first call `useCheckAndPostMiraklContactCenterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckAndPostMiraklContactCenterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkAndPostMiraklContactCenterMutation, { data, loading, error }] = useCheckAndPostMiraklContactCenterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckAndPostMiraklContactCenterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckAndPostMiraklContactCenterMutation,
    CheckAndPostMiraklContactCenterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CheckAndPostMiraklContactCenterMutation,
    CheckAndPostMiraklContactCenterMutationVariables
  >(CheckAndPostMiraklContactCenterDocument, options);
}
export type CheckAndPostMiraklContactCenterMutationHookResult = ReturnType<
  typeof useCheckAndPostMiraklContactCenterMutation
>;
export type CheckAndPostMiraklContactCenterMutationResult =
  Apollo.MutationResult<CheckAndPostMiraklContactCenterMutation>;
export type CheckAndPostMiraklContactCenterMutationOptions = Apollo.BaseMutationOptions<
  CheckAndPostMiraklContactCenterMutation,
  CheckAndPostMiraklContactCenterMutationVariables
>;
export const ReplyToMiraklThreadDocument = gql`
  mutation replyToMiraklThread($data: MiraklThreadData!) {
    replyToMiraklThread(data: $data)
  }
`;
export type ReplyToMiraklThreadMutationFn = Apollo.MutationFunction<
  ReplyToMiraklThreadMutation,
  ReplyToMiraklThreadMutationVariables
>;

/**
 * __useReplyToMiraklThreadMutation__
 *
 * To run a mutation, you first call `useReplyToMiraklThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyToMiraklThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyToMiraklThreadMutation, { data, loading, error }] = useReplyToMiraklThreadMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReplyToMiraklThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReplyToMiraklThreadMutation,
    ReplyToMiraklThreadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReplyToMiraklThreadMutation, ReplyToMiraklThreadMutationVariables>(
    ReplyToMiraklThreadDocument,
    options,
  );
}
export type ReplyToMiraklThreadMutationHookResult = ReturnType<
  typeof useReplyToMiraklThreadMutation
>;
export type ReplyToMiraklThreadMutationResult = Apollo.MutationResult<ReplyToMiraklThreadMutation>;
export type ReplyToMiraklThreadMutationOptions = Apollo.BaseMutationOptions<
  ReplyToMiraklThreadMutation,
  ReplyToMiraklThreadMutationVariables
>;
export const GetCustomerCardDocument = gql`
  query getCustomerCard($bubbleErrors: Boolean) {
    getCustomerCard(bubbleErrors: $bubbleErrors) {
      cardNumber
      confirmed
      created
    }
  }
`;

/**
 * __useGetCustomerCardQuery__
 *
 * To run a query within a React component, call `useGetCustomerCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerCardQuery({
 *   variables: {
 *      bubbleErrors: // value for 'bubbleErrors'
 *   },
 * });
 */
export function useGetCustomerCardQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomerCardQuery, GetCustomerCardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerCardQuery, GetCustomerCardQueryVariables>(
    GetCustomerCardDocument,
    options,
  );
}
export function useGetCustomerCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerCardQuery, GetCustomerCardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerCardQuery, GetCustomerCardQueryVariables>(
    GetCustomerCardDocument,
    options,
  );
}
export type GetCustomerCardQueryHookResult = ReturnType<typeof useGetCustomerCardQuery>;
export type GetCustomerCardLazyQueryHookResult = ReturnType<typeof useGetCustomerCardLazyQuery>;
export type GetCustomerCardQueryResult = Apollo.QueryResult<
  GetCustomerCardQuery,
  GetCustomerCardQueryVariables
>;
export const UpdateCustomerCardDocument = gql`
  mutation updateCustomerCard($confirmed: Boolean!) {
    updateCustomerCard(confirmed: $confirmed) {
      cardNumber
      confirmed
      created
    }
  }
`;
export type UpdateCustomerCardMutationFn = Apollo.MutationFunction<
  UpdateCustomerCardMutation,
  UpdateCustomerCardMutationVariables
>;

/**
 * __useUpdateCustomerCardMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerCardMutation, { data, loading, error }] = useUpdateCustomerCardMutation({
 *   variables: {
 *      confirmed: // value for 'confirmed'
 *   },
 * });
 */
export function useUpdateCustomerCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerCardMutation,
    UpdateCustomerCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCustomerCardMutation, UpdateCustomerCardMutationVariables>(
    UpdateCustomerCardDocument,
    options,
  );
}
export type UpdateCustomerCardMutationHookResult = ReturnType<typeof useUpdateCustomerCardMutation>;
export type UpdateCustomerCardMutationResult = Apollo.MutationResult<UpdateCustomerCardMutation>;
export type UpdateCustomerCardMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerCardMutation,
  UpdateCustomerCardMutationVariables
>;
export const AcceptTermsAndConditionsDocument = gql`
  mutation acceptTermsAndConditions($created: String!) {
    acceptTermsAndConditions(created: $created)
  }
`;
export type AcceptTermsAndConditionsMutationFn = Apollo.MutationFunction<
  AcceptTermsAndConditionsMutation,
  AcceptTermsAndConditionsMutationVariables
>;

/**
 * __useAcceptTermsAndConditionsMutation__
 *
 * To run a mutation, you first call `useAcceptTermsAndConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermsAndConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermsAndConditionsMutation, { data, loading, error }] = useAcceptTermsAndConditionsMutation({
 *   variables: {
 *      created: // value for 'created'
 *   },
 * });
 */
export function useAcceptTermsAndConditionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptTermsAndConditionsMutation,
    AcceptTermsAndConditionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptTermsAndConditionsMutation,
    AcceptTermsAndConditionsMutationVariables
  >(AcceptTermsAndConditionsDocument, options);
}
export type AcceptTermsAndConditionsMutationHookResult = ReturnType<
  typeof useAcceptTermsAndConditionsMutation
>;
export type AcceptTermsAndConditionsMutationResult =
  Apollo.MutationResult<AcceptTermsAndConditionsMutation>;
export type AcceptTermsAndConditionsMutationOptions = Apollo.BaseMutationOptions<
  AcceptTermsAndConditionsMutation,
  AcceptTermsAndConditionsMutationVariables
>;
export const GetCustomerDocument = gql`
  query getCustomer {
    getCustomer {
      tac {
        created
      }
    }
  }
`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(
    GetCustomerDocument,
    options,
  );
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<
  GetCustomerQuery,
  GetCustomerQueryVariables
>;
export const ChangePasswordDocument = gql`
  mutation changePassword($password: String!, $oldPassword: String!) {
    changePassword(password: $password, oldPassword: $oldPassword)
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      oldPassword: // value for 'oldPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    ChangePasswordDocument,
    options,
  );
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const GetCurrentUserDocument = gql`
  query getCurrentUser {
    getCurrentUser {
      anonymous
      title
      titleCode
      firstName
      lastName
      name
      uid
      email
      restType
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options,
  );
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const LoginDocument = gql`
  mutation login($userCredentials: UserBaseLoginInput!) {
    login(userCredentials: $userCredentials) {
      isAnonymous
      orderCartMerged
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      userCredentials: // value for 'userCredentials'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const LogoutDocument = gql`
  mutation logout {
    logout {
      orderCartMerged
    }
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const ResetPasswordInquiryDocument = gql`
  mutation resetPasswordInquiry($email: String!, $captcha: String) {
    resetPasswordInquiry(email: $email, captcha: $captcha)
  }
`;
export type ResetPasswordInquiryMutationFn = Apollo.MutationFunction<
  ResetPasswordInquiryMutation,
  ResetPasswordInquiryMutationVariables
>;

/**
 * __useResetPasswordInquiryMutation__
 *
 * To run a mutation, you first call `useResetPasswordInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordInquiryMutation, { data, loading, error }] = useResetPasswordInquiryMutation({
 *   variables: {
 *      email: // value for 'email'
 *      captcha: // value for 'captcha'
 *   },
 * });
 */
export function useResetPasswordInquiryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordInquiryMutation,
    ResetPasswordInquiryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordInquiryMutation, ResetPasswordInquiryMutationVariables>(
    ResetPasswordInquiryDocument,
    options,
  );
}
export type ResetPasswordInquiryMutationHookResult = ReturnType<
  typeof useResetPasswordInquiryMutation
>;
export type ResetPasswordInquiryMutationResult =
  Apollo.MutationResult<ResetPasswordInquiryMutation>;
export type ResetPasswordInquiryMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordInquiryMutation,
  ResetPasswordInquiryMutationVariables
>;
export const RegisterUserDocument = gql`
  mutation registerUser($data: RegisterUserInput!) {
    registerUser(data: $data) {
      email
    }
  }
`;
export type RegisterUserMutationFn = Apollo.MutationFunction<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(
    RegisterUserDocument,
    options,
  );
}
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export const RegisterUserConfirmationDocument = gql`
  mutation registerUserConfirmation($dblOptInKey: String!) {
    registerUserConfirmation(dblOptInKey: $dblOptInKey) {
      email
    }
  }
`;
export type RegisterUserConfirmationMutationFn = Apollo.MutationFunction<
  RegisterUserConfirmationMutation,
  RegisterUserConfirmationMutationVariables
>;

/**
 * __useRegisterUserConfirmationMutation__
 *
 * To run a mutation, you first call `useRegisterUserConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserConfirmationMutation, { data, loading, error }] = useRegisterUserConfirmationMutation({
 *   variables: {
 *      dblOptInKey: // value for 'dblOptInKey'
 *   },
 * });
 */
export function useRegisterUserConfirmationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterUserConfirmationMutation,
    RegisterUserConfirmationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterUserConfirmationMutation,
    RegisterUserConfirmationMutationVariables
  >(RegisterUserConfirmationDocument, options);
}
export type RegisterUserConfirmationMutationHookResult = ReturnType<
  typeof useRegisterUserConfirmationMutation
>;
export type RegisterUserConfirmationMutationResult =
  Apollo.MutationResult<RegisterUserConfirmationMutation>;
export type RegisterUserConfirmationMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserConfirmationMutation,
  RegisterUserConfirmationMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($tokenId: String!, $token: String!, $password: String!, $captcha: String) {
    resetPassword(tokenId: $tokenId, token: $token, password: $password, captcha: $captcha)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      captcha: // value for 'captcha'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options,
  );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const ValidateTokenDocument = gql`
  mutation validateToken($tokenId: String!, $token: String!, $captcha: String) {
    validateToken(tokenId: $tokenId, token: $token, captcha: $captcha) {
      email
    }
  }
`;
export type ValidateTokenMutationFn = Apollo.MutationFunction<
  ValidateTokenMutation,
  ValidateTokenMutationVariables
>;

/**
 * __useValidateTokenMutation__
 *
 * To run a mutation, you first call `useValidateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateTokenMutation, { data, loading, error }] = useValidateTokenMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *      token: // value for 'token'
 *      captcha: // value for 'captcha'
 *   },
 * });
 */
export function useValidateTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<ValidateTokenMutation, ValidateTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ValidateTokenMutation, ValidateTokenMutationVariables>(
    ValidateTokenDocument,
    options,
  );
}
export type ValidateTokenMutationHookResult = ReturnType<typeof useValidateTokenMutation>;
export type ValidateTokenMutationResult = Apollo.MutationResult<ValidateTokenMutation>;
export type ValidateTokenMutationOptions = Apollo.BaseMutationOptions<
  ValidateTokenMutation,
  ValidateTokenMutationVariables
>;
export const ResendBonuscardActivationDocument = gql`
  mutation resendBonuscardActivation($email: String!, $captcha: String) {
    resendBonuscardActivation(email: $email, captcha: $captcha)
  }
`;
export type ResendBonuscardActivationMutationFn = Apollo.MutationFunction<
  ResendBonuscardActivationMutation,
  ResendBonuscardActivationMutationVariables
>;

/**
 * __useResendBonuscardActivationMutation__
 *
 * To run a mutation, you first call `useResendBonuscardActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendBonuscardActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendBonuscardActivationMutation, { data, loading, error }] = useResendBonuscardActivationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      captcha: // value for 'captcha'
 *   },
 * });
 */
export function useResendBonuscardActivationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendBonuscardActivationMutation,
    ResendBonuscardActivationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendBonuscardActivationMutation,
    ResendBonuscardActivationMutationVariables
  >(ResendBonuscardActivationDocument, options);
}
export type ResendBonuscardActivationMutationHookResult = ReturnType<
  typeof useResendBonuscardActivationMutation
>;
export type ResendBonuscardActivationMutationResult =
  Apollo.MutationResult<ResendBonuscardActivationMutation>;
export type ResendBonuscardActivationMutationOptions = Apollo.BaseMutationOptions<
  ResendBonuscardActivationMutation,
  ResendBonuscardActivationMutationVariables
>;
export const DoubleOptInBonuscardDocument = gql`
  mutation doubleOptInBonuscard($code: String!) {
    doubleOptInBonuscard(code: $code) {
      number
    }
  }
`;
export type DoubleOptInBonuscardMutationFn = Apollo.MutationFunction<
  DoubleOptInBonuscardMutation,
  DoubleOptInBonuscardMutationVariables
>;

/**
 * __useDoubleOptInBonuscardMutation__
 *
 * To run a mutation, you first call `useDoubleOptInBonuscardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoubleOptInBonuscardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doubleOptInBonuscardMutation, { data, loading, error }] = useDoubleOptInBonuscardMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useDoubleOptInBonuscardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DoubleOptInBonuscardMutation,
    DoubleOptInBonuscardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DoubleOptInBonuscardMutation, DoubleOptInBonuscardMutationVariables>(
    DoubleOptInBonuscardDocument,
    options,
  );
}
export type DoubleOptInBonuscardMutationHookResult = ReturnType<
  typeof useDoubleOptInBonuscardMutation
>;
export type DoubleOptInBonuscardMutationResult =
  Apollo.MutationResult<DoubleOptInBonuscardMutation>;
export type DoubleOptInBonuscardMutationOptions = Apollo.BaseMutationOptions<
  DoubleOptInBonuscardMutation,
  DoubleOptInBonuscardMutationVariables
>;
export const AddBonuscardDocument = gql`
  mutation addBonuscard($data: BonuscardRegisterRequestInput!) {
    addBonuscard(data: $data)
  }
`;
export type AddBonuscardMutationFn = Apollo.MutationFunction<
  AddBonuscardMutation,
  AddBonuscardMutationVariables
>;

/**
 * __useAddBonuscardMutation__
 *
 * To run a mutation, you first call `useAddBonuscardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBonuscardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBonuscardMutation, { data, loading, error }] = useAddBonuscardMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddBonuscardMutation(
  baseOptions?: Apollo.MutationHookOptions<AddBonuscardMutation, AddBonuscardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddBonuscardMutation, AddBonuscardMutationVariables>(
    AddBonuscardDocument,
    options,
  );
}
export type AddBonuscardMutationHookResult = ReturnType<typeof useAddBonuscardMutation>;
export type AddBonuscardMutationResult = Apollo.MutationResult<AddBonuscardMutation>;
export type AddBonuscardMutationOptions = Apollo.BaseMutationOptions<
  AddBonuscardMutation,
  AddBonuscardMutationVariables
>;
export const GetNavigationNodesDocument = gql`
  query getNavigationNodes($ids: [String!], $level: Int) {
    getNavigationNodes(ids: $ids, level: $level) {
      data
    }
  }
`;

/**
 * __useGetNavigationNodesQuery__
 *
 * To run a query within a React component, call `useGetNavigationNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavigationNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavigationNodesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      level: // value for 'level'
 *   },
 * });
 */
export function useGetNavigationNodesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNavigationNodesQuery, GetNavigationNodesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNavigationNodesQuery, GetNavigationNodesQueryVariables>(
    GetNavigationNodesDocument,
    options,
  );
}
export function useGetNavigationNodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNavigationNodesQuery,
    GetNavigationNodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNavigationNodesQuery, GetNavigationNodesQueryVariables>(
    GetNavigationNodesDocument,
    options,
  );
}
export type GetNavigationNodesQueryHookResult = ReturnType<typeof useGetNavigationNodesQuery>;
export type GetNavigationNodesLazyQueryHookResult = ReturnType<
  typeof useGetNavigationNodesLazyQuery
>;
export type GetNavigationNodesQueryResult = Apollo.QueryResult<
  GetNavigationNodesQuery,
  GetNavigationNodesQueryVariables
>;
export const GetFaqSuggestDocument = gql`
  query getFaqSuggest($term: String!, $maxWordsQuestion: Int, $maxWordsAnswer: Int) {
    getFaqSuggest(
      term: $term
      maxWordsQuestion: $maxWordsQuestion
      maxWordsAnswer: $maxWordsAnswer
    ) {
      count
      results {
        categoryIconName
        questionTitle
        answerContent
        answerUrl
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetFaqSuggestQuery__
 *
 * To run a query within a React component, call `useGetFaqSuggestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaqSuggestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFaqSuggestQuery({
 *   variables: {
 *      term: // value for 'term'
 *      maxWordsQuestion: // value for 'maxWordsQuestion'
 *      maxWordsAnswer: // value for 'maxWordsAnswer'
 *   },
 * });
 */
export function useGetFaqSuggestQuery(
  baseOptions: Apollo.QueryHookOptions<GetFaqSuggestQuery, GetFaqSuggestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFaqSuggestQuery, GetFaqSuggestQueryVariables>(
    GetFaqSuggestDocument,
    options,
  );
}
export function useGetFaqSuggestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFaqSuggestQuery, GetFaqSuggestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFaqSuggestQuery, GetFaqSuggestQueryVariables>(
    GetFaqSuggestDocument,
    options,
  );
}
export type GetFaqSuggestQueryHookResult = ReturnType<typeof useGetFaqSuggestQuery>;
export type GetFaqSuggestLazyQueryHookResult = ReturnType<typeof useGetFaqSuggestLazyQuery>;
export type GetFaqSuggestQueryResult = Apollo.QueryResult<
  GetFaqSuggestQuery,
  GetFaqSuggestQueryVariables
>;
export const GetSellerDocument = gql`
  query getSeller($sellerId: String) {
    getSeller(sellerId: $sellerId) {
      conditions
      gdpr
      name
      description
      imprint
      returnPolicy
      contactInformation {
        companyName
        titleCode
        firstName
        lastName
        email
        phone
        phone2
        town
        line1
        line2
        postalCode
        streetname
        formattedAddress
        url
        region {
          countryIso
          isocode
          name
        }
        country {
          isocode
          name
        }
      }
      seoData {
        url
      }
    }
  }
`;

/**
 * __useGetSellerQuery__
 *
 * To run a query within a React component, call `useGetSellerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellerQuery({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useGetSellerQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSellerQuery, GetSellerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSellerQuery, GetSellerQueryVariables>(GetSellerDocument, options);
}
export function useGetSellerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSellerQuery, GetSellerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSellerQuery, GetSellerQueryVariables>(GetSellerDocument, options);
}
export type GetSellerQueryHookResult = ReturnType<typeof useGetSellerQuery>;
export type GetSellerLazyQueryHookResult = ReturnType<typeof useGetSellerLazyQuery>;
export type GetSellerQueryResult = Apollo.QueryResult<GetSellerQuery, GetSellerQueryVariables>;
export const BookAppointmentDocument = gql`
  mutation bookAppointment($data: DeliveryAppointmentData!) {
    bookAppointment(data: $data)
  }
`;
export type BookAppointmentMutationFn = Apollo.MutationFunction<
  BookAppointmentMutation,
  BookAppointmentMutationVariables
>;

/**
 * __useBookAppointmentMutation__
 *
 * To run a mutation, you first call `useBookAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAppointmentMutation, { data, loading, error }] = useBookAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBookAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookAppointmentMutation,
    BookAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BookAppointmentMutation, BookAppointmentMutationVariables>(
    BookAppointmentDocument,
    options,
  );
}
export type BookAppointmentMutationHookResult = ReturnType<typeof useBookAppointmentMutation>;
export type BookAppointmentMutationResult = Apollo.MutationResult<BookAppointmentMutation>;
export type BookAppointmentMutationOptions = Apollo.BaseMutationOptions<
  BookAppointmentMutation,
  BookAppointmentMutationVariables
>;
export const GetAppointmentProposalsDocument = gql`
  query getAppointmentProposals($lastname: String!, $orderCode: String!) {
    getAppointmentProposals(lastname: $lastname, orderCode: $orderCode) {
      errorCode
      noMoreAppointments
      proposals {
        proposalId
        startDate
        endDate
      }
    }
  }
`;

/**
 * __useGetAppointmentProposalsQuery__
 *
 * To run a query within a React component, call `useGetAppointmentProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentProposalsQuery({
 *   variables: {
 *      lastname: // value for 'lastname'
 *      orderCode: // value for 'orderCode'
 *   },
 * });
 */
export function useGetAppointmentProposalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppointmentProposalsQuery,
    GetAppointmentProposalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppointmentProposalsQuery, GetAppointmentProposalsQueryVariables>(
    GetAppointmentProposalsDocument,
    options,
  );
}
export function useGetAppointmentProposalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentProposalsQuery,
    GetAppointmentProposalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppointmentProposalsQuery, GetAppointmentProposalsQueryVariables>(
    GetAppointmentProposalsDocument,
    options,
  );
}
export type GetAppointmentProposalsQueryHookResult = ReturnType<
  typeof useGetAppointmentProposalsQuery
>;
export type GetAppointmentProposalsLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentProposalsLazyQuery
>;
export type GetAppointmentProposalsQueryResult = Apollo.QueryResult<
  GetAppointmentProposalsQuery,
  GetAppointmentProposalsQueryVariables
>;
export const BrochuresDocument = gql`
  query brochures {
    getBrochures {
      brochureTypes {
        name
        seoCode
        id
        brochures {
          id
          title
          startDate
          endDate
          downloadUrl
          image {
            cdnFilename
            fileExtension
            realFilename
          }
        }
      }
    }
  }
`;

/**
 * __useBrochuresQuery__
 *
 * To run a query within a React component, call `useBrochuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrochuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrochuresQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrochuresQuery(
  baseOptions?: Apollo.QueryHookOptions<BrochuresQuery, BrochuresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrochuresQuery, BrochuresQueryVariables>(BrochuresDocument, options);
}
export function useBrochuresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BrochuresQuery, BrochuresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrochuresQuery, BrochuresQueryVariables>(BrochuresDocument, options);
}
export type BrochuresQueryHookResult = ReturnType<typeof useBrochuresQuery>;
export type BrochuresLazyQueryHookResult = ReturnType<typeof useBrochuresLazyQuery>;
export type BrochuresQueryResult = Apollo.QueryResult<BrochuresQuery, BrochuresQueryVariables>;
export const ExpressPaymentAuthorizeDocument = gql`
  mutation expressPaymentAuthorize($cartId: String!, $paymentMode: String!) {
    expressPaymentAuthorize(cartId: $cartId, paymentMode: $paymentMode) {
      customResponsePaymentData
    }
  }
`;
export type ExpressPaymentAuthorizeMutationFn = Apollo.MutationFunction<
  ExpressPaymentAuthorizeMutation,
  ExpressPaymentAuthorizeMutationVariables
>;

/**
 * __useExpressPaymentAuthorizeMutation__
 *
 * To run a mutation, you first call `useExpressPaymentAuthorizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpressPaymentAuthorizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expressPaymentAuthorizeMutation, { data, loading, error }] = useExpressPaymentAuthorizeMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      paymentMode: // value for 'paymentMode'
 *   },
 * });
 */
export function useExpressPaymentAuthorizeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExpressPaymentAuthorizeMutation,
    ExpressPaymentAuthorizeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExpressPaymentAuthorizeMutation,
    ExpressPaymentAuthorizeMutationVariables
  >(ExpressPaymentAuthorizeDocument, options);
}
export type ExpressPaymentAuthorizeMutationHookResult = ReturnType<
  typeof useExpressPaymentAuthorizeMutation
>;
export type ExpressPaymentAuthorizeMutationResult =
  Apollo.MutationResult<ExpressPaymentAuthorizeMutation>;
export type ExpressPaymentAuthorizeMutationOptions = Apollo.BaseMutationOptions<
  ExpressPaymentAuthorizeMutation,
  ExpressPaymentAuthorizeMutationVariables
>;
export const CategoryDocument = gql`
  query category($code: String!) {
    getCategory(code: $code) {
      name
      code
      breadcrumbs {
        itemCode
        seoUrl
        name
        type
      }
      image {
        hashCode: cdnFilename
      }
      seoData {
        baseStoreRelatedItems {
          locale
          url
        }
        canonicalUrl
        description
        keywords
        noFollow
        noIndex
        title
        url
      }
    }
  }
`;

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
}
export function useCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
}
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = Apollo.QueryResult<CategoryQuery, CategoryQueryVariables>;
export const PlaceOrderDocument = gql`
  mutation placeOrder($data: PlaceOrderRequestInput!) {
    placeOrder(data: $data) {
      paymentStart {
        customResponsePaymentData
        error
        htmlFragment
        interactionCode
        restType
        returnCode
        urlRedirect
      }
      code
      messages {
        message
        arguments {
          value
        }
      }
      paymentMode {
        active
        code
        description
        mobileDescription
        name
        paymentCost {
          value
        }
        pgProvider
        redirectHint
        redirectMessage
        redirectPageHeader
      }
      providerParameters {
        key
        value
      }
    }
  }
`;
export type PlaceOrderMutationFn = Apollo.MutationFunction<
  PlaceOrderMutation,
  PlaceOrderMutationVariables
>;

/**
 * __usePlaceOrderMutation__
 *
 * To run a mutation, you first call `usePlaceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeOrderMutation, { data, loading, error }] = usePlaceOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePlaceOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<PlaceOrderMutation, PlaceOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PlaceOrderMutation, PlaceOrderMutationVariables>(
    PlaceOrderDocument,
    options,
  );
}
export type PlaceOrderMutationHookResult = ReturnType<typeof usePlaceOrderMutation>;
export type PlaceOrderMutationResult = Apollo.MutationResult<PlaceOrderMutation>;
export type PlaceOrderMutationOptions = Apollo.BaseMutationOptions<
  PlaceOrderMutation,
  PlaceOrderMutationVariables
>;
export const PlaceOrderConfirmationAdyenDocument = gql`
  mutation placeOrderConfirmationAdyen($data: PlaceOrderConfirmationAdyenInput!) {
    placeOrderConfirmationAdyen(data: $data) {
      pspReference
      resultCode
      merchantReference
      paymentMethod {
        type
      }
    }
  }
`;
export type PlaceOrderConfirmationAdyenMutationFn = Apollo.MutationFunction<
  PlaceOrderConfirmationAdyenMutation,
  PlaceOrderConfirmationAdyenMutationVariables
>;

/**
 * __usePlaceOrderConfirmationAdyenMutation__
 *
 * To run a mutation, you first call `usePlaceOrderConfirmationAdyenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceOrderConfirmationAdyenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeOrderConfirmationAdyenMutation, { data, loading, error }] = usePlaceOrderConfirmationAdyenMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePlaceOrderConfirmationAdyenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlaceOrderConfirmationAdyenMutation,
    PlaceOrderConfirmationAdyenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PlaceOrderConfirmationAdyenMutation,
    PlaceOrderConfirmationAdyenMutationVariables
  >(PlaceOrderConfirmationAdyenDocument, options);
}
export type PlaceOrderConfirmationAdyenMutationHookResult = ReturnType<
  typeof usePlaceOrderConfirmationAdyenMutation
>;
export type PlaceOrderConfirmationAdyenMutationResult =
  Apollo.MutationResult<PlaceOrderConfirmationAdyenMutation>;
export type PlaceOrderConfirmationAdyenMutationOptions = Apollo.BaseMutationOptions<
  PlaceOrderConfirmationAdyenMutation,
  PlaceOrderConfirmationAdyenMutationVariables
>;
export const PostAppointmentDocument = gql`
  mutation postAppointment($data: AppointmentData!) {
    postAppointment(data: $data) {
      appointmentId
    }
  }
`;
export type PostAppointmentMutationFn = Apollo.MutationFunction<
  PostAppointmentMutation,
  PostAppointmentMutationVariables
>;

/**
 * __usePostAppointmentMutation__
 *
 * To run a mutation, you first call `usePostAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postAppointmentMutation, { data, loading, error }] = usePostAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePostAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PostAppointmentMutation,
    PostAppointmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PostAppointmentMutation, PostAppointmentMutationVariables>(
    PostAppointmentDocument,
    options,
  );
}
export type PostAppointmentMutationHookResult = ReturnType<typeof usePostAppointmentMutation>;
export type PostAppointmentMutationResult = Apollo.MutationResult<PostAppointmentMutation>;
export type PostAppointmentMutationOptions = Apollo.BaseMutationOptions<
  PostAppointmentMutation,
  PostAppointmentMutationVariables
>;
export const GetAppointmentAreasDocument = gql`
  query getAppointmentAreas {
    getAppointmentAreas {
      areas {
        description
        length
        externId
        thumbnail {
          altText
          cdnFilename
          fileType
          hashCode
        }
        id
        name
        publicName
      }
    }
  }
`;

/**
 * __useGetAppointmentAreasQuery__
 *
 * To run a query within a React component, call `useGetAppointmentAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppointmentAreasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAppointmentAreasQuery,
    GetAppointmentAreasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppointmentAreasQuery, GetAppointmentAreasQueryVariables>(
    GetAppointmentAreasDocument,
    options,
  );
}
export function useGetAppointmentAreasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentAreasQuery,
    GetAppointmentAreasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppointmentAreasQuery, GetAppointmentAreasQueryVariables>(
    GetAppointmentAreasDocument,
    options,
  );
}
export type GetAppointmentAreasQueryHookResult = ReturnType<typeof useGetAppointmentAreasQuery>;
export type GetAppointmentAreasLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentAreasLazyQuery
>;
export type GetAppointmentAreasQueryResult = Apollo.QueryResult<
  GetAppointmentAreasQuery,
  GetAppointmentAreasQueryVariables
>;
export const AppointmentStoresDocument = gql`
  query appointmentStores($areaId: String!, $postalCode: String) {
    getAppointmentStores(areaId: $areaId, postalCode: $postalCode) {
      pointOfServices {
        address {
          phone
          email
          firstName
          lastName
          postalCode
          town
          streetname
          streetnumber
        }
        code
        geoPoint {
          latitude
          longitude
        }
        timendoGroupId
        timendoInterventionId
        longDescription
        name
        openingHoursText
      }
    }
  }
`;

/**
 * __useAppointmentStoresQuery__
 *
 * To run a query within a React component, call `useAppointmentStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentStoresQuery({
 *   variables: {
 *      areaId: // value for 'areaId'
 *      postalCode: // value for 'postalCode'
 *   },
 * });
 */
export function useAppointmentStoresQuery(
  baseOptions: Apollo.QueryHookOptions<AppointmentStoresQuery, AppointmentStoresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentStoresQuery, AppointmentStoresQueryVariables>(
    AppointmentStoresDocument,
    options,
  );
}
export function useAppointmentStoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentStoresQuery,
    AppointmentStoresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentStoresQuery, AppointmentStoresQueryVariables>(
    AppointmentStoresDocument,
    options,
  );
}
export type AppointmentStoresQueryHookResult = ReturnType<typeof useAppointmentStoresQuery>;
export type AppointmentStoresLazyQueryHookResult = ReturnType<typeof useAppointmentStoresLazyQuery>;
export type AppointmentStoresQueryResult = Apollo.QueryResult<
  AppointmentStoresQuery,
  AppointmentStoresQueryVariables
>;
export const GetAppointmentInformationDocument = gql`
  query getAppointmentInformation($areaId: String, $storeId: String, $appointmentId: String) {
    getAppointmentInformation(areaId: $areaId, storeId: $storeId, appointmentId: $appointmentId) {
      appointmentId
      start
      areaInformation {
        description
        length
        externId
        thumbnail {
          altText
          cdnFilename
          fileType
          hashCode
        }
        id
        name
        publicName
      }
      storeInformation {
        address {
          phone
          email
          firstName
          lastName
          postalCode
          town
          streetname
          streetnumber
        }
        code
        geoPoint {
          latitude
          longitude
        }
        timendoGroupId
        timendoInterventionId
        longDescription
        name
        openingHoursText
      }
      customer {
        email
        firstName
        gender
        lastName
        phone
      }
    }
  }
`;

/**
 * __useGetAppointmentInformationQuery__
 *
 * To run a query within a React component, call `useGetAppointmentInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentInformationQuery({
 *   variables: {
 *      areaId: // value for 'areaId'
 *      storeId: // value for 'storeId'
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useGetAppointmentInformationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAppointmentInformationQuery,
    GetAppointmentInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppointmentInformationQuery, GetAppointmentInformationQueryVariables>(
    GetAppointmentInformationDocument,
    options,
  );
}
export function useGetAppointmentInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentInformationQuery,
    GetAppointmentInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppointmentInformationQuery,
    GetAppointmentInformationQueryVariables
  >(GetAppointmentInformationDocument, options);
}
export type GetAppointmentInformationQueryHookResult = ReturnType<
  typeof useGetAppointmentInformationQuery
>;
export type GetAppointmentInformationLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentInformationLazyQuery
>;
export type GetAppointmentInformationQueryResult = Apollo.QueryResult<
  GetAppointmentInformationQuery,
  GetAppointmentInformationQueryVariables
>;
export const GetAppointmentTimeslotsDocument = gql`
  query getAppointmentTimeslots(
    $areaId: String!
    $storeId: String!
    $start: String!
    $end: String!
  ) {
    getAppointmentTimeslots(areaId: $areaId, storeId: $storeId, start: $start, end: $end) {
      availableTimeslots {
        calendarIds
        start
      }
    }
  }
`;

/**
 * __useGetAppointmentTimeslotsQuery__
 *
 * To run a query within a React component, call `useGetAppointmentTimeslotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentTimeslotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentTimeslotsQuery({
 *   variables: {
 *      areaId: // value for 'areaId'
 *      storeId: // value for 'storeId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetAppointmentTimeslotsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppointmentTimeslotsQuery,
    GetAppointmentTimeslotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppointmentTimeslotsQuery, GetAppointmentTimeslotsQueryVariables>(
    GetAppointmentTimeslotsDocument,
    options,
  );
}
export function useGetAppointmentTimeslotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentTimeslotsQuery,
    GetAppointmentTimeslotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppointmentTimeslotsQuery, GetAppointmentTimeslotsQueryVariables>(
    GetAppointmentTimeslotsDocument,
    options,
  );
}
export type GetAppointmentTimeslotsQueryHookResult = ReturnType<
  typeof useGetAppointmentTimeslotsQuery
>;
export type GetAppointmentTimeslotsLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentTimeslotsLazyQuery
>;
export type GetAppointmentTimeslotsQueryResult = Apollo.QueryResult<
  GetAppointmentTimeslotsQuery,
  GetAppointmentTimeslotsQueryVariables
>;
export const GetAppointmentTimeslotsWithInfoDocument = gql`
  query getAppointmentTimeslotsWithInfo(
    $areaId: String!
    $storeId: String!
    $start: String!
    $end: String!
  ) {
    getAppointmentTimeslots(areaId: $areaId, storeId: $storeId, start: $start, end: $end) {
      availableTimeslots {
        calendarIds
        start
      }
    }
    getAppointmentInformation(areaId: $areaId, storeId: $storeId) {
      areaInformation {
        description
        length
        externId
        thumbnail {
          altText
          cdnFilename
          fileType
          hashCode
        }
        id
        name
        publicName
      }
      storeInformation {
        address {
          phone
          email
          firstName
          lastName
          postalCode
          town
          streetname
          streetnumber
        }
        code
        geoPoint {
          latitude
          longitude
        }
        timendoGroupId
        timendoInterventionId
        longDescription
        name
        openingHoursText
      }
    }
  }
`;

/**
 * __useGetAppointmentTimeslotsWithInfoQuery__
 *
 * To run a query within a React component, call `useGetAppointmentTimeslotsWithInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentTimeslotsWithInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentTimeslotsWithInfoQuery({
 *   variables: {
 *      areaId: // value for 'areaId'
 *      storeId: // value for 'storeId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetAppointmentTimeslotsWithInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAppointmentTimeslotsWithInfoQuery,
    GetAppointmentTimeslotsWithInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAppointmentTimeslotsWithInfoQuery,
    GetAppointmentTimeslotsWithInfoQueryVariables
  >(GetAppointmentTimeslotsWithInfoDocument, options);
}
export function useGetAppointmentTimeslotsWithInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAppointmentTimeslotsWithInfoQuery,
    GetAppointmentTimeslotsWithInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAppointmentTimeslotsWithInfoQuery,
    GetAppointmentTimeslotsWithInfoQueryVariables
  >(GetAppointmentTimeslotsWithInfoDocument, options);
}
export type GetAppointmentTimeslotsWithInfoQueryHookResult = ReturnType<
  typeof useGetAppointmentTimeslotsWithInfoQuery
>;
export type GetAppointmentTimeslotsWithInfoLazyQueryHookResult = ReturnType<
  typeof useGetAppointmentTimeslotsWithInfoLazyQuery
>;
export type GetAppointmentTimeslotsWithInfoQueryResult = Apollo.QueryResult<
  GetAppointmentTimeslotsWithInfoQuery,
  GetAppointmentTimeslotsWithInfoQueryVariables
>;
export const GetPocoContentTokenDocument = gql`
  query getPocoContentToken {
    getPocoContentToken {
      token
    }
  }
`;

/**
 * __useGetPocoContentTokenQuery__
 *
 * To run a query within a React component, call `useGetPocoContentTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPocoContentTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPocoContentTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPocoContentTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPocoContentTokenQuery,
    GetPocoContentTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPocoContentTokenQuery, GetPocoContentTokenQueryVariables>(
    GetPocoContentTokenDocument,
    options,
  );
}
export function useGetPocoContentTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPocoContentTokenQuery,
    GetPocoContentTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPocoContentTokenQuery, GetPocoContentTokenQueryVariables>(
    GetPocoContentTokenDocument,
    options,
  );
}
export type GetPocoContentTokenQueryHookResult = ReturnType<typeof useGetPocoContentTokenQuery>;
export type GetPocoContentTokenLazyQueryHookResult = ReturnType<
  typeof useGetPocoContentTokenLazyQuery
>;
export type GetPocoContentTokenQueryResult = Apollo.QueryResult<
  GetPocoContentTokenQuery,
  GetPocoContentTokenQueryVariables
>;
export const ReverseImageSearchDocument = gql`
  mutation reverseImageSearch($data: ImageSearchData!) {
    reverseImageSearch(data: $data) {
      codes
      errorCode
      restType
    }
  }
`;
export type ReverseImageSearchMutationFn = Apollo.MutationFunction<
  ReverseImageSearchMutation,
  ReverseImageSearchMutationVariables
>;

/**
 * __useReverseImageSearchMutation__
 *
 * To run a mutation, you first call `useReverseImageSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReverseImageSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reverseImageSearchMutation, { data, loading, error }] = useReverseImageSearchMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReverseImageSearchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReverseImageSearchMutation,
    ReverseImageSearchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReverseImageSearchMutation, ReverseImageSearchMutationVariables>(
    ReverseImageSearchDocument,
    options,
  );
}
export type ReverseImageSearchMutationHookResult = ReturnType<typeof useReverseImageSearchMutation>;
export type ReverseImageSearchMutationResult = Apollo.MutationResult<ReverseImageSearchMutation>;
export type ReverseImageSearchMutationOptions = Apollo.BaseMutationOptions<
  ReverseImageSearchMutation,
  ReverseImageSearchMutationVariables
>;
export const ProductDocument = gql`
  query product($productCode: String!) {
    getProduct(productCode: $productCode) {
      manufactureCountry {
        name
      }
      originCountry {
        name
      }
      produceCountry {
        name
      }
      attributes {
        colorMapping {
          code
          targetColor
          webColor
        }
        dimensions {
          ...dimensions
          strRepresentation
          strRepresentationKey
        }
        material
      }
      affirmators {
        title
        iconKey
        link
      }
      categoryId
      color
      name
      code
      timendoMasterId
      timendoAreaCmsPage
      description
      transitionalDescription
      teaserText
      url
      baseProduct
      onlineOnly
      augmentedReality {
        androidUrl
        iosUrl
      }
      availabilityStatus
      legalInformationShown
      reservationData {
        maxReservableAmount
        reservable
      }
      additionalProductExpenses {
        price
        type
        includedInProductPrice
        restType
      }
      categories {
        name
        code
      }
      configurable
      configuratorData {
        configurationId
        configurationSystemId
        restType
      }
      configuredProduct
      configuredProductData {
        attachments {
          altText
          code
          url
        }
        images {
          altText
          url
        }
        configuredId
        productNumber
        description
        name
        restType
        summary
      }
      brands {
        ...brands
      }
      liveshopping
      deliveryData {
        assembly
        bulkyProduct
        customDeliveryType
        deliveryCostsCharged
        deliveryIndicator
        deliveryInformationForCustomers {
          code
          name
          value
          valueId
        }
      }
      mediaData {
        ...mediaData
      }
      characteristicData {
        colorMapping {
          code
          targetColor
          webColor
        }
        detailAttributes {
          name
          attributes {
            name
            value
          }
        }
      }
      eyecatchers {
        code
        style
      }
      shopInformation {
        isExternal
        shopId
        shopName
      }
      seoData {
        ...seoData
      }
      breadcrumbs {
        ...breadcrumbs
      }
      variantContainerData {
        variantGroups {
          name
          title
          variantValues {
            colorTitle
            colorMapping
            restType
            enabled
            selected
            value
            product {
              code
              color
              url
            }
            productPicture {
              altText
              cdnFilename
              hashCode
              fileExtension
              fileType
              mediaProvider
              pictureHeight
              pictureWidth
              realFilename
              restType
              seoText
            }
            targetColor
          }
        }
      }
      priceData {
        ...commonPriceData
        currentPrice {
          endTime
          specialOfferTypeData {
            numberOfAsterisks
          }
        }
        postCost {
          value
        }
        deliveryCost {
          value
        }
        deliveryMethods {
          code
          price {
            value
          }
        }
        assemblyCost {
          value
        }
        shippingCost {
          value
        }
        promotionalMessageShown
      }
      style {
        code
        name
      }
      energyEfficiencyData {
        ...energyEfficiencyData
      }
      orderData {
        buyable
        maxOrderableAmount
        sellable
      }
      summary
      additionalDescription {
        name
        attributes {
          name
          value
        }
      }
      eligibleForFreeDelivery
      statisticKPI
      hasRatings
      relatedProductAvailability {
        hasAccessories
      }
      productServices {
        code
        price
        type
      }
    }
  }
  ${DimensionsFragmentDoc}
  ${BrandsFragmentDoc}
  ${MediaDataFragmentDoc}
  ${SeoDataFragmentDoc}
  ${BreadcrumbsFragmentDoc}
  ${CommonPriceDataFragmentDoc}
  ${EnergyEfficiencyDataFragmentDoc}
`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      productCode: // value for 'productCode'
 *   },
 * });
 */
export function useProductQuery(
  baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
}
export function useProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const GetProductComparisonDocument = gql`
  query getProductComparison($productCode: String!) {
    getProductComparison(productCode: $productCode) {
      products {
        productId
        productName
        attributes
        formattedPrice
        price
        image
        url
      }
      attributes {
        key
        name
      }
      title
    }
  }
`;

/**
 * __useGetProductComparisonQuery__
 *
 * To run a query within a React component, call `useGetProductComparisonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductComparisonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductComparisonQuery({
 *   variables: {
 *      productCode: // value for 'productCode'
 *   },
 * });
 */
export function useGetProductComparisonQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductComparisonQuery,
    GetProductComparisonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductComparisonQuery, GetProductComparisonQueryVariables>(
    GetProductComparisonDocument,
    options,
  );
}
export function useGetProductComparisonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductComparisonQuery,
    GetProductComparisonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductComparisonQuery, GetProductComparisonQueryVariables>(
    GetProductComparisonDocument,
    options,
  );
}
export type GetProductComparisonQueryHookResult = ReturnType<typeof useGetProductComparisonQuery>;
export type GetProductComparisonLazyQueryHookResult = ReturnType<
  typeof useGetProductComparisonLazyQuery
>;
export type GetProductComparisonQueryResult = Apollo.QueryResult<
  GetProductComparisonQuery,
  GetProductComparisonQueryVariables
>;
export const GetProductComparisonServiceDocument = gql`
  query getProductComparisonService($productCode: String!) {
    getProductComparisonService(productCode: $productCode) {
      products {
        brand {
          name
        }
        productId
        productName
        attributes
        price
        image {
          cdnFilename
          altText
        }
        url
      }
      attributes {
        key
        name
      }
    }
  }
`;

/**
 * __useGetProductComparisonServiceQuery__
 *
 * To run a query within a React component, call `useGetProductComparisonServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductComparisonServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductComparisonServiceQuery({
 *   variables: {
 *      productCode: // value for 'productCode'
 *   },
 * });
 */
export function useGetProductComparisonServiceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductComparisonServiceQuery,
    GetProductComparisonServiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductComparisonServiceQuery,
    GetProductComparisonServiceQueryVariables
  >(GetProductComparisonServiceDocument, options);
}
export function useGetProductComparisonServiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductComparisonServiceQuery,
    GetProductComparisonServiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductComparisonServiceQuery,
    GetProductComparisonServiceQueryVariables
  >(GetProductComparisonServiceDocument, options);
}
export type GetProductComparisonServiceQueryHookResult = ReturnType<
  typeof useGetProductComparisonServiceQuery
>;
export type GetProductComparisonServiceLazyQueryHookResult = ReturnType<
  typeof useGetProductComparisonServiceLazyQuery
>;
export type GetProductComparisonServiceQueryResult = Apollo.QueryResult<
  GetProductComparisonServiceQuery,
  GetProductComparisonServiceQueryVariables
>;
export const GetProductComparisonServiceValidationDocument = gql`
  query getProductComparisonServiceValidation($productCode: String!, $categoryId: Int!) {
    getProductComparisonServiceValidation(productCode: $productCode, categoryId: $categoryId) {
      products {
        brand {
          name
        }
        productId
        productName
        attributes
        price
        image {
          cdnFilename
          altText
        }
        url
      }
      attributes {
        key
        name
      }
    }
  }
`;

/**
 * __useGetProductComparisonServiceValidationQuery__
 *
 * To run a query within a React component, call `useGetProductComparisonServiceValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductComparisonServiceValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductComparisonServiceValidationQuery({
 *   variables: {
 *      productCode: // value for 'productCode'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetProductComparisonServiceValidationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductComparisonServiceValidationQuery,
    GetProductComparisonServiceValidationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductComparisonServiceValidationQuery,
    GetProductComparisonServiceValidationQueryVariables
  >(GetProductComparisonServiceValidationDocument, options);
}
export function useGetProductComparisonServiceValidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductComparisonServiceValidationQuery,
    GetProductComparisonServiceValidationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductComparisonServiceValidationQuery,
    GetProductComparisonServiceValidationQueryVariables
  >(GetProductComparisonServiceValidationDocument, options);
}
export type GetProductComparisonServiceValidationQueryHookResult = ReturnType<
  typeof useGetProductComparisonServiceValidationQuery
>;
export type GetProductComparisonServiceValidationLazyQueryHookResult = ReturnType<
  typeof useGetProductComparisonServiceValidationLazyQuery
>;
export type GetProductComparisonServiceValidationQueryResult = Apollo.QueryResult<
  GetProductComparisonServiceValidationQuery,
  GetProductComparisonServiceValidationQueryVariables
>;
export const ProductAvailabilityDocument = gql`
  query productAvailability(
    $productCode: String!
    $qty: String
    $zipCode: String
    $maxSubsidiariesShown: String
  ) {
    getProductAvailability(
      productCode: $productCode
      qty: $qty
      zipCode: $zipCode
      maxSubsidiariesShown: $maxSubsidiariesShown
    ) {
      assembly
      nearestAvailableSubsidiaries {
        reservationSubsidiaries {
          ...subsidiary
        }
        selfServiceSubsidiaries {
          ...subsidiary
        }
      }
      status
      shippingInformation {
        deliveryTime
        deliveryTimeText
        shippingType
      }
      subsidiaries {
        ...subsidiary
      }
    }
  }
  ${SubsidiaryFragmentDoc}
`;

/**
 * __useProductAvailabilityQuery__
 *
 * To run a query within a React component, call `useProductAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductAvailabilityQuery({
 *   variables: {
 *      productCode: // value for 'productCode'
 *      qty: // value for 'qty'
 *      zipCode: // value for 'zipCode'
 *      maxSubsidiariesShown: // value for 'maxSubsidiariesShown'
 *   },
 * });
 */
export function useProductAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<ProductAvailabilityQuery, ProductAvailabilityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductAvailabilityQuery, ProductAvailabilityQueryVariables>(
    ProductAvailabilityDocument,
    options,
  );
}
export function useProductAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductAvailabilityQuery,
    ProductAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductAvailabilityQuery, ProductAvailabilityQueryVariables>(
    ProductAvailabilityDocument,
    options,
  );
}
export type ProductAvailabilityQueryHookResult = ReturnType<typeof useProductAvailabilityQuery>;
export type ProductAvailabilityLazyQueryHookResult = ReturnType<
  typeof useProductAvailabilityLazyQuery
>;
export type ProductAvailabilityQueryResult = Apollo.QueryResult<
  ProductAvailabilityQuery,
  ProductAvailabilityQueryVariables
>;
export const GetZipCodeDocument = gql`
  query getZipCode($search: String, $lat: Float, $lng: Float) {
    getZipCode(search: $search, lat: $lat, lng: $lng) {
      zipCode {
        id
        label
        value
      }
      geometry {
        location {
          lat
          lng
        }
        viewport {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
    }
  }
`;

/**
 * __useGetZipCodeQuery__
 *
 * To run a query within a React component, call `useGetZipCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZipCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZipCodeQuery({
 *   variables: {
 *      search: // value for 'search'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *   },
 * });
 */
export function useGetZipCodeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetZipCodeQuery, GetZipCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetZipCodeQuery, GetZipCodeQueryVariables>(GetZipCodeDocument, options);
}
export function useGetZipCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetZipCodeQuery, GetZipCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetZipCodeQuery, GetZipCodeQueryVariables>(
    GetZipCodeDocument,
    options,
  );
}
export type GetZipCodeQueryHookResult = ReturnType<typeof useGetZipCodeQuery>;
export type GetZipCodeLazyQueryHookResult = ReturnType<typeof useGetZipCodeLazyQuery>;
export type GetZipCodeQueryResult = Apollo.QueryResult<GetZipCodeQuery, GetZipCodeQueryVariables>;
export const ZipCodeDocument = gql`
  query zipCode($search: String!) {
    suggestZipCode(search: $search) {
      postalcodes {
        value
        town
        label
      }
    }
  }
`;

/**
 * __useZipCodeQuery__
 *
 * To run a query within a React component, call `useZipCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useZipCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZipCodeQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useZipCodeQuery(
  baseOptions: Apollo.QueryHookOptions<ZipCodeQuery, ZipCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZipCodeQuery, ZipCodeQueryVariables>(ZipCodeDocument, options);
}
export function useZipCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ZipCodeQuery, ZipCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZipCodeQuery, ZipCodeQueryVariables>(ZipCodeDocument, options);
}
export type ZipCodeQueryHookResult = ReturnType<typeof useZipCodeQuery>;
export type ZipCodeLazyQueryHookResult = ReturnType<typeof useZipCodeLazyQuery>;
export type ZipCodeQueryResult = Apollo.QueryResult<ZipCodeQuery, ZipCodeQueryVariables>;
export const GetProductReviewsDocument = gql`
  query getProductReviews(
    $currentPage: Int
    $pageSize: Int
    $productCode: String!
    $filters: ReviewsFilters
  ) {
    getProductReviews(
      currentPage: $currentPage
      pageSize: $pageSize
      productCode: $productCode
      filters: $filters
    ) {
      approvalMode
      ...dimensionTypes
      ...pagination
      ratingStatistic {
        ...ratingStatistic
      }
      ...dimensionalRatingStatisticData
      ...reviews
    }
  }
  ${DimensionTypesFragmentDoc}
  ${PaginationFragmentDoc}
  ${RatingStatisticFragmentDoc}
  ${DimensionalRatingStatisticDataFragmentDoc}
  ${ReviewsFragmentDoc}
`;

/**
 * __useGetProductReviewsQuery__
 *
 * To run a query within a React component, call `useGetProductReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductReviewsQuery({
 *   variables: {
 *      currentPage: // value for 'currentPage'
 *      pageSize: // value for 'pageSize'
 *      productCode: // value for 'productCode'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetProductReviewsQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductReviewsQuery, GetProductReviewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductReviewsQuery, GetProductReviewsQueryVariables>(
    GetProductReviewsDocument,
    options,
  );
}
export function useGetProductReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductReviewsQuery,
    GetProductReviewsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductReviewsQuery, GetProductReviewsQueryVariables>(
    GetProductReviewsDocument,
    options,
  );
}
export type GetProductReviewsQueryHookResult = ReturnType<typeof useGetProductReviewsQuery>;
export type GetProductReviewsLazyQueryHookResult = ReturnType<typeof useGetProductReviewsLazyQuery>;
export type GetProductReviewsQueryResult = Apollo.QueryResult<
  GetProductReviewsQuery,
  GetProductReviewsQueryVariables
>;
export const GetProductReviewOptionsDocument = gql`
  query getProductReviewOptions($productCode: String!) {
    getProductReviewOptions(productCode: $productCode) {
      canCreateReview
      canGetReviews
    }
  }
`;

/**
 * __useGetProductReviewOptionsQuery__
 *
 * To run a query within a React component, call `useGetProductReviewOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductReviewOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductReviewOptionsQuery({
 *   variables: {
 *      productCode: // value for 'productCode'
 *   },
 * });
 */
export function useGetProductReviewOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductReviewOptionsQuery,
    GetProductReviewOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductReviewOptionsQuery, GetProductReviewOptionsQueryVariables>(
    GetProductReviewOptionsDocument,
    options,
  );
}
export function useGetProductReviewOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductReviewOptionsQuery,
    GetProductReviewOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductReviewOptionsQuery, GetProductReviewOptionsQueryVariables>(
    GetProductReviewOptionsDocument,
    options,
  );
}
export type GetProductReviewOptionsQueryHookResult = ReturnType<
  typeof useGetProductReviewOptionsQuery
>;
export type GetProductReviewOptionsLazyQueryHookResult = ReturnType<
  typeof useGetProductReviewOptionsLazyQuery
>;
export type GetProductReviewOptionsQueryResult = Apollo.QueryResult<
  GetProductReviewOptionsQuery,
  GetProductReviewOptionsQueryVariables
>;
export const HotdealsProductDocument = gql`
  query hotdealsProduct($productCode: String!) {
    getProduct(productCode: $productCode) {
      brands {
        ...brands
      }
      mediaData {
        ...mediaData
      }
      seoData {
        ...seoData
      }
      breadcrumbs {
        ...breadcrumbs
      }
      hotdealData {
        ...hotDealData
      }
      name
      code
      url
      baseProduct
      availabilityStatus
      legalInformationShown
      priceData {
        currentPrice {
          ...currentPrice
        }
        oldPrice {
          value
        }
        pricePerUnit {
          value
        }
        productUnitDiffersToPriceUnit
        sellingUnit
        sellingAmount
        savedPercent
        productUnitDiffersToPriceUnit
        promotionalMessageShown
      }
      description
      summary
      statisticKPI
    }
  }
  ${BrandsFragmentDoc}
  ${MediaDataFragmentDoc}
  ${SeoDataFragmentDoc}
  ${BreadcrumbsFragmentDoc}
  ${HotDealDataFragmentDoc}
  ${CurrentPriceFragmentDoc}
`;

/**
 * __useHotdealsProductQuery__
 *
 * To run a query within a React component, call `useHotdealsProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotdealsProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotdealsProductQuery({
 *   variables: {
 *      productCode: // value for 'productCode'
 *   },
 * });
 */
export function useHotdealsProductQuery(
  baseOptions: Apollo.QueryHookOptions<HotdealsProductQuery, HotdealsProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HotdealsProductQuery, HotdealsProductQueryVariables>(
    HotdealsProductDocument,
    options,
  );
}
export function useHotdealsProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HotdealsProductQuery, HotdealsProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HotdealsProductQuery, HotdealsProductQueryVariables>(
    HotdealsProductDocument,
    options,
  );
}
export type HotdealsProductQueryHookResult = ReturnType<typeof useHotdealsProductQuery>;
export type HotdealsProductLazyQueryHookResult = ReturnType<typeof useHotdealsProductLazyQuery>;
export type HotdealsProductQueryResult = Apollo.QueryResult<
  HotdealsProductQuery,
  HotdealsProductQueryVariables
>;
export const PreloadHotdealsProductDocument = gql`
  query preloadHotdealsProduct($productCode: String!) {
    getProduct(productCode: $productCode) {
      name
      code
      priceData {
        currentPrice {
          value
          specialOfferTypeData {
            numberOfAsterisks
          }
        }
        oldPrice {
          value
        }
        savedPercent
      }
    }
  }
`;

/**
 * __usePreloadHotdealsProductQuery__
 *
 * To run a query within a React component, call `usePreloadHotdealsProductQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreloadHotdealsProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreloadHotdealsProductQuery({
 *   variables: {
 *      productCode: // value for 'productCode'
 *   },
 * });
 */
export function usePreloadHotdealsProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    PreloadHotdealsProductQuery,
    PreloadHotdealsProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PreloadHotdealsProductQuery, PreloadHotdealsProductQueryVariables>(
    PreloadHotdealsProductDocument,
    options,
  );
}
export function usePreloadHotdealsProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PreloadHotdealsProductQuery,
    PreloadHotdealsProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PreloadHotdealsProductQuery, PreloadHotdealsProductQueryVariables>(
    PreloadHotdealsProductDocument,
    options,
  );
}
export type PreloadHotdealsProductQueryHookResult = ReturnType<
  typeof usePreloadHotdealsProductQuery
>;
export type PreloadHotdealsProductLazyQueryHookResult = ReturnType<
  typeof usePreloadHotdealsProductLazyQuery
>;
export type PreloadHotdealsProductQueryResult = Apollo.QueryResult<
  PreloadHotdealsProductQuery,
  PreloadHotdealsProductQueryVariables
>;
export const GetRatingProductDimensionTypesDocument = gql`
  query getRatingProductDimensionTypes {
    getRatingProductDimensionTypes {
      type
      value
    }
  }
`;

/**
 * __useGetRatingProductDimensionTypesQuery__
 *
 * To run a query within a React component, call `useGetRatingProductDimensionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRatingProductDimensionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRatingProductDimensionTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRatingProductDimensionTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRatingProductDimensionTypesQuery,
    GetRatingProductDimensionTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRatingProductDimensionTypesQuery,
    GetRatingProductDimensionTypesQueryVariables
  >(GetRatingProductDimensionTypesDocument, options);
}
export function useGetRatingProductDimensionTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRatingProductDimensionTypesQuery,
    GetRatingProductDimensionTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRatingProductDimensionTypesQuery,
    GetRatingProductDimensionTypesQueryVariables
  >(GetRatingProductDimensionTypesDocument, options);
}
export type GetRatingProductDimensionTypesQueryHookResult = ReturnType<
  typeof useGetRatingProductDimensionTypesQuery
>;
export type GetRatingProductDimensionTypesLazyQueryHookResult = ReturnType<
  typeof useGetRatingProductDimensionTypesLazyQuery
>;
export type GetRatingProductDimensionTypesQueryResult = Apollo.QueryResult<
  GetRatingProductDimensionTypesQuery,
  GetRatingProductDimensionTypesQueryVariables
>;
export const GetProductsToReviewDocument = gql`
  query getProductsToReview(
    $codes: String!
    $email: String!
    $orderCode: String!
    $year: String!
    $mac: String!
  ) {
    getProductsToReview(
      codes: $codes
      email: $email
      orderCode: $orderCode
      year: $year
      mac: $mac
    ) {
      products {
        attributes {
          colorMapping {
            code
            targetColor
            webColor
          }
          dimensions {
            ...dimensions
            strRepresentation
            strRepresentationKey
          }
          material
        }
        name
        code
        url
        brands {
          ...brands
        }
        mediaData {
          ...mediaData
        }
      }
    }
  }
  ${DimensionsFragmentDoc}
  ${BrandsFragmentDoc}
  ${MediaDataFragmentDoc}
`;

/**
 * __useGetProductsToReviewQuery__
 *
 * To run a query within a React component, call `useGetProductsToReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsToReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsToReviewQuery({
 *   variables: {
 *      codes: // value for 'codes'
 *      email: // value for 'email'
 *      orderCode: // value for 'orderCode'
 *      year: // value for 'year'
 *      mac: // value for 'mac'
 *   },
 * });
 */
export function useGetProductsToReviewQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductsToReviewQuery, GetProductsToReviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsToReviewQuery, GetProductsToReviewQueryVariables>(
    GetProductsToReviewDocument,
    options,
  );
}
export function useGetProductsToReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsToReviewQuery,
    GetProductsToReviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsToReviewQuery, GetProductsToReviewQueryVariables>(
    GetProductsToReviewDocument,
    options,
  );
}
export type GetProductsToReviewQueryHookResult = ReturnType<typeof useGetProductsToReviewQuery>;
export type GetProductsToReviewLazyQueryHookResult = ReturnType<
  typeof useGetProductsToReviewLazyQuery
>;
export type GetProductsToReviewQueryResult = Apollo.QueryResult<
  GetProductsToReviewQuery,
  GetProductsToReviewQueryVariables
>;
export const CreateProductVerifiedReviewDocument = gql`
  mutation createProductVerifiedReview(
    $data: ReviewCreateInput!
    $productCode: String!
    $email: String
    $orderCode: String
    $orderProductCodes: String
    $year: String
    $mac: String
    $token: String!
  ) {
    createProductReview(
      data: $data
      productCode: $productCode
      email: $email
      orderCode: $orderCode
      orderProductCodes: $orderProductCodes
      year: $year
      mac: $mac
      token: $token
    )
  }
`;
export type CreateProductVerifiedReviewMutationFn = Apollo.MutationFunction<
  CreateProductVerifiedReviewMutation,
  CreateProductVerifiedReviewMutationVariables
>;

/**
 * __useCreateProductVerifiedReviewMutation__
 *
 * To run a mutation, you first call `useCreateProductVerifiedReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductVerifiedReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductVerifiedReviewMutation, { data, loading, error }] = useCreateProductVerifiedReviewMutation({
 *   variables: {
 *      data: // value for 'data'
 *      productCode: // value for 'productCode'
 *      email: // value for 'email'
 *      orderCode: // value for 'orderCode'
 *      orderProductCodes: // value for 'orderProductCodes'
 *      year: // value for 'year'
 *      mac: // value for 'mac'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCreateProductVerifiedReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProductVerifiedReviewMutation,
    CreateProductVerifiedReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProductVerifiedReviewMutation,
    CreateProductVerifiedReviewMutationVariables
  >(CreateProductVerifiedReviewDocument, options);
}
export type CreateProductVerifiedReviewMutationHookResult = ReturnType<
  typeof useCreateProductVerifiedReviewMutation
>;
export type CreateProductVerifiedReviewMutationResult =
  Apollo.MutationResult<CreateProductVerifiedReviewMutation>;
export type CreateProductVerifiedReviewMutationOptions = Apollo.BaseMutationOptions<
  CreateProductVerifiedReviewMutation,
  CreateProductVerifiedReviewMutationVariables
>;
export const PlaceReservationDocument = gql`
  mutation placeReservation($data: PlaceReservationData!) {
    placeReservation(data: $data) {
      code
      paymentAddress {
        country {
          isocode
        }
        email
        firstName
        lastName
      }
      subOrders {
        code
        selectedStore {
          code
          name
          seoData {
            url
          }
        }
        totalPriceWithTax {
          currencyIso
          value
        }
      }
    }
  }
`;
export type PlaceReservationMutationFn = Apollo.MutationFunction<
  PlaceReservationMutation,
  PlaceReservationMutationVariables
>;

/**
 * __usePlaceReservationMutation__
 *
 * To run a mutation, you first call `usePlaceReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeReservationMutation, { data, loading, error }] = usePlaceReservationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePlaceReservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlaceReservationMutation,
    PlaceReservationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PlaceReservationMutation, PlaceReservationMutationVariables>(
    PlaceReservationDocument,
    options,
  );
}
export type PlaceReservationMutationHookResult = ReturnType<typeof usePlaceReservationMutation>;
export type PlaceReservationMutationResult = Apollo.MutationResult<PlaceReservationMutation>;
export type PlaceReservationMutationOptions = Apollo.BaseMutationOptions<
  PlaceReservationMutation,
  PlaceReservationMutationVariables
>;
export const ReservationExportStatusDocument = gql`
  query reservationExportStatus($code: String!) {
    reservationExportStatus(code: $code) {
      status
    }
  }
`;

/**
 * __useReservationExportStatusQuery__
 *
 * To run a query within a React component, call `useReservationExportStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationExportStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationExportStatusQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useReservationExportStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReservationExportStatusQuery,
    ReservationExportStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReservationExportStatusQuery, ReservationExportStatusQueryVariables>(
    ReservationExportStatusDocument,
    options,
  );
}
export function useReservationExportStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReservationExportStatusQuery,
    ReservationExportStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReservationExportStatusQuery, ReservationExportStatusQueryVariables>(
    ReservationExportStatusDocument,
    options,
  );
}
export type ReservationExportStatusQueryHookResult = ReturnType<
  typeof useReservationExportStatusQuery
>;
export type ReservationExportStatusLazyQueryHookResult = ReturnType<
  typeof useReservationExportStatusLazyQuery
>;
export type ReservationExportStatusQueryResult = Apollo.QueryResult<
  ReservationExportStatusQuery,
  ReservationExportStatusQueryVariables
>;
export const GetRestaurantOverviewDocument = gql`
  query getRestaurantOverview($subsidiaryId: String, $postalCode: String) {
    getRestaurants(subsidiaryId: $subsidiaryId, postalCode: $postalCode) {
      restaurants {
        name
        address {
          fax
          phone
          email
          postalCode
          town
          streetname
          streetnumber
        }
        restType
        openingHoursSchedule {
          regularOpeningDays {
            closingTime {
              formattedHour
            }
            openingTime {
              formattedHour
            }
            closed
            code
            weekDay
            formattedDate
          }
        }
        geoPoint {
          latitude
          longitude
        }
        type
        distanceFromPostalCode
        code
        seoData {
          url
        }
        urlCode
      }
    }
  }
`;

/**
 * __useGetRestaurantOverviewQuery__
 *
 * To run a query within a React component, call `useGetRestaurantOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantOverviewQuery({
 *   variables: {
 *      subsidiaryId: // value for 'subsidiaryId'
 *      postalCode: // value for 'postalCode'
 *   },
 * });
 */
export function useGetRestaurantOverviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRestaurantOverviewQuery,
    GetRestaurantOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRestaurantOverviewQuery, GetRestaurantOverviewQueryVariables>(
    GetRestaurantOverviewDocument,
    options,
  );
}
export function useGetRestaurantOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRestaurantOverviewQuery,
    GetRestaurantOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRestaurantOverviewQuery, GetRestaurantOverviewQueryVariables>(
    GetRestaurantOverviewDocument,
    options,
  );
}
export type GetRestaurantOverviewQueryHookResult = ReturnType<typeof useGetRestaurantOverviewQuery>;
export type GetRestaurantOverviewLazyQueryHookResult = ReturnType<
  typeof useGetRestaurantOverviewLazyQuery
>;
export type GetRestaurantOverviewQueryResult = Apollo.QueryResult<
  GetRestaurantOverviewQuery,
  GetRestaurantOverviewQueryVariables
>;
export const GetStoreOverviewDocument = gql`
  query getStoreOverview($subsidiaryId: String, $showAll: Boolean, $postalCode: String) {
    getPointOfServices(subsidiaryId: $subsidiaryId, showAll: $showAll, postalCode: $postalCode) {
      pointOfServices {
        seoData {
          title
          description
          noIndex
          noFollow
          url
        }
        address {
          fax
          phone
          email
          firstName
          lastName
          postalCode
          town
          streetname
          streetnumber
        }
        regularOpeningHours
        distanceFromPostalCode
        bigPicture {
          altText
          url
        }
        code
        geoPoint {
          latitude
          longitude
        }
        longDescription
        name
        newFlag
        openingHoursText
        services {
          code
          name
          icon {
            altText
            url
          }
        }
        type
      }
    }
  }
`;

/**
 * __useGetStoreOverviewQuery__
 *
 * To run a query within a React component, call `useGetStoreOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreOverviewQuery({
 *   variables: {
 *      subsidiaryId: // value for 'subsidiaryId'
 *      showAll: // value for 'showAll'
 *      postalCode: // value for 'postalCode'
 *   },
 * });
 */
export function useGetStoreOverviewQuery(
  baseOptions?: Apollo.QueryHookOptions<GetStoreOverviewQuery, GetStoreOverviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStoreOverviewQuery, GetStoreOverviewQueryVariables>(
    GetStoreOverviewDocument,
    options,
  );
}
export function useGetStoreOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStoreOverviewQuery, GetStoreOverviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStoreOverviewQuery, GetStoreOverviewQueryVariables>(
    GetStoreOverviewDocument,
    options,
  );
}
export type GetStoreOverviewQueryHookResult = ReturnType<typeof useGetStoreOverviewQuery>;
export type GetStoreOverviewLazyQueryHookResult = ReturnType<typeof useGetStoreOverviewLazyQuery>;
export type GetStoreOverviewQueryResult = Apollo.QueryResult<
  GetStoreOverviewQuery,
  GetStoreOverviewQueryVariables
>;
export const GetSubsidiaryDocument = gql`
  query getSubsidiary($subsidiaryId: String) {
    getPointOfServices(subsidiaryId: $subsidiaryId) {
      pointOfServices {
        seoData {
          title
          description
          noIndex
          noFollow
          url
        }
        address {
          fax
          phone
          email
          firstName
          lastName
          postalCode
          town
          streetname
          streetnumber
        }
        attachedPointOfServices {
          address {
            country {
              isocode
              name
              restType
            }
            defaultAddress
            fax
            firstName
            formattedAddress
            id
            lastName
            lift
            line1
            line2
            phone
            postalCode
            region {
              countryIso
              isocode
              name
              restType
            }
            restType
            shippingAddress
            streetname
            streetnumber
            town
            visibleInAddressBook
          }
          code
          geoPoint {
            latitude
            longitude
            restType
          }
          name
          openingHoursText
          restType
          type
        }
        bigPicture {
          altText
          cdnFilename
          filename
          fileType
          hashCode
          pictureHeight
          pictureWidth
          url
        }
        code
        geoPoint {
          latitude
          longitude
        }
        longDescription
        name
        dailyMenu
        restaurantDescription
        restaurantInfo
        openingHoursText
        openingHours {
          regularOpeningDays {
            ...OpeningDay
          }
          restaurantOpeningDays {
            ...OpeningDay
          }
          specialOpeningDays {
            ...OpeningDay
          }
        }
        specialOpeningHours
        restaurantOpeningHours
        regularOpeningHours
        timendoGroupId
        type
        services {
          code
          name
          iconName
          url
          icon {
            altText
            url
          }
        }
      }
    }
  }
  ${OpeningDayFragmentDoc}
`;

/**
 * __useGetSubsidiaryQuery__
 *
 * To run a query within a React component, call `useGetSubsidiaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubsidiaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubsidiaryQuery({
 *   variables: {
 *      subsidiaryId: // value for 'subsidiaryId'
 *   },
 * });
 */
export function useGetSubsidiaryQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSubsidiaryQuery, GetSubsidiaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubsidiaryQuery, GetSubsidiaryQueryVariables>(
    GetSubsidiaryDocument,
    options,
  );
}
export function useGetSubsidiaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSubsidiaryQuery, GetSubsidiaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSubsidiaryQuery, GetSubsidiaryQueryVariables>(
    GetSubsidiaryDocument,
    options,
  );
}
export type GetSubsidiaryQueryHookResult = ReturnType<typeof useGetSubsidiaryQuery>;
export type GetSubsidiaryLazyQueryHookResult = ReturnType<typeof useGetSubsidiaryLazyQuery>;
export type GetSubsidiaryQueryResult = Apollo.QueryResult<
  GetSubsidiaryQuery,
  GetSubsidiaryQueryVariables
>;
